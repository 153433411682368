import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { random, snakeCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserForm } from '../@components/UserForm';

export function CreateUserPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [loading, setLoading] = useState(false);

  const updateUserTenantAccessPermission = async (data, userId) => {
    try {
      const request = api.ac.v5['role-manager'].users.$userId(userId).$put({
        headers: {
          Authorization: secretToken,
        },
        params: {
          accessChildTenant: data?.accessChildTenant,
          tenantId: data?.tenant,
          newRoleId: data?.role?.id,
        },
      });
      await request?.process();
    } catch (error) {}
  };

  /** @type {import('../@components/UserForm').UserFormProps['onSubmitData']} */
  const createUser = async (formData) => {
    const { email, name, role } = formData;
    try {
      setLoading(true);
      const username = snakeCase(email.substring(0, email.indexOf('@')) + random(10, 100));
      const req = api.ac.v5.auth.account.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          role: role?.name || 'ADMIN',
          email,
          name,
          tenantId,
          username,
        },
      });
      const result = await req.process();
      await updateUserTenantAccessPermission(formData, result.users?.at(0)?.userId);
      toastSuccess('Success', t(T['user.create.success']));
      navigate('/administration/users');
    } catch (err) {
      setLoading(false);
      toastWarning('Error', err?.response?.data?.message || t(T['user.create.retry']));
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['user.create.title'])}
      </Typography>
      <UserForm onSubmitData={createUser} loading={loading} />
    </Box>
  );
}
