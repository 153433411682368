import api from '@/api';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccessEntitySelection } from '../../feature-flags/@components/EntitySelection';
import { VideoMinutesEndpointTriggerTable } from '../@components/breakdown/VideoMinutesEndpointTriggerTable';
import { VideoMinutesBreakDownFilteringArea } from '../@components/VideoMinutesBreakDownFilteringArea';
import { VideoMinutesGraph } from '../@components/VideoMinutesGraph';
import { VideoMinutesContext } from '../@context/VideoMinutesContext';
import { VideoMinutesFilterContextProvider } from '../@context/VideoMinutesFilterContext';
import {
  calculateDailySubscriptionData,
  checkIsCurrentOrFuture,
  formatKeyValueEstimateData,
  processVideoMinutesChartData,
} from '../@utils';
import { CLIENT_WISE_MINUTE_PAYLOAD } from '../assets';
import YearMonthPicker from '../@components/MonthYearPicker';

export function VideoMinutesBreakDown() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { selectedTenant, setSelectedTenant } = useContext(VideoMinutesContext);

  const { query, updateQuery } = useRouteQuery();

  const [videoMinutes, setVideoMinutes] = useState(null);
  const [originalDataCount, setOriginalDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const handleDateChange = (data) => {
    updateQuery({ monthInt: data?.monthInt, year: data?.year });
  };

  const selectedClient = useMemo(() => {
    const firstPath = window.location.pathname.split('/')[1];
    return (
      CLIENT_WISE_MINUTE_PAYLOAD[query?.client] ||
      (firstPath === 'smarter-ai'
        ? CLIENT_WISE_MINUTE_PAYLOAD['smarter_ai']
        : CLIENT_WISE_MINUTE_PAYLOAD['customer'])
    );
  }, [query?.client]);

  const getVideoMinutesHistogramData = useCallback(async () => {
    try {
      const request = api.ac.v5['video-minutes'].tenants
        .$tenantId(selectedTenant || tenantId)
        .histogram.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            monthOfTheYear: selectedMonth,
            year: selectedYear,
            ...selectedClient,
          },
        });
      const result = await request?.process();
      return result;
    } catch (error) {
      return {};
    }
  }, [secretToken, selectedMonth, selectedYear, selectedClient, selectedTenant, tenantId]);

  const getVideoMinutesHistogramEstimatedData = useCallback(async () => {
    try {
      const isFuture = checkIsCurrentOrFuture(selectedYear, selectedMonth);
      if (!isFuture) return {};
      const request = api.ac.v5['video-minutes'].tenants
        .$tenantId(selectedTenant || tenantId)
        .estimation.$get({
          headers: {
            Authorization: secretToken,
          },
          // @ts-ignore
          params: {
            monthOfTheYear: selectedMonth,
            year: selectedYear,
            ...selectedClient,
          },
        });
      const result = await request?.process();
      return formatKeyValueEstimateData(result?.estimatedVideoMinutesUsages, selectedClient);
    } catch (ex) {
      return {};
    }
  }, [secretToken, selectedMonth, selectedYear, selectedClient, selectedTenant, tenantId]);

  const getVideoMinutesSubscriptionData = useCallback(async () => {
    try {
      const request = api.ac.v5['video-minutes'].subscriptions.tenants
        .$tenantId(selectedTenant)
        .$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            monthOfTheYear: selectedMonth,
            year: selectedYear,
            //...selectedClient,
          },
        });
      const result = await request?.process();
      const data = calculateDailySubscriptionData(result);
      setSubscriptionData(data?.map((i) => i.value));
    } catch (ex) {
      return {};
    }
  }, [secretToken, selectedMonth, selectedYear, selectedTenant]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce(async () => {
      if (!selectedYear || !selectedMonth) return;
      setLoading(true);

      try {
        await getVideoMinutesSubscriptionData();
        const histogramData = await getVideoMinutesHistogramData();
        const estimateData = await getVideoMinutesHistogramEstimatedData();
        setOriginalDataCount(histogramData?.videoMinutesUsagesHistograms?.length);
        if (histogramData?.videoMinutesUsagesHistograms) {
          const updatedData = processVideoMinutesChartData(
            histogramData.videoMinutesUsagesHistograms,
            estimateData,
            selectedYear,
            selectedMonth,
            query?.client
          );
          setVideoMinutes(updatedData);
        } else {
          setOriginalDataCount(0);
          setVideoMinutes({ minutes: [], maxDuration: 0 });
        }
      } catch (error) {
        console.error('Error fetching video minutes data:', error);
        setOriginalDataCount(0);
        setVideoMinutes({ minutes: [], maxDuration: 0 });
      } finally {
        setLoading(false);
      }
    }, 1000),
    [
      query?.client,
      selectedYear,
      selectedMonth,
      getVideoMinutesHistogramData,
      getVideoMinutesHistogramEstimatedData,
    ]
  );

  useEffect(() => {
    const currentMonth = query?.monthInt || new Date().getMonth() + 1; //1 index month
    const currentYear = query?.year || new Date().getFullYear();
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
  }, [query?.monthInt, query?.year]);

  useEffect(() => {
    setLoading(true);
    debouncedFetchData();
    return () => debouncedFetchData?.cancel();
  }, [debouncedFetchData]);

  useEffect(() => {
    if (!selectedTenant) return;
    updateQuery({ searchedCameras: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  return (
    <Box>
      <Box width={{ xs: '100%', md: '600px' }}>
        <AccessEntitySelection onSelectTenant={setSelectedTenant} />
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" justifyContent={'flex-end'}>
          <YearMonthPicker
            {...(query?.year && { defaultYear: Number(query.year) })}
            {...(query?.monthInt && { defaultMonth: Number(query.monthInt) })}
            currentYearMonth={false}
            onChange={(data) => {
              handleDateChange(data);
            }}
            minYear={2022}
          />
        </Box>

        <VideoMinutesGraph
          videoMinutes={videoMinutes}
          loading={loading}
          originalMinutesCount={originalDataCount}
          subscriptionData={subscriptionData}
        />

        <VideoMinutesFilterContextProvider>
          <VideoMinutesBreakDownFilteringArea textView={true} />
          <VideoMinutesEndpointTriggerTable selectedTenant={selectedTenant} />
        </VideoMinutesFilterContextProvider>
      </Box>
    </Box>
  );
}
