import * as d3 from 'd3';
import { useEffect, useMemo, useRef, useState } from 'react';
import { VIDEO_MINUTES_CHART_HEIGHT, VIDEO_MINUTES_CHART_MARGIN_LEFT } from '../../assets';

export function VideoMinutedCumulativeLine(props) {
  const { results, parentWidth, cumulativeMaxDuration, subscriptionMaxData } = props;

  const canvasRef = useRef(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const animationSpeed = 100;

  const maxValue = useMemo(
    () => Math.max(cumulativeMaxDuration, subscriptionMaxData),
    [cumulativeMaxDuration, subscriptionMaxData]
  );

  const extraHeight = useMemo(() => {
    const adjustedHeight =
      maxValue === cumulativeMaxDuration
        ? VIDEO_MINUTES_CHART_HEIGHT
        : VIDEO_MINUTES_CHART_HEIGHT * Math.abs(subscriptionMaxData / cumulativeMaxDuration);

    const extraHeight = adjustedHeight - VIDEO_MINUTES_CHART_HEIGHT;
    return extraHeight > 200 ? 195 : extraHeight;
  }, [cumulativeMaxDuration, subscriptionMaxData, maxValue]);

  useEffect(() => {
    if (!parentWidth || !results) return;

    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;

    const width = canvas?.width;
    const height = canvas?.height;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Parse data
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(results, (d) => new Date(d.time)))
      .range([VIDEO_MINUTES_CHART_MARGIN_LEFT, width]);

    // Use cumulativeMaxDuration for yScale
    const yScale = d3
      .scaleLinear()
      .domain([0, cumulativeMaxDuration]) // Updated to use cumulativeMaxDuration
      .range([height - extraHeight, 0]);

    let lastIndexBeforePredicted = results.length - 1;
    let predictedStarted = false;

    // Find the last non-predicted point before the first predicted one
    for (let i = 0; i < results.length; i++) {
      if (results?.at(i)?.predicted) {
        lastIndexBeforePredicted = i - 2;
        predictedStarted = true;
        break;
      }
    }

    let currentIndex = 0;

    function drawLine() {
      const now = Date.now();
      if (now - lastUpdateTime < animationSpeed) {
        setAnimationFrameId(requestAnimationFrame(drawLine));
        return;
      }

      setLastUpdateTime(now);

      ctx.clearRect(0, 0, width, height);

      // Draw the line gradually
      ctx.beginPath();
      ctx.lineWidth = 2.5;
      ctx.strokeStyle = '#257FE9';

      // Draw solid line up to the last predicted: false point
      ctx.setLineDash([]); // Solid line
      if (currentIndex === 0) {
        ctx.moveTo(xScale(new Date(results?.at(0)?.time)), yScale(results?.at(0)?.cumulativeValue));
      }

      for (let i = currentIndex; i < results.length; i++) {
        const x = xScale(new Date(results?.at(i)?.time));
        const y = yScale(results?.at(i)?.cumulativeValue);

        if (!results?.at(i)?.predicted && i <= lastIndexBeforePredicted) {
          ctx.lineTo(x, y);
        }

        if (i === lastIndexBeforePredicted + 1 && predictedStarted) {
          ctx.lineTo(x, y);
          ctx.stroke();

          // Switch to dotted line
          ctx.setLineDash([5, 5]);
          ctx.beginPath();
          ctx.moveTo(x, y);
        }

        if (results?.at(i)?.predicted && i > lastIndexBeforePredicted) {
          ctx.lineTo(x, y);
        }

        // Exit if the line drawing is complete
        if (i >= results.length - 1) {
          ctx.stroke();
          cancelAnimationFrame(animationFrameId);
          return;
        }
      }

      // Update index and schedule next frame
      currentIndex++;
      setAnimationFrameId(requestAnimationFrame(drawLine));
    }

    // Start animation
    drawLine();

    // Cleanup animation frame on component unmount or data change
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, parentWidth, cumulativeMaxDuration, subscriptionMaxData]); // Add cumulativeMaxDuration to dependency array

  if (!maxValue) return;

  return (
    <canvas
      ref={canvasRef}
      width={parentWidth}
      height={VIDEO_MINUTES_CHART_HEIGHT}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 100,
        marginTop: `${8 + extraHeight}px`,
        pointerEvents: 'none',
      }}
    />
  );
}
