import {
  SMARTER_AI_ICON,
  SMARTER_AI_ICON_WHITE,
  VISION_TEAM_ICON,
  VISION_TEAM_ICON_WHITE,
} from '@/assets/constants/images';
import { Group } from '@mui/icons-material';

export const VIDEO_MINUTES_CHART_MARGIN_LEFT = 45;
export const VIDEO_MINUTES_CHART_HEIGHT = 200;

export const VIDEO_MINUTES_TYPE_MAPPING = {
  LIVE_STREAMING: 'Live Streaming',
  EVENT_PLAYBACK: 'Event Playback',
  EVENT_UPLOAD: 'Event Upload',
  SHADOW_EVENT_PLAYBACK: 'Shadow Event Playback',
  SHADOW_EVENT_UPLOAD: 'Shadow Event Upload',
  RECORDING_PLAYBACK: 'Recall Playback',
  RECORDING_UPLOAD: 'Recall Upload',
};

export const VIDEO_MINUTES_TYPE_COLOR_MAPPING = {
  LIVE_STREAMING: '#823FEB',
  EVENT_PLAYBACK: '#89D04D',
  EVENT_UPLOAD: '#FFCB57',
  SHADOW_EVENT_PLAYBACK: '#89D04D',
  SHADOW_EVENT_UPLOAD: '#FFCB57',
  RECORDING_PLAYBACK: '#F58E42',
  RECORDING_UPLOAD: '#2DAED6',
};

export const MONTHS_ARRAY = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const CLIENT_WISE_MINUTE_PAYLOAD = {
  vision_team: {
    includeCustomerUse: false,
    includeShadowUse: true,
    includeSAIUserUse: true,
  },
  customer: {
    includeCustomerUse: true,
    includeShadowUse: false,
    includeSAIUserUse: false,
  },
  smarter_ai: {
    includeCustomerUse: false,
    includeShadowUse: false,
    includeSAIUserUse: true,
  },
};

export const VIDEO_MINUTES_CLIENT_ITEMS = [
  {
    key: 'customer',
    label: 'Customer',
    icon: <Group fontSize="small" />,
    activeIcon: <Group htmlColor="white" fontSize="small" />,
    iconType: 'ICON',
  },
  {
    key: 'smarter_ai',
    label: 'Smarter AI',
    img: SMARTER_AI_ICON,
    activeImage: SMARTER_AI_ICON_WHITE,
    iconType: 'IMAGE',
  },
  {
    key: 'vision_team',
    label: 'Vision Team',
    img: VISION_TEAM_ICON,
    activeImage: VISION_TEAM_ICON_WHITE,
    iconType: 'IMAGE',
    imageSize: '17px',
  },
];

export const ENTITY_TYPE = {
  TENANT_ROOT: 'TR',
  TENANT_PARENT_X: 'TPX',
  TENANT_PARENT: 'TP',
  TENANT: 'T',
  ENDPOINT: 'E',
};
