import { USER_FALLBACK_ICON } from '@/assets/constants/images';
import { getEmailIcon } from '@/utils/formatting';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { Typography } from '@mui/material';
import { startCase } from 'lodash';

/** @type {Array<TableColumn<UserWithAccountDetails>>} */
export const USER_TABLE_COLUMNS = [
  {
    id: 'userId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'userId'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled={!row.active} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    format: (value, row) => {
      return (
        <IconLabelItem
          // @ts-ignore
          src={row?.imageUrl || USER_FALLBACK_ICON}
          title={<LongTextShortener text={value} length={30} />}
          sx={{ gap: 3, alignItems: 'center', fontSize: '0.875rem' }}
          iconSize={{ xs: '17px', md: '30px' }}
        />
      );
    },
    link: (row) => `/administration/users/${row.userId}`,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    isPrimary: true,
    format: (value) => {
      const icon = getEmailIcon(value);
      const isSMarterAiIcon = icon?.includes('/icon.svg');
      return (
        <IconLabelItem
          src={icon}
          title={value}
          sx={{
            gap: isSMarterAiIcon ? 1 : 1.6,
            alignItems: 'center',
          }}
          iconSize={{ xs: '17px', md: isSMarterAiIcon ? '26px' : '19px' }}
        />
      );
    },
    link: (row) => `/administration/users/${row.userId}`,
  },
  {
    id: 'role',
    label: 'Role',
    align: 'left',
    format: (value) => (
      <Typography fontSize="0.875rem">{value ? startCase(value.toLowerCase()) : '...'}</Typography>
    ),
  },
  {
    id: 'accessChildTenant',
    label: 'Child Tenant Access',
    align: 'center',
    format: (value) => (
      <Typography fontSize="0.875rem">{value ? 'Enabled' : 'Disabled'}</Typography>
    ),
  },
  {
    id: 'active',
    label: 'Status',
    align: 'left',
    format: (value) => <Typography fontSize="0.875rem">{value ? 'Active' : 'Inactive'}</Typography>,
  },
];
