import { CHART_NO_DATA_FOUND } from '@/assets/constants/images';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { generateEvenlySpacedArray } from '@/web/safety/scoring/utils';
import { Box, CircularProgress } from '@mui/material';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VideoMinuteConsumptionLimit } from '../VideoMinuteConsumptionLimit';
import { VideoMinutedCumulativeLine } from '../VideoMinutedCumulativeLine';
import { VideoMinutesBarYAxis } from '../VideoMinutesBarXAxis';
import { VideoMinutesBarXAxis } from '../VideoMinutesBarYAxis';
import { VideoMinutesChartLegends } from '../VideoMinutesChartLegends';
import { max } from 'lodash';

export function VideoMinutesGraph(props) {
  const { videoMinutes, originalMinutesCount, subscriptionData, loading } = props;

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  const results = useMemo(() => {
    const minutes = videoMinutes?.minutes ?? [];
    const data = minutes?.map((item) => ({
      time: item?.timestamp,
      value: item?.totalDurationMinutes,
      videoMinuteUsages: item?.videoMinuteUsages,
      predicted: item?.predicted,
    }));
    let cumulativeSum = 0;
    return data.map((d) => {
      cumulativeSum += d.value;
      return {
        ...d,
        cumulativeValue: cumulativeSum,
      };
    });
  }, [videoMinutes?.minutes]);

  const videMinutesMaxDuration = useMemo(() => {
    return Math.max(videoMinutes?.maxDuration + 5, 0);
  }, [videoMinutes?.maxDuration]);

  const isUsagesSeventyPercentOfSubscription = useMemo(() => {
    const subsMaxData = max(subscriptionData) || 0;
    const isSeventyPercent = videMinutesMaxDuration >= subsMaxData * 0.7;
    return isSeventyPercent;
  }, [videMinutesMaxDuration, subscriptionData]);

  const hasSubscriptionMaxData = useMemo(() => {
    const data = max(subscriptionData) || 0;
    return data > 0 ? true : false;
  }, [subscriptionData]);

  const subscriptionMaxData = useMemo(() => {
    if (!isUsagesSeventyPercentOfSubscription) return videoMinutes?.maxDuration;
    return Math.max(videoMinutes?.maxDuration, max(subscriptionData) || 0);
  }, [videoMinutes?.maxDuration, subscriptionData, isUsagesSeventyPercentOfSubscription]);

  const cumulativeMaxDuration = useMemo(
    () => Math.max(...results.map((d) => d.cumulativeValue), 0),
    [results]
  );

  const yAxisItems = useMemo(() => {
    return generateEvenlySpacedArray(0, videMinutesMaxDuration, 5);
  }, [videMinutesMaxDuration]);

  const yAxisCumulativeItems = useMemo(() => {
    if (!results || results.length === 0) return [];
    return generateEvenlySpacedArray(0, Math.max(cumulativeMaxDuration, subscriptionMaxData), 5);
  }, [results, subscriptionMaxData, cumulativeMaxDuration]);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.getBoundingClientRect().width;
        if (width !== 0) {
          setParentWidth(width);
          clearInterval(checkInterval); // Stop checking once width is non-zero
        }
      }
    };
    // Set an interval to keep checking the width until it's no longer zero
    const checkInterval = setInterval(updateWidth, 100);

    window.addEventListener('resize', updateWidth);
    // Clean up
    return () => {
      clearInterval(checkInterval); // Clear interval on unmount
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  if (loading) {
    return (
      <CenterBox style={{ height: '250px', border: '1px solid #dfe4ec' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box pt={2}>
      <VideoMinutesChartLegends />

      <Box ref={parentRef} sx={{ position: 'relative', width: '96%' }} mt={2}>
        {cumulativeMaxDuration === 0 ? (
          <Box
            position="absolute"
            top="10px"
            left={'56px'}
            right={0}
            display="flex"
            justifyContent="center"
            zIndex={1000}
          >
            <IconMessageBox size="120px" src={CHART_NO_DATA_FOUND} message="No data found" />
          </Box>
        ) : null}

        {/* Draw Y axis */}
        <VideoMinutesBarYAxis
          yAxisItems={yAxisItems}
          yAxisCumulativeItems={yAxisCumulativeItems}
          maxDuration={videMinutesMaxDuration}
          cumulativeMaxDuration={cumulativeMaxDuration}
        />

        {/* Draw X axis and Bars */}
        <VideoMinutesBarXAxis results={results} maxDuration={videMinutesMaxDuration} />

        {/* Cumulative Line chart */}
        {results?.length && originalMinutesCount > 0 ? (
          <VideoMinutedCumulativeLine
            results={results}
            parentWidth={parentWidth}
            cumulativeMaxDuration={cumulativeMaxDuration}
            subscriptionMaxData={subscriptionMaxData}
          />
        ) : null}

        {/* Consumption limit chart */}
        {!loading &&
        originalMinutesCount > 0 &&
        hasSubscriptionMaxData &&
        isUsagesSeventyPercentOfSubscription ? (
          <VideoMinuteConsumptionLimit
            subscriptionData={subscriptionData}
            parentWidth={parentWidth}
            cumulativeMaxDuration={cumulativeMaxDuration}
            subscriptionMaxData={subscriptionMaxData}
          />
        ) : null}
      </Box>
    </Box>
  );
}
