import { useRouteQuery } from '@/hooks/useRouteQuery';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CustomerSelectionMenu } from './@components/CustomerSelectionMenu';
import { VideoMinutesProvider } from './@context/VideoMinutesContext';
import { VideoMinutesBreakDown } from './breakdown';
import { VideoMinutesOverview } from './overview';
import { VideoMinutesFilterContextProvider } from './@context/VideoMinutesFilterContext';

const locations = [
  {
    key: 'overview',
    label: 'Overview',
    breadcrumb: 'Video Minutes',
    disabled: false,
    component: <VideoMinutesOverview />,
  },
  {
    key: 'breakdown',
    label: 'Breakdown',
    breadcrumb: 'Video Minutes',
    disabled: false,
    component: (
      <VideoMinutesProvider>
        <VideoMinutesBreakDown />
      </VideoMinutesProvider>
    ),
  },
];

export function VideoMinutesLandingPage() {
  const { setBreadcrumbTitle } = useContext(MainContext) || {};

  const { query, updateQuery } = useRouteQuery();

  const [selectedParentKey, setSelectedParentKey] = useState(query?.type || 'overview');

  const handleChange = (event, newValue) => {
    setSelectedParentKey(newValue);
    updateQuery({ type: newValue });
  };

  useEffect(() => {
    const item = locations?.find((i) => i?.key === selectedParentKey);
    if (item) {
      setBreadcrumbTitle(item.breadcrumb);
    }
  }, [setBreadcrumbTitle, selectedParentKey]);

  useEffect(() => {
    if (query?.type) {
      setSelectedParentKey(query.type);
    }
  }, [query?.type]);

  return (
    <Box mx={2}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'left', md: 'center' }}
        pt={2}
        sx={{
          '& .MuiButtonBase-root': {
            textTransform: 'none',
          },
        }}
      >
        <Tabs
          value={selectedParentKey}
          onChange={handleChange}
          aria-label="APK Tabs"
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          sx={{
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
              color: '#4177BF !important',
            },
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-disabled': {
              color: '#A6AFBA !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#4177BF !important',
            },
            '.MuiTabs-scroller': {
              height: '40px',
            },
          }}
        >
          {locations?.map((item, index) => (
            <Tab
              disabled={item?.disabled}
              key={index}
              value={item.key}
              label={item.label}
              sx={{ fontSize: '1rem' }}
            />
          ))}
        </Tabs>
        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} gap={1}>
          <CustomerSelectionMenu />
        </Box>
      </Box>

      {/* Render the selected component */}
      <Box mt={1}>
        <VideoMinutesFilterContextProvider>
          {locations
            .filter((item) => item.key === selectedParentKey)
            .map((item) => (
              <Box key={item.key}>{item.component}</Box>
            ))}
        </VideoMinutesFilterContextProvider>
      </Box>
    </Box>
  );
}
