import { restoreAuthState, store } from '@/store';
import { selectTenantId } from '@/store/auth';
import { PageView } from '@/store/page-view';
import { fetchMyGeoInfo } from '@/utils/geoinfo';
import { setTriggerConfigDefaultValue } from '@/utils/triggers';
import { throttle } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function Preload() {
  const tenantId = useSelector(selectTenantId);

  useEffect(() => {
    const aborter = new AbortController();

    fetchMyGeoInfo().catch(console.log);

    const handleVisibility = throttle(() => {
      if (document.visibilityState === 'visible') {
        restoreAuthState().catch(console.error);
      }
      store.dispatch(PageView.setTabVisible(document.visibilityState === 'visible'));
    }, 100);
    document.addEventListener('visibilitychange', handleVisibility, {
      passive: true,
      signal: aborter.signal,
    });

    return () => {
      aborter.abort();
      handleVisibility.cancel();
    };
  }, []);

  useEffect(() => {
    setTriggerConfigDefaultValue(tenantId);
  }, [tenantId]);

  return null;
}
