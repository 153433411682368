import * as asset from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { EDGE_TENSOR_REGEX } from '@/utils/user-access';

/** @type {Array<SidebarItem>} */
export const SIDEBAR_ITEMS = [
  {
    key: 'cameras',
    name: T['navigation.cameras'],
    image: asset.CAMERA_N_OFF_ICON,
    activeImage: asset.CAMERA_N_ON_ICON,
    link: '/cameras',
    accessLevel: 'USER',
  },
  {
    key: 'events',
    name: T['navigation.events'],
    image: asset.EVENTS_N_OFF_ICON,
    activeImage: asset.EVENTS_N_ON_ICON,
    link: `/events?from=${minusOneMonth()}&to=${endOfTheDay()}`,
    accessLevel: 'USER',
  },
  {
    key: 'trips',
    name: T['navigation.trips'],
    image: asset.TRIPS_OFF_ICON,
    activeImage: asset.TRIPS_ON_ICON,
    link: `/trips?from=${minusOneMonth()}&to=${endOfTheDay()}`,
    accessLevel: 'USER',
  },
  {
    key: 'fleets',
    name: T['navigation.waste.fleets'],
    image: asset.FLEET_OFF_ICON,
    activeImage: asset.FLEET_ON_ICON,
    link: '/fleets',
    accessLevel: 'USER',
    children: [
      {
        key: 'live-tracking',
        name: T['navigation.fleet.live.tracking'],
        image: asset.LIVE_TRACKING_ICON_OFF,
        activeImage: asset.LIVE_TRACKING_ICON_ON,
        link: '/fleets/live-tracking',
        accessLevel: 'USER',
      },
      {
        key: 'drivers',
        name: T['navigation.drivers'],
        image: asset.DRIVER_OFF_ICON,
        activeImage: asset.DRIVER_ON_ICON,
        link: '/fleets/drivers',
        accessLevel: 'ADMIN',
      },
      {
        key: 'driver-group',
        name: T['navigation.driver.groups'],
        image: asset.VIN_GROUP_ICON_OFF,
        activeImage: asset.VIN_GROUP_ICON_ON,
        link: '/fleets/driver-groups',
        accessLevel: 'HIDDEN',
      },
      {
        key: 'driver-tags',
        name: T['navigation.driver.tags'],
        image: asset.DRIVER_TAG_ICON_OFF,
        activeImage: asset.DRIVER_TAG_ICON_ON,
        link: '/fleets/driver-tags',
        accessLevel: 'ADMIN',
      },
    ],
  },
  {
    key: 'safety',
    name: T['navigation.safety'],
    image: asset.SAFETY_ICON_OFF,
    activeImage: asset.SAFETY_ICON_ON,
    link: '/safety',
    accessLevel: 'USER',
    children: [
      {
        key: 'scoring',
        name: T['navigation.scoring'],
        image: asset.SCORING_ICON_OFF,
        activeImage: asset.SCORING_ICON_ON,
        link: '/safety/scoring',
      },
      {
        key: 'review',
        name: T['navigation.review'],
        image: asset.REVIEW_ICON_OFF,
        activeImage: asset.REVIEW_ICON_ON,
        link: '/safety/reviews',
        accessLevel: 'HIDDEN',
      },
      {
        key: 'coaching',
        //serviceAccessKey: 'COACHING',
        name: T['navigation.coaching'],
        image: asset.COACHING_ICON_OFF,
        activeImage: asset.COACHING_ICON_ON,
        link: '/safety/coaching',
      },
    ],
  },
  {
    key: 'operations',
    name: T['navigation.operations'],
    image: asset.OPERATION_ICON_OFF,
    activeImage: asset.OPERATION_ICON_ON,
    link: '/operations',
    accessLevel: 'ADMIN',
    children: [
      {
        key: 'annotations',
        name: T['navigation.annotations'],
        image: asset.ANNOTATION_ICON_OFF,
        activeImage: asset.ANNOTATION_ICON_ON,
        link: '/operations/annotations',
      },
      {
        key: 'geofences',
        name: T['navigation.operations.geofences'],
        image: asset.GEOFENCE_ICON_OFF,
        activeImage: asset.GEOFENCE_ICON_ON,
        link: '/operations/geofences',
      },
    ],
  },
  {
    key: 'administration',
    name: T['navigation.administration'],
    image: asset.ADMINISTRATION_ICON_OFF,
    activeImage: asset.ADMINISTRATION_ICON_ON,
    link: '/administration',
    accessLevel: 'ADMIN',
    children: [
      {
        key: 'ai',
        name: T['navigation.ai'],
        image: asset.AI_OFF_ICON,
        activeImage: asset.AI_ON_ICON,
        link: '/administration/ai',
        accessLevel: 'HIDDEN',
        emailAccess: [EDGE_TENSOR_REGEX],
        teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
        children: [
          {
            key: 'ai-models',
            name: T['navigation.ai.models'],
            image: asset.AI_MODEL_ICON_OFF,
            activeImage: asset.AI_MODEL_ICON_ON,
            link: '/administration/ai/ai-models',
          },
          {
            key: 'ai-containers',
            name: T['navigation.ai.container'],
            image: asset.AI_CONTAINER_ICON_OFF,
            activeImage: asset.AI_CONTAINER_ICON_ON,
            link: '/administration/ai/ai-containers',
          },
        ],
      },
      {
        key: 'device-groups',
        name: T['navigation.device.groups'],
        image: asset.DEVICE_GROUP_ICON_OFF,
        activeImage: asset.DEVICE_GROUP_ICON_ON,
        link: '/administration/device-groups',
      },
      {
        key: 'trigger-sensitivity',
        name: 'Triggers',
        image: asset.TRIGGER_SENSITIVITY_ICON_OFF,
        activeImage: asset.TRIGGER_SENSITIVITY_ICON_ON,
        link: '/administration/trigger-configuration',
      },
      {
        key: 'device-pools',
        name: T['navigation.device.pools'],
        image: asset.DEVICE_POOL_ICON_OFF,
        activeImage: asset.DEVICE_POOL_ICON_ON,
        link: '/administration/device-pools',
      },
      {
        key: 'vin-groups',
        name: T['navigation.vin.groups'],
        image: asset.VIN_GROUP_ICON_OFF,
        activeImage: asset.VIN_GROUP_ICON_ON,
        link: '/administration/vin-groups',
      },
      {
        key: 'tenants',
        name: T['navigation.tenants'],
        image: asset.TENANT_ICON_OFF,
        activeImage: asset.TENANT_ICON_ON,
        link: '/administration/tenants',
      },
      {
        key: 'users',
        name: T['navigation.users'],
        image: asset.USERS_ICON_OFF,
        activeImage: asset.USERS_ICON_ON,
        link: '/administration/users',
      },
      {
        key: 'scheme-view',
        name: T['navigation.scheme'],
        image: asset.SCHEME_LANDING_ICON_OFF,
        activeImage: asset.SCHEME_LANDING_ICON_ON,
        link: '/administration/schemes/',
        children: [
          {
            key: 'scheme-list',
            name: 'View Schemes',
            image: asset.SCHEME_LIST_ICON_OFF,
            activeImage: asset.SCHEME_LIST_ICON_ON,
            link: '/administration/schemes/list',
            exact: true,
          },
          {
            key: 'apply-schemes',
            name: 'Apply Scheme',
            image: asset.SCHEME_VIEW_MODE_ICON_OFF,
            activeImage: asset.SCHEME_VIEW_MODE_ICON_ON,
            link: '/administration/schemes/apply',
            exact: true,
          },
        ],
      },
      {
        key: 'video-minutes',
        name: T['navigation.video-minutes'],
        image: asset.VIDEO_MINUTES_OFF,
        activeImage: asset.VIDEO_MINUTES_ON,
        link: '/administration/video-minutes',
        accessLevel: 'ADMIN',
      },
      {
        key: 'my-notifications',
        name: 'My Notification',
        image: asset.MY_NOTIFICATION_OFF,
        activeImage: asset.MY_NOTIFICATION_ON,
        link: '/administration/my-notifications',
      },
      {
        key: 'dbc',
        name: T['navigation.dbc'],
        image: asset.DBC_OFF_ICON,
        activeImage: asset.DBC_ON_ICON,
        link: '/administration/dbc',
      },
      {
        key: 'apk-files',
        name: T['navigation.apk.files'],
        image: asset.APK_OFF_ICON,
        activeImage: asset.APK_ON_ICON,
        link: '/administration/apk-files',
      },
    ],
  },
  // {
  //   key: 'waste-management',
  //   name: T['navigation.waste.management'],
  //   image: asset.WASTE_MANAGEMENT_ICON_OFF,
  //   activeImage: asset.WASTE_MANAGEMENT_ICON_ON,
  //   link: '/waste-management',
  //   accessLevel: 'HIDDEN',
  //   children: [
  //     {
  //       key: 'waste-operations',
  //       name: T['navigation.operations'],
  //       image: asset.NAV_OPERATION_ICON_OFF,
  //       activeImage: asset.NAV_OPERATION_ICON_ON,
  //       link: '/waste-management/operations',
  //     },
  //     {
  //       key: 'waste-configurations',
  //       name: T['navigation.configurations'],
  //       image: asset.NAV_CONFIGURATION_ICON_OFF,
  //       activeImage: asset.NAV_CONFIGURATION_ICON_ON,
  //       link: '/waste-management/configurations',
  //     },
  //     {
  //       key: 'waste-reports',
  //       name: T['navigation.reports'],
  //       image: asset.NAV_REPORT_ICON_OFF,
  //       activeImage: asset.NAV_REPORT_ICON_ON,
  //       link: '/waste-management/reports',
  //     },
  //   ],
  // },
  // {
  //   key: 'winter-operations',
  //   name: T['navigation.winter.operations'],
  //   image: asset.WINTER_OPERATION_ICON_OFF,
  //   activeImage: asset.WINTER_OPERATION_ICON_ON,
  //   link: '/winter-operations',
  //   accessLevel: 'HIDDEN',
  //   children: [
  //     {
  //       key: 'winter-gallery',
  //       name: T['navigation.gallery'],
  //       image: asset.NAV_GALLERY_ICON_OFF,
  //       activeImage: asset.NAV_GALLERY_ICON_ON,
  //       link: '/winter-operations/gallery',
  //     },
  //     {
  //       key: 'winter-configurations',
  //       name: T['navigation.configurations'],
  //       image: asset.NAV_CONFIGURATION_ICON_OFF,
  //       activeImage: asset.NAV_CONFIGURATION_ICON_ON,
  //       link: '/winter-operations/configurations',
  //     },
  //     {
  //       key: 'winter-reports',
  //       name: T['navigation.reports'],
  //       image: asset.NAV_REPORT_ICON_OFF,
  //       activeImage: asset.NAV_REPORT_ICON_ON,
  //       link: '/winter-operations/reports',
  //     },
  //   ],
  // },
  {
    key: 'data-privacy',
    name: T['navigation.privacy.and.compliance'],
    image: asset.DATA_PRIVACY_ICON_OFF,
    activeImage: asset.DATA_PRIVACY_ICON_ON,
    link: '/data-privacy',
    accessLevel: 'SUPER_ADMIN',
    children: [
      {
        key: 'data-residency',
        name: T['navigation.data.residency'],
        image: asset.DATA_RESIDENCY_ICON_OFF,
        activeImage: asset.DATA_RESIDENCY_ICON_ON,
        link: '/data-privacy/data-residency',
      },
      {
        key: 'data-retention',
        name: T['navigation.data.retention'],
        image: asset.DATA_RETENTION_ICON_OFF,
        activeImage: asset.DATA_RETENTION_ICON_ON,
        link: '/data-privacy/data-retention',
        accessLevel: 'HIDDEN',
      },
      {
        key: 'data-privacy-policies',
        name: T['navigation.privacy.policies'],
        image: asset.DATA_PRIVACY_POLICY_ICON_OFF,
        activeImage: asset.DATA_PRIVACY_POLICY_ICON_ON,
        link: '/data-privacy/privacy-policies',
        accessLevel: 'HIDDEN',
      },
    ],
  },
  {
    key: 'smarter-ai',
    name: T['navigation.smarter.ai'],
    image: asset.ORGANIZATION_ICON_OFF,
    activeImage: asset.ORGANIZATION_ICON_ON,
    link: '/smarter-ai',
    accessLevel: 'SMARTER_AI_ADMIN',
    children: [
      {
        key: 'events',
        name: T['navigation.events'],
        image: asset.EVENTS_N_OFF_ICON,
        activeImage: asset.EVENTS_N_ON_ICON,
        link: `/smarter-ai/events?from=${minusOneMonth()}&to=${endOfTheDay()}`,
      },
      {
        key: 'ai-accelerator',
        name: T['navigation.ai.accelerator'],
        image: asset.AI_ACCELERATOR_OFF_ICON,
        activeImage: asset.AI_ACCELERATOR_ON_ICON,
        link: '/smarter-ai/ai-accelerator',
      },
      {
        key: 'firmwares',
        name: T['navigation.firmwares'],
        image: asset.FIRMWARE_ICON_OFF,
        activeImage: asset.FIRMWARE_ICON_ON,
        link: '/smarter-ai/firmwares',
      },
      {
        key: 'triggers',
        name: T['navigation.triggers'],
        image: asset.TRIGGERS_ICON_OFF,
        activeImage: asset.TRIGGERS_ICON_ON,
        link: '/smarter-ai/triggers',
        accessLevel: 'HIDDEN',
        emailAccess: [EDGE_TENSOR_REGEX],
        teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
      },
      {
        key: 'labels',
        name: T['navigation.operations.labels'],
        image: asset.LABEL_ICON_OFF,
        activeImage: asset.LABEL_ICON_ON,
        link: '/smarter-ai/labels',
      },
      {
        key: 'products',
        name: T['navigation.products'],
        image: asset.PRODUCT_ICON_OFF,
        activeImage: asset.PRODUCT_ICON_ON,
        link: '/smarter-ai/products',
      },
      {
        key: 'device-batches',
        name: T['navigation.device.batches'],
        image: asset.DEVICE_BATCH_ICON_OFF,
        activeImage: asset.DEVICE_BATCH_ICON_ON,
        link: '/smarter-ai/device-batches',
      },
      {
        key: 'onboard-logs',
        name: T['onboard.page.card.name'],
        image: asset.ONBOARD_ICON_OFF,
        activeImage: asset.ONBOARD_ICON_ON,
        link: '/smarter-ai/onboard-logs',
      },
      {
        key: 'scheme-management',
        name: T['navigation.scheme'],
        image: asset.SCHEME_ICON_OFF,
        activeImage: asset.SCHEME_ICON_ON,
        link: '/smarter-ai/scheme-management',
        children: [
          {
            key: 'schemes',
            name: T['navigation.view.scheme'],
            image: asset.SCHEME_LIST_ICON_OFF,
            activeImage: asset.SCHEME_LIST_ICON_ON,
            link: '/smarter-ai/scheme-management/schemes',
          },
          {
            key: 'tenant-deployment',
            name: T['page.card.tenant.deployment'],
            image: asset.ASSIGN_CHILD_ICON,
            activeImage: asset.ASSIGN_CHILD_ICON_ON,
            link: '/smarter-ai/scheme-management/tenant-deployment',
          },
        ],
      },
      {
        key: 'monitoring',
        name: T['navigation.monitoring'],
        image: asset.AI_ACCELERATOR_OFF_ICON,
        activeImage: asset.AI_ACCELERATOR_OFF_ICON,
        link: '/smarter-ai/monitoring',
        accessLevel: 'SMARTER_AI_ADMIN',
        children: [
          {
            key: 'firmwares',
            name: T['navigation.firmwares'],
            image: asset.FIRMWARE_ICON_OFF,
            activeImage: asset.FIRMWARE_ICON_ON,
            link: '/smarter-ai/monitoring/firmwares',
            children: [
              {
                key: 'firmware-cameras',
                name: T['navigation.firmwares.cameras'],
                image: asset.CAMERA_N_OFF_ICON,
                activeImage: asset.CAMERA_N_ON_ICON,
                link: '/smarter-ai/monitoring/firmwares/cameras',
              },
              {
                key: 'firmware-tenant',
                name: T['navigation.firmwares.tenants'],
                image: asset.TENANT_ICON_OFF,
                activeImage: asset.TENANT_ICON_ON,
                link: '/smarter-ai/monitoring/firmwares/tenants',
              },
            ],
          },
          {
            key: 'camera-health',
            name: T['camera.health.page.card.name'],
            image: asset.CAMERA_HEALTH_OFF,
            activeImage: asset.CAMERA_HEALTH_ON,
            link: '/smarter-ai/monitoring/camera-health',
            // accessLevel: 'HIDDEN',
          },
          {
            key: 'trigger-report',
            name: T['navigation.triggers.report'],
            image: asset.NAV_REPORT_ICON_OFF,
            activeImage: asset.NAV_REPORT_ICON_ON,
            link: '/smarter-ai/monitoring/triggers/trigger-report',
          },
        ],
      },
      {
        key: 'trigger-variables',
        name: T['navigation.trigger.variable'],
        image: asset.TRIGGERS_VARIABLE_ICON_OFF,
        activeImage: asset.TRIGGERS_VARIABLE_ICON_ON,
        teamAccess: ['AI_TEAM', 'FRONT_END_TEAM'],
        link: '/smarter-ai/trigger-variables',
      },
      {
        key: 'feedbacks',
        name: T['navigation.feedbacks'],
        image: asset.FEEDBACK_ICON_OFF,
        activeImage: asset.FEEDBACK_ICON_ON,
        link: '/smarter-ai/feedbacks',
      },
      {
        key: 'feature-flags',
        name: T['navigation.feature.flags'],
        image: asset.FEATURE_FLAG_OFF_ICON,
        activeImage: asset.FEATURE_FLAG_ON_ICON,
        link: '/smarter-ai/feature-flags',
      },
      {
        key: 'video-minutes',
        name: T['navigation.video-minutes'],
        image: asset.VIDEO_MINUTES_OFF,
        activeImage: asset.VIDEO_MINUTES_ON,
        link: '/smarter-ai/video-minutes',
      },
    ],
  },
];
