/* eslint-disable no-unused-vars */
import { selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [searchText]
 * @property {string} [searchedEndpoint]
 * @property {string} [searchedTenant]
 * @property {string} [month]
 * @property {string} [monthInt]
 * @property {string} [year]
 * @property {string} [client]
 * @property {Array} [searchedCameras]
 * @property {Array} [endpoints]
 * @property {Array} [tenants]
 * @property {FilterParams} [value]
 */

/** @type {FilterParams} */
const defaultValues = {
  searchText: '',
  searchedEndpoint: '',
  searchedTenant: '',
  month: '',
  monthInt: '',
  year: '',
  client: '',
  searchedCameras: [],
  endpoints: [],
  tenants: [],
};

const paramAlias = {
  searchText: 'searchText',
  searchedEndpoint: 'searchedEndpoint',
  searchedTenant: 'searchedTenant',
  month: 'month',
  monthInt: 'monthInt',
  year: 'year',
  client: 'client',
  endpoints: 'endpoints',
  tenants: 'tenants',
};

// /** @type {import("react").Context<FilterParams>} */
export const VideoMinutesFilterContext = createContext(null);

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetVideoMinutesFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetVideoMinutesFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setVideoMinutesFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return (
    <SetVideoMinutesFilterContext.Provider value={setVideoMinutesFilter}>
      {children}
    </SetVideoMinutesFilterContext.Provider>
  );
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function VideoMinutesFilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();
  const tenantId = useSelector(selectTenantId);

  const [selectedTenant, setSelectedTenant] = useState(tenantId);
  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);
  const [minutesEndpoints, setMinutesEndpoints] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [tenants, setTenants] = useState([]);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  /** @type {(value: any, defaultValue: number) => number} */
  const toNumber = (value, defaultValue) => Number(value) || defaultValue || 0;

  useEffect(() => {
    const searchText = toString(query.searchText, defaults.searchText);
    setValue((v) => (v.searchText === searchText ? v : { ...v, searchText }));
  }, [query.searchText, defaults]);

  useEffect(() => {
    const searchedEndpoint = toString(query.searchedEndpoint, defaults.searchedEndpoint);
    setValue((v) => (v.searchedEndpoint === searchedEndpoint ? v : { ...v, searchedEndpoint }));
  }, [query.searchedEndpoint, defaults]);

  useEffect(() => {
    const searchedTenant = toString(query.searchedTenant, defaults.searchedTenant);
    setValue((v) => (v.searchedTenant === searchedTenant ? v : { ...v, searchedTenant }));
  }, [query.searchedTenant, defaults]);

  useEffect(() => {
    const searchedCameras = query.searchedCameras
      ? // @ts-ignore
        query.searchedCameras?.split(',')
      : defaults.searchedCameras || [];
    setValue((v) =>
      JSON.stringify(v.searchedCameras) === JSON.stringify(searchedCameras)
        ? v
        : { ...v, searchedCameras }
    );
  }, [query.searchedCameras, defaults]);

  useEffect(() => {
    const month = toString(query.month, defaults.month);
    setValue((v) => (v.month === month ? v : { ...v, month }));
  }, [query.month, defaults]);

  useEffect(() => {
    const monthInt = toString(query.monthInt, defaults.monthInt);
    setValue((v) => (v.monthInt === monthInt ? v : { ...v, monthInt }));
  }, [query.monthInt, defaults]);

  useEffect(() => {
    const year = toString(query.year, defaults.year);
    setValue((v) => (v.year === year ? v : { ...v, year }));
  }, [query.year, defaults]);

  useEffect(() => {
    const client = toString(query.client, defaults.client);
    setValue((v) => (v.client === client ? v : { ...v, client }));
  }, [query.client, defaults]);

  return (
    <SetVideoMinutesFilterContextProvider>
      <VideoMinutesFilterContext.Provider
        value={{
          ...value,
          selectedTenant,
          minutesEndpoints,
          tenants,
          endpoints,
          setMinutesEndpoints,
          setEndpoints,
          setTenants,
        }}
      >
        {children}
      </VideoMinutesFilterContext.Provider>
    </SetVideoMinutesFilterContextProvider>
  );
}
