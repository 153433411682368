import { VideoMinutesProvider } from '@/web/smarter-ai/video-minutes/@context/VideoMinutesContext';
import { VideoMinutesBreakDown } from '@/web/smarter-ai/video-minutes/breakdown';
import { Box, Typography } from '@mui/material';

export function VideoMinutes() {
  return (
    <Box mt={2} mb={4} mx={2.5}>
      <Box py={2.5}>
        <Typography variant="body2" fontSize="1rem" fontWeight={'medium'} mb={1} color="#596A82">
          Breakdown
        </Typography>
        <VideoMinutesProvider>
          <VideoMinutesBreakDown />
        </VideoMinutesProvider>
      </Box>
    </Box>
  );
}
