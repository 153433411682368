import api from '@/api';
import { DEFAULT_GROUP_SETTINGS } from '@/assets/schemes/defaultValues';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import { sendEventsToGroup, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastWarning } from '@/utils/toaster';
import { includes, isNaN, isNull, isUndefined } from 'lodash';

/**
 * Validate Group Settings data while submitting
 * @param {{[key: string]: any}} form
 * @param {ProductWithAccelerator} product
 */

export const validateGroupSettingsData = (form, product) => {
  let errorArray = [];
  const { streamingQualityProfile, isAbtOnBitrateEnabled, isAbtOnFramerateEnabled } = form;
  const showDynamicBitrateStream = streamingQualityProfile && isAbtOnBitrateEnabled;
  const showDynamicFrameRateStream = streamingQualityProfile && isAbtOnFramerateEnabled;

  if (form.recordingBitrate === 'dynamic') {
    if (!form.recordingBitrateMax) {
      errorArray.push('recordingBitrateMax');
    }
    if (!form.recordingBitrateMin) {
      errorArray.push('recordingBitrateMin');
    }
  }
  if (form.recordingFrameRate === 'dynamic') {
    if (!form.recordingFrameRateMax) {
      errorArray.push('recordingFrameRateMax');
    }
    if (!form.recordingFrameRateMin) {
      errorArray.push('recordingFrameRateMin');
    }
  }

  if (showDynamicBitrateStream) {
    if (!form.streamingBitrateMax) {
      errorArray.push('streamingBitrateMax');
    }
    if (!form.streamingBitrateMin) {
      errorArray.push('streamingBitrateMin');
    }
  }
  if (showDynamicFrameRateStream) {
    if (!form.streamingFrameRateMax) {
      errorArray.push('streamingFrameRateMax');
    }
    if (!form.streamingFrameRateMin) {
      errorArray.push('streamingFrameRateMin');
    }
  }

  if (errorArray.length > 0) {
    toastWarning('Please enter all required input fields');
  }

  if (form.recordingFrameRate === 'dynamic') {
    if (Number(form.recordingFrameRateMin) > Number(form.recordingFrameRateMax)) {
      errorArray.push('recordingFrameRateMin');
      errorArray.push('recordingFrameRateMax');
      toastWarning('Minimum Frame rate cannot be greater than Maximum Frame rate(Recording)');
    }
  }
  if (form.recordingBitrate === 'dynamic') {
    if (Number(form.recordingBitrateMin) > Number(form.recordingBitrateMax)) {
      errorArray.push('recordingBitrateMin');
      errorArray.push('recordingBitrateMax');
      toastWarning('Minimum Bitrate cannot be greater than Maximum Bitrate(Recording)');
    }
  }

  if (showDynamicFrameRateStream) {
    if (Number(form.streamingFrameRateMin) > Number(form.streamingFrameRateMax)) {
      errorArray.push('streamingFrameRateMin');
      errorArray.push('streamingFrameRateMax');
      toastWarning('Minimum Frame rate cannot be greater than Maximum Frame rate(Streaming)');
    }
  }
  if (showDynamicBitrateStream) {
    if (Number(form.streamingBitrateMin) > Number(form.streamingBitrateMax)) {
      errorArray.push('streamingBitrateMin');
      errorArray.push('streamingBitrateMax');
      toastWarning('Minimum Bitrate cannot be greater than Maximum Bitrate(Streaming)');
    }
  }

  if (form.recordingBitrate === 'dynamic') {
    delete form.recordingBitrateValue;
  } else {
    delete form.recordingBitrateMax;
    delete form.recordingBitrateMin;
  }

  if (form.recordingFrameRate === 'dynamic') {
    delete form.recordingFrameRateValue;
  } else {
    delete form.recordingFrameRateMin;
    delete form.recordingFrameRateMax;
  }

  if (showDynamicBitrateStream) {
    delete form.streamingBitrateValue;
  } else {
    delete form.streamingBitrateMax;
    delete form.streamingBitrateMin;
  }

  if (showDynamicFrameRateStream) {
    delete form.streamingFrameRateValue;
  } else {
    delete form.streamingFrameRateMin;
    delete form.streamingFrameRateMax;
  }

  if (form.periodicCheckPolicy === 'INTERVAL' || form.periodicCheckPolicy === 'DISABLED') {
    delete form.periodicCheckTime;
  }

  if (form.installationRetryPolicy !== 'SCHEDULED') {
    delete form.maxRetryOnInstallationFailure;
  }

  if (form.installStartPolicy !== 'SCHEDULED') {
    delete form.installationStartSchedule;
  }

  if (form.installStartPolicy !== 'GEO_FENCE') {
    delete form.geoFenceInstallationStart;
  }

  if (form.downloadRetryPolicy !== 'SCHEDULED') {
    delete form.downloadRetrySchedule;
  }

  if (form.downloadStartPolicy !== 'SCHEDULED') {
    delete form.downloadStartSchedule;
  }

  if (form.downloadStartPolicy !== 'GEO_FENCE') {
    delete form.geoFenceDownloadStart;
  }

  //ON, OFF to boolean handle
  if (form.hasOwnProperty('usbFileTransfer')) {
    form['usbFileTransfer'] =
      form.usbFileTransfer === 'ON' || form.usbFileTransfer?.toString() === 'true' ? 'ON' : 'OFF';
  }

  // boolean to ON-OFF Handle
  if (form.hasOwnProperty('parkingMode')) {
    form['parkingMode'] =
      form.parkingMode === 'ON' || form.parkingMode?.toString() === 'true' ? 'ON' : 'OFF';
  }

  form.app_release_timeout = 0;
  form.ota_reboot_timeout = 0;

  if (form.hasOwnProperty('periodicCheckInterval')) {
    form['periodicCheckInterval'] = form.periodicCheckInterval * 60;
  }

  if (form.hasOwnProperty('reportingThreshold')) {
    form['reportingThreshold'] = form.reportingThreshold * 1024;
  }

  if (form.hasOwnProperty('lowBandwidthThreshold')) {
    form['lowBandwidthThreshold'] = form.lowBandwidthThreshold * 1024 * 1024;
  }

  if (form.hasOwnProperty('blackoutThreshold')) {
    form['blackoutThreshold'] = form.blackoutThreshold * 1024 * 1024;
  }

  if (form.hasOwnProperty('wifiNetworks')) {
    form['wifiNetworks'] = JSON?.stringify(form.wifiNetworks);
  } else {
    form['wifiNetworks'] = '[]';
  }

  /********************************************************************
   *                                                                   *
   * IMAGER BASED VIDEO AND AUDIO PIPELINE FOR RECORDING AND STREAMING *
   *                                                                   *
   *********************************************************************/
  const { videoPipeline, audioPipeline, imagerState } = imagerBasedPipelineSettingsValidateOnSubmit(
    form,
    product
  );

  form.videoPipeline = JSON.stringify(videoPipeline);
  form.audioPipeline = JSON.stringify(audioPipeline);
  form.imagerState = JSON.stringify(imagerState);

  cleanSettingsFromData(form);

  return { form, errorArray };
};

/**
 * Remove unused settings from data, it'll clean all the form data
 * @param {{[key: string]: any}} form
 */
const cleanSettingsFromData = (form) => {
  for (const property in form) {
    if (
      isUndefined(form[property]) ||
      isNaN(form[property]) ||
      isNull(form[property]) ||
      includes(property, '_recording_') ||
      includes(property, '_streaming_') ||
      includes(property, 'enableImagerState_')
    ) {
      delete form[property];
    }
  }
  delete form?.audioBitRate;
  delete form?.audioCodec;
  delete form?.enableAudioPipeline;
};

/**
 * Send Event to Device Group
 * @param {number} groupId
 * @returns
 */

/**
 * Resetting Privacy Group Settings
 * While new model deploy/undeploy, it'll update blurring group settings to default
 * @param {number} groupId
 */
export const resetPrivacyGroupSettings = (groupId) => {
  const state = store.getState();
  const secretToken = selectSecretToken(state);

  const postRequest = api.ac.endpoint.group.settings.$post({
    params: {
      secretToken,
      groupId,
    },
    data: {
      groupId,
      properties: [
        { key: 'isBlurEnabled', value: 'false' },
        { key: 'blurredLabels', value: '' },
      ],
    },
  });

  postRequest.process().then((res) => {
    sendEventsToGroup(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
    sendOtaGroupMessages(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
  });
};

/**
 * Handle Audio Pipeline data while submit
 * @param {ProductWithAccelerator['inputs']} inputAudioSources
 * @param {{[key: string]: any}} form
 * @returns []
 */

const formatAudioPipelineDataOnSubmit = (inputAudioSources, form) => {
  const audioPipeline = [];
  inputAudioSources?.forEach((item) => {
    const inputCode = item.inputCode;
    ['recording', 'streaming']?.forEach((type) => {
      audioPipeline.push({
        src: inputCode,
        pipeline: type,
        isEnable:
          form[`enableAudioPipeline_${inputCode}_${type}_`] ??
          DEFAULT_GROUP_SETTINGS.enableAudioPipeline,
        bitRate: form[`audioBitrate_${inputCode}_${type}_`] ?? DEFAULT_GROUP_SETTINGS.audioBitRate,
        codec: form[`audioCodec_${inputCode}_${type}_`] ?? DEFAULT_GROUP_SETTINGS.audioCodec,
      });
    });
  });

  return audioPipeline;
};

/**
 * Handle Video Pipeline data while submit
 * @param {ProductWithAccelerator['inputs']} inputVideoSources
 * @param {{[key: string]: any}} form
 * @returns []
 */

const formatVideoPipelineDataOnSubmit = (inputVideoSources, form) => {
  const videoPipeline = [];
  inputVideoSources?.forEach((item) => {
    const inputCode = item.inputCode;
    let isRecordingImagerEnable = form[`enableVideoPipeline_${inputCode}_recording_`] ?? true;
    let isStreamingImagerEnable = form[`enableVideoPipeline_${inputCode}_streaming_`] ?? true;

    videoPipeline.push({
      src: inputCode,
      pipeline: 'recording',
      isEnable: isRecordingImagerEnable,
      resolution:
        form[`resolution_${inputCode}_recording_`] ?? DEFAULT_GROUP_SETTINGS.recordingResolution,
      bitRate:
        form[`bitrateValue_${inputCode}_recording_`] ??
        DEFAULT_GROUP_SETTINGS.recordingBitrateValue,
      bitRateMin:
        form[`bitrateMin_${inputCode}_recording_`] ?? DEFAULT_GROUP_SETTINGS.recordingBitrateMin,
      bitRateMax:
        form[`bitrateMax_${inputCode}_recording_`] ?? DEFAULT_GROUP_SETTINGS.recordingBitrateMax,
      fps:
        form[`frameRateValue_${inputCode}_recording_`] ??
        DEFAULT_GROUP_SETTINGS.recordingFrameRateValue,
      fpsMin:
        form[`frameRateMin_${inputCode}_recording_`] ??
        DEFAULT_GROUP_SETTINGS.recordingFrameRateMin,
      fpsMax:
        form[`frameRateMax_${inputCode}_recording_`] ??
        DEFAULT_GROUP_SETTINGS.recordingFrameRateMax,
      codec:
        form[`videoCodec_${inputCode}_recording_`] ?? DEFAULT_GROUP_SETTINGS.recordingVideoCodec,
      overrideConfigFromAiModel:
        form[`shouldTakeSettingsFromModel_${inputCode}_recording_`] ??
        DEFAULT_GROUP_SETTINGS.shouldTakeRecordingSettingsFromModel,
      isAdaptableFrameRate: false,
      isAdaptableBitRate: false,
      enableSnapshot: form[`enableSnapshot${inputCode}_recording_`] ?? true,
      enableAiMetadata: form[`enableAiMetadata${inputCode}_recording_`] ?? true,
    });

    videoPipeline.push({
      src: inputCode,
      pipeline: 'streaming',
      isEnable: isStreamingImagerEnable,
      resolution:
        form[`resolution_${inputCode}_streaming_`] ?? DEFAULT_GROUP_SETTINGS.streamingResolution,
      bitRate:
        form[`bitrateValue_${inputCode}_streaming_`] ??
        DEFAULT_GROUP_SETTINGS.streamingBitrateValue,
      bitRateMin:
        form[`bitrateMin_${inputCode}_streaming_`] ?? DEFAULT_GROUP_SETTINGS.streamingBitrateMin,
      bitRateMax:
        form[`bitrateMax_${inputCode}_streaming_`] ?? DEFAULT_GROUP_SETTINGS.streamingBitrateMax,
      fps:
        form[`frameRateValue_${inputCode}_streaming_`] ??
        DEFAULT_GROUP_SETTINGS.streamingFrameRateValue,
      fpsMin:
        form[`frameRateMin_${inputCode}_streaming_`] ??
        DEFAULT_GROUP_SETTINGS.streamingFrameRateMin,
      fpsMax:
        form[`frameRateMax_${inputCode}_streaming_`] ??
        DEFAULT_GROUP_SETTINGS.streamingFrameRateMax,
      codec:
        form[`videoCodec_${inputCode}_streaming_`] ?? DEFAULT_GROUP_SETTINGS.streamingVideoCodec,
      overrideConfigFromAiModel:
        form[`shouldTakeSettingsFromModel_${inputCode}_streaming_`] ??
        DEFAULT_GROUP_SETTINGS.shouldTakeStreamingSettingsFromModel,
      isAdaptableFrameRate: form[`isAbtOnFramerateEnabled_${inputCode}_streaming_`] ?? false,
      isAdaptableBitRate: form[`isAbtOnBitrateEnabled_${inputCode}_streaming_`] ?? false,
      enableSnapshot: false,
      enableAiMetadata: form[`enableAiMetadata${inputCode}_streaming_`] ?? true,
    });
  });

  return videoPipeline;
};

/**
 * Handle Imager State data while submit
 * @param {ProductWithAccelerator['inputs']} inputVideoSources
 * @param {ProductWithAccelerator['inputs']} inputAudioSources
 * @param {{[key: string]: any}} form
 * @returns []
 */

const formatImagerStateDataOnSubmit = (inputVideoSources, inputAudioSources, form) => {
  const imagerState = [];
  inputVideoSources?.forEach((item) => {
    const inputCode = item.inputCode;
    imagerState.push({
      inputType: item.inputType,
      inputCode: inputCode,
      isEnable: form[`enableImagerState_${inputCode}`] ?? true,
    });
  });

  inputAudioSources?.forEach((item) => {
    const inputCode = item.inputCode;
    imagerState.push({
      inputType: item.inputType,
      inputCode: inputCode,
      isEnable: form[`enableImagerState_${inputCode}`] ?? true,
    });
  });

  return imagerState;
};

/**
 * Imager Based Pipeline settings
 * @param {{[key: string]: any}} form
 * @param {ProductWithAccelerator} product
 * @returns
 */

export const imagerBasedPipelineSettingsValidateOnSubmit = (form, product) => {
  const inputVideoSources = product?.inputs.filter((item) => item.inputType === 'VIDEO');
  const inputAudioSources = product?.inputs.filter((item) => item.inputType === 'AUDIO');

  const imagerState = formatImagerStateDataOnSubmit(inputVideoSources, inputAudioSources, form);
  const videoPipeline = formatVideoPipelineDataOnSubmit(inputVideoSources, form);
  const audioPipeline = formatAudioPipelineDataOnSubmit(inputAudioSources, form);

  const enabledCount = imagerState.filter((x) => x.inputType === 'VIDEO' && x.isEnable).length;
  if (enabledCount > 1) {
    for (const item of videoPipeline) {
      if (item.resolution === 'WH_1920x1080') {
        item.resolution = 'WH_1280x720';
      }
    }
  }

  return { videoPipeline, audioPipeline, imagerState };
};

/**
 * Imager Based Pipeline settings on group settings Initialization
 * @param {string} key
 * @param {string} value
 * @param {any} setValue
 * @param {ProductWithAccelerator} product
 * @returns
 */

export const imagerBasedPipelineSettingsInitialization = (key, value, product, setValue) => {
  if (!(key === 'videoPipeline' || key === 'audioPipeline' || key === 'imagerState')) return;

  const inputVideoSources = product?.inputs.filter((item) => item.inputType === 'VIDEO');
  const inputAudioSources = product?.inputs.filter((item) => item.inputType === 'AUDIO');
  const inputSources = inputAudioSources?.concat(inputVideoSources);

  deviceImagerStateSettingsInitialization(key, value, setValue, inputSources);
  imagerVideoPipelineInitialization(key, value, setValue, inputVideoSources);
  imagerAudioPipelineInitialization(key, value, setValue, inputAudioSources);
};

/**
 * Device Settings Imager Data Initializations
 * Tab: Device Settings
 * @param {string} key
 * @param {string} value
 * @param {any} setValue
 * @param {ProductWithAccelerator['inputs']} inputSources
 */
export const deviceImagerStateSettingsInitialization = (key, value, setValue, inputSources) => {
  if (key === 'imagerState') {
    const parsedImagerStates = value ? JSON?.parse(value) : [];

    parsedImagerStates?.forEach((item) =>
      setValue(`enableImagerState_${item.inputCode}`, item.isEnable)
    );

    inputSources?.forEach((video) => {
      const inputCode = video.inputCode;
      if (!parsedImagerStates?.some((item) => item.inputCode === inputCode)) {
        setValue(`enableImagerState_${inputCode}`, true);
      }
    });
  }
};

/**
 * Video Pipeline settings Initializations
 * Tab: Recording, Streaming
 * @param {string} key
 * @param {string} value
 * @param {any} setValue
 * @param {ProductWithAccelerator['inputs']} inputVideoSources
 */
export const imagerVideoPipelineInitialization = (key, value, setValue, inputVideoSources) => {
  if (key === 'videoPipeline') {
    const parsedVideoPipelines = value ? JSON?.parse(value) : [];

    parsedVideoPipelines?.forEach((item) => {
      const inputCode = item.src;
      const postFix = `_${inputCode}_${item.pipeline}_`;
      setValue(`enableVideoPipeline${postFix}`, item.isEnable);
      setValue(`resolution${postFix}`, item.resolution);
      setValue(`bitrateValue${postFix}`, item.bitRate);
      setValue(`bitrateMin${postFix}`, item.bitRateMin);
      setValue(`bitrateMax${postFix}`, item.bitRateMax);
      setValue(`frameRateValue${postFix}`, item.fps);
      setValue(`frameRateMin${postFix}`, item.fpsMin);
      setValue(`frameRateMax${postFix}`, item.fpsMax);
      setValue(`videoCodec${postFix}`, item.codec);
      setValue(`shouldTakeSettingsFromModel${postFix}`, item.overrideConfigFromAiModel);
      setValue(`enableSnapshot${postFix}`, item.enableSnapshot);
      setValue(`enableAiMetadata${postFix}`, item.enableAiMetadata);
      if (item.pipeline === 'streaming') {
        setValue(`isAbtOnFramerateEnabled_${inputCode}_streaming_`, item.isAdaptableFrameRate);
        setValue(`isAbtOnBitrateEnabled_${inputCode}_streaming_`, item.isAdaptableBitRate);
      }
    });

    inputVideoSources?.forEach((video) => {
      const inputCode = video.inputCode;
      const hasNoDiff = parsedVideoPipelines?.some((item) => item.src === inputCode);
      if (hasNoDiff) return;
      ['recording', 'streaming']?.forEach((type) => {
        const postFix = `_${inputCode}_${type}_`;

        const defaultResolution =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingResolution
            : DEFAULT_GROUP_SETTINGS.streamingResolution;

        const defaultBitRateValue =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingBitrateValue
            : DEFAULT_GROUP_SETTINGS.streamingBitrateValue;

        const defaultBitRateValueMin =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingBitrateMin
            : DEFAULT_GROUP_SETTINGS.streamingBitrateMin;

        const defaultBitRateValueMax =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingBitrateMax
            : DEFAULT_GROUP_SETTINGS.streamingBitrateMax;

        const defaultFrameRateValue =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingFrameRateValue
            : DEFAULT_GROUP_SETTINGS.streamingFrameRateValue;

        const defaultFrameRateValueMin =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingFrameRateMin
            : DEFAULT_GROUP_SETTINGS.streamingFrameRateMin;

        const defaultFrameRateValueMax =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingFrameRateMax
            : DEFAULT_GROUP_SETTINGS.streamingFrameRateMax;

        const defaultVideoCodec =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.recordingVideoCodec
            : DEFAULT_GROUP_SETTINGS.streamingVideoCodec;

        const defaultOverrideAiConfig =
          type === 'recording'
            ? DEFAULT_GROUP_SETTINGS.shouldTakeRecordingSettingsFromModel
            : DEFAULT_GROUP_SETTINGS.shouldTakeStreamingSettingsFromModel;

        setValue(`enableVideoPipeline${postFix}`, true);
        setValue(`resolution${postFix}`, defaultResolution);
        setValue(`bitrateValue${postFix}`, defaultBitRateValue);
        setValue(`bitrateMin${postFix}`, defaultBitRateValueMin);
        setValue(`bitrateMax${postFix}`, defaultBitRateValueMax);
        setValue(`frameRateValue${postFix}`, defaultFrameRateValue);
        setValue(`frameRateMin${postFix}`, defaultFrameRateValueMin);
        setValue(`frameRateMax${postFix}`, defaultFrameRateValueMax);
        setValue(`videoCodec${postFix}`, defaultVideoCodec);
        setValue(`shouldTakeSettingsFromModel${postFix}`, defaultOverrideAiConfig);

        setValue(`enableSnapshot${postFix}`, true);
        setValue(`enableAiMetadata${postFix}`, true);

        if (type === 'streaming') {
          setValue(`isAbtOnFramerateEnabled_${inputCode}_streaming_`, false);
          setValue(`isAbtOnBitrateEnabled_${inputCode}_streaming_`, false);
        }
      });
    });
  }
};

/**
 * Audio Pipeline settings Initializations
 * Tab: Recording, Streaming
 * @param {string} key
 * @param {string} value
 * @param {any} setValue
 * @param {ProductWithAccelerator['inputs']} inputAudioSources
 */
export const imagerAudioPipelineInitialization = (key, value, setValue, inputAudioSources) => {
  if (key === 'audioPipeline') {
    const parsedAudioPipelines = value ? JSON?.parse(value) : [];
    parsedAudioPipelines?.forEach((item) => {
      const inputCode = item.src;
      const postFix = `_${inputCode}_${item.pipeline}_`;
      setValue(`enableAudioPipeline${postFix}`, item.isEnable);
      setValue(`audioBitrate${postFix}`, item.bitRate);
      setValue(`audioCodec${postFix}`, item.codec);
    });

    inputAudioSources?.forEach((video) => {
      const inputCode = video.inputCode;
      if (!parsedAudioPipelines?.some((item) => item.src === inputCode)) {
        ['recording', 'streaming']?.forEach((type) => {
          const postFix = `_${inputCode}_${type}_`;
          setValue(`enableAudioPipeline${postFix}`, DEFAULT_GROUP_SETTINGS.enableAudioPipeline);
          setValue(`audioBitrate${postFix}`, DEFAULT_GROUP_SETTINGS.audioBitRate);
          setValue(`audioCodec${postFix}`, DEFAULT_GROUP_SETTINGS.audioCodec);
        });
      }
    });
  }
};
