import { selectTenantId } from '@/store/auth';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @template T
 * @typedef {object} VideoMinutesContextData
 * @property {string} selectedTenant
 * @property {StateAction<string>} setSelectedTenant
 */

/**
 * @template T
 * @type {import('react').Context<VideoMinutesContextData<any>>}
 */
export const VideoMinutesContext = createContext(null);

export const VideoMinutesProvider = ({ children }) => {
  const tenantId = useSelector(selectTenantId);
  const [selectedTenant, setSelectedTenant] = useState(tenantId);

  return (
    <VideoMinutesContext.Provider
      value={{
        setSelectedTenant,
        selectedTenant,
      }}
    >
      {children}
    </VideoMinutesContext.Provider>
  );
};
