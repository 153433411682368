import { Box } from '@mui/material';
import { VideoMinuteGraphBar } from '../VideoMinuteGraphBar';

export function VideoMinutesBarXAxis(props) {
  const { results, maxDuration } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      gap="5px"
      position="absolute"
      top={10}
      left={35}
      right={0}
      bottom={{ xs: '35px', md: '24px', xl: '16px' }}
      pb="0px"
      sx={{ overflowY: 'hidden' }}
    >
      {results.map((item, index) => (
        <VideoMinuteGraphBar index={index} item={item} maxDuration={maxDuration} />
      ))}
    </Box>
  );
}
