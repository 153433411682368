import { Box, Typography } from '@mui/material';
import { isInteger, orderBy } from 'lodash';
import { convertFloatMinutesToMinSec } from '../../@utils/date';
import { useMemo } from 'react';

export function VideoMinutesBarYAxis(props) {
  const { yAxisItems, yAxisCumulativeItems, maxDuration, cumulativeMaxDuration } = props;

  const formatValue = (val) => (isInteger(val) ? val : convertFloatMinutesToMinSec(val)) + 'm';

  const yAxisCalculatedItems = useMemo(
    () => (maxDuration === 0 ? [0, 10, 20, 30, 40] : yAxisItems),
    [yAxisItems, maxDuration]
  );

  const yAxisCumulativeCalculatedItems = useMemo(
    () => (cumulativeMaxDuration === 0 ? [0, 100, 200, 300, 400] : yAxisCumulativeItems),
    [yAxisCumulativeItems, cumulativeMaxDuration]
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="103%">
      <Box>
        {orderBy(yAxisCalculatedItems, [], ['desc'])?.map((value, index) => (
          <Box key={value + index} display="flex" gap="5px" height="50px">
            <Typography
              component="div"
              variant="subtitle2"
              textAlign="right"
              width="30px"
              lineHeight="20px"
              fontSize="0.65rem"
              children={formatValue(value)}
            />
          </Box>
        ))}
      </Box>

      <Box
        width="100%"
        ml={'10px'}
        mt={{ xs: '178px', xl: '182px' }}
        borderTop="1px dashed #DCE1EA"
        mb={'10px'}
        mr={'10px'}
      />

      <Box>
        {orderBy(yAxisCumulativeCalculatedItems, [], ['desc'])?.map((value, index) => (
          <Box key={value + index} display="flex" gap="5px" height="50px">
            <Typography
              component="div"
              variant="subtitle2"
              textAlign="left"
              width="30px"
              lineHeight="20px"
              fontSize="0.65rem"
              children={formatValue(value)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
