import { selectUserAccessibleFeatures } from '@/store/auth';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CoachingEndpointFilteringArea } from './@components/CoachingEndpointFilteringArea';
import { CoachingLockLandingPage } from './@components/CoachingLockLandingPage';
import { CoachingFilterContextProvider } from './@context/CoachingFilterContext';

const locations = [
  {
    url: '/safety/coaching',
    label: 'Coach Camera',
    breadcrumb: 'Coaching',
  },
  {
    url: '/safety/coaching/history',
    label: 'Coaching History',
    breadcrumb: 'Coaching History',
  },
  {
    url: '/safety/coaching/pending',
    label: 'Coaching Pending',
    breadcrumb: 'Coaching Pending',
  },
];

export function EndpointCoachingPage() {
  const navigate = useNavigate();
  const { setBreadcrumbTitle } = useContext(MainContext) || {};

  const enableFeatures = useSelector(selectUserAccessibleFeatures);

  const location = useLocation();
  const params = useParams();

  /** @type {StateVariable<string>} */
  const [selectedParentUrl, setSelectedParentUrl] = useState(location?.pathname);

  const hasCoachingPermission = useMemo(
    () => enableFeatures?.includes('COACHING'),
    [enableFeatures]
  );

  const handleChange = (event, newValue) => {
    setSelectedParentUrl(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const item = locations?.find((i) => i?.url === selectedParentUrl);
    setBreadcrumbTitle(item?.breadcrumb, (path) => path.endsWith(item?.url));
  }, [setBreadcrumbTitle, selectedParentUrl]);

  useEffect(() => {
    setSelectedParentUrl(location?.pathname);
  }, [location?.pathname]);

  if (params?.sessionId) return <Outlet />;

  if (!hasCoachingPermission) return <CoachingLockLandingPage />;

  return (
    <Box mx={2.5} pb={5}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{
          '& .MuiButtonBase-root': {
            textTransform: 'none',
          },
        }}
      >
        <Tabs
          value={selectedParentUrl}
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          sx={{
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
              color: '#4177BF !important',
            },
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-disabled': {
              color: '#A6AFBA !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#4177BF !important',
            },
            '.MuiTabs-scroller': {
              height: '40px',
            },
          }}
        >
          {locations?.map((item, index) => (
            <Tab key={index} value={item?.url} label={item?.label} />
          ))}
        </Tabs>
      </Box>

      <FilterContextProvider>
        <CoachingFilterContextProvider>
          <Box mb={2}>
            <CoachingEndpointFilteringArea />
          </Box>
          <Outlet />
        </CoachingFilterContextProvider>
      </FilterContextProvider>
    </Box>
  );
}
