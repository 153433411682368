import { formatDateForTriggerReport } from '@/utils/datetime';
import LightTooltip from '@/web/@components/LightTooltip';
import { normalizeTo100 } from '@/web/safety/scoring/utils';
import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { VIDEO_MINUTES_TYPE_COLOR_MAPPING } from '../../assets';
import { VideoMinuteGraphTooltip } from '../VideoMinuteGraphTooltip';

export function VideoMinuteGraphBar(props) {
  const { item, index, maxDuration } = props;

  const { time, value } = item;

  const [hovered, setHovered] = useState(false);

  const segmentedData = useMemo(() => {
    const totalDuration = value;
    const segmentedData = [];
    let cumulativePercentage = 0;
    const scalingFactor = totalDuration / maxDuration;
    item?.videoMinuteUsages.forEach((item) => {
      const percentage = (item.durationMinutes / totalDuration) * 100 * scalingFactor;
      segmentedData.push({
        top: cumulativePercentage,
        bottom: cumulativePercentage + percentage,
        color: VIDEO_MINUTES_TYPE_COLOR_MAPPING[item?.type],
      });
      cumulativePercentage += percentage;
    });
    return segmentedData;
  }, [item, value, maxDuration]);

  return (
    <Box
      key={time + index}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap="5px"
    >
      <LightTooltip
        arrow
        followCursor
        placement="right"
        title={<VideoMinuteGraphTooltip item={item} time={time} value={value} />}
      >
        <Box
          width={{ xs: '8px', md: '17px', xl: '18px' }}
          height="100%"
          position="relative"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box
            position="absolute"
            left={0}
            right={0}
            top={`${100 - normalizeTo100(value, 0, maxDuration)}%`}
            bottom={0}
            sx={{
              ...(item?.predicted
                ? {
                    backgroundImage:
                      'linear-gradient(-45deg, #E4ECF4 25%, transparent 25%, transparent 50%, #E4ECF4 50%, #E4ECF4 75%, transparent 75%, transparent)',
                    backgroundSize: '8px 8px',
                  }
                : { bgcolor: '#E4ECF4' }),
            }}
          />

          {hovered &&
            segmentedData?.map((data, index) => {
              const topPercentage = data?.top;
              const bottomPercentage = data?.bottom;

              return (
                <Box
                  key={index}
                  bgcolor={data?.color || 'green'}
                  position="absolute"
                  left={0}
                  zIndex={101}
                  right={0}
                  bottom={`${topPercentage}%`}
                  height={`${bottomPercentage - topPercentage}%`}
                />
              );
            })}
        </Box>
      </LightTooltip>

      <Typography
        height={{ xs: '1px', md: '12px', xl: '20px' }}
        component="div"
        variant="subtitle2"
        textAlign="center"
        lineHeight={{ xs: '8px', md: '8px', xl: '18px' }}
        fontSize="0.65rem"
      >
        {index % 2 === 0 && formatDateForTriggerReport(time)}
      </Typography>
    </Box>
  );
}
