import { formatDateWithWeek } from '@/utils/datetime';
import { convertFloatMinutesToMinSec } from '@/web/smarter-ai/video-minutes/@utils/date';
import { Box, Divider, Typography } from '@mui/material';
import { VIDEO_MINUTES_TYPE_COLOR_MAPPING, VIDEO_MINUTES_TYPE_MAPPING } from '../../assets';
import { sortBy } from 'lodash';
import { CHART_MTD_LEGEND } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';

export function VideoMinuteGraphTooltip(props) {
  const { item, time, value } = props;
  return (
    <Box minWidth="265px" textAlign="left" key={time}>
      <Box display="grid" gap={0}>
        <Typography variant="caption" fontSize="0.80rem" fontWeight={500} sx={{ opacity: 0.68 }}>
          {formatDateWithWeek(time)}
        </Typography>
      </Box>

      <Box pb={1}>
        {sortBy(item?.videoMinuteUsages, 'durationMinutes')?.map((minutes) => (
          <Box
            pt={0.8}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent={'center'}
          >
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems="center"
              alignContent={'center'}
              gap={1.1}
            >
              <Box
                sx={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: VIDEO_MINUTES_TYPE_COLOR_MAPPING[minutes?.type],
                }}
              ></Box>
              <Typography variant="caption" fontWeight={400}>
                {VIDEO_MINUTES_TYPE_MAPPING[minutes?.type] || minutes?.type}
              </Typography>
            </Box>

            <Typography variant="body2" fontSize="0.75rem" fontWeight={500}>
              {convertFloatMinutesToMinSec(minutes?.durationMinutes || 0)} min
            </Typography>
          </Box>
        ))}
      </Box>

      <Divider />

      <Box display="flex" flexDirection="column">
        <Box
          pt={0.8}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent={'center'}
        >
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            alignContent={'center'}
            gap={1.1}
          >
            {item?.predicted ? (
              <Box
                sx={{
                  width: '15px',
                  height: '13px',
                  overflow: 'hidden',
                  paddingTop: '-15px',
                }}
              >
                <Typography
                  sx={{
                    color: '#BFCBDC !important',
                    fontWeight: 600,
                    transform: 'rotate(45deg)',
                    fontSize: '1.01rem !important',
                    width: '20px',
                  }}
                >
                  ||||
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: '12px', width: '12px', backgroundColor: '#BFCBDC' }}></Box>
            )}
            <Typography variant="caption" fontWeight={400}>
              {item?.predicted ? 'Estimated' : 'Daily'}
            </Typography>
          </Box>

          <Typography variant="body2" fontSize="0.75rem" fontWeight={500}>
            {convertFloatMinutesToMinSec(value || 0)} min
          </Typography>
        </Box>

        <Box
          pt={0.8}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent={'center'}
        >
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            alignContent={'center'}
            gap={0.5}
          >
            {item?.predicted ? (
              <BoxImage src={CHART_MTD_LEGEND} width={'20px'} />
            ) : (
              <Box sx={{ height: '6px', width: '18px', backgroundColor: '#257FE9' }}></Box>
            )}
            <Typography variant="caption" fontWeight={400}>
              {item?.predicted ? 'Estimated Month to Date' : 'Month to Date'}
            </Typography>
          </Box>

          <Typography variant="body2" fontSize="0.75rem" fontWeight={500}>
            {convertFloatMinutesToMinSec(item?.cumulativeValue || 0)} min
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
