import { VIN_API_KEY } from '@/config';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';

const currentYear = new Date().getFullYear();

/** @param {import('@mui/material').CardProps} props */
export function CameraVinArea(props) {
  const { camera } = useContext(CameraDetailsContext);

  const [expanded, setExpanded] = useState(true);

  // https://www.auto.dev/docs
  const { result, loading, error } = useFutureLoader(
    async ({ signal }) => {
      if (!camera?.vin) return null;
      /** @type {VINResponse} */
      const result = await fetch(`https://auto.dev/api/vin/${camera.vin}?apikey=${VIN_API_KEY}`, {
        signal,
        cache: 'force-cache',
      }).then((resp) => resp.json());
      return result;
    },
    [camera?.vin]
  );

  const vehicleYearInfo = useMemo(() => (result?.years || [])[0] || {}, [result?.years]);

  if (loading || error || !result || result?.status === 'NOT_FOUND' || !result.model?.name) {
    return null;
  }

  return (
    <Card variant="outlined" {...props} sx={{ borderRadius: '5px', ...props.sx }}>
      <Button
        fullWidth
        variant="text"
        onClick={() => setExpanded(!expanded)}
        sx={{ py: 1, px: 2, display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography color="#011e42" variant="body1" fontWeight="600">
          Vehicle Information
        </Typography>
        <ChevronRight
          sx={{
            transform: `rotate(${expanded ? 90 : 0}deg)`,
            transition: '0.3s transform',
          }}
        />
      </Button>
      {expanded && (
        <>
          <Divider />
          <Box
            p={2}
            gap={2}
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            {/* <Box>image</Box> */}
            <Grid container spacing={2} textAlign={{ xs: 'center', md: 'left' }} flex={1}>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">VIN</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {camera.vin || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Make</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {result.make?.name || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Model</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {result.model?.name || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Year</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {vehicleYearInfo?.year || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Age</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {vehicleYearInfo?.year
                    ? currentYear > vehicleYearInfo.year
                      ? `${currentYear - vehicleYearInfo.year} years`
                      : 'Less than a year'
                    : 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Style/Body</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {result.categories?.vehicleStyle || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Vehicle Type</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {result.categories?.vehicleType || 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="subtitle2">Body Class</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {(vehicleYearInfo?.styles || [])[0]?.submodel?.modelName ||
                    result.categories?.primaryBodyType ||
                    'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2">Fuel Type</Typography>
                <Typography variant="subtitle1" fontWeight={500} textTransform="capitalize">
                  {result.engine?.type || 'Not Available'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Card>
  );
}
