import { formatDateForTriggerReport, formatDateWithWeek } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { generateEvenlySpacedArray, getEpochTime, normalizeTo100 } from '../../utils';
import LightTooltip from '@/web/@components/LightTooltip';
import { orderBy } from 'lodash';

export function TriggerReportDetailsGraph(props) {
  const { item } = props;
  const results = useMemo(() => {
    return item?.countPerDates.map((item) => {
      return {
        time: getEpochTime(item?.displayDate),
        value: item?.score,
      };
    });
  }, [item?.countPerDates]);

  const yAxisItems = useMemo(() => {
    return generateEvenlySpacedArray(item?.minCount, item?.maxCount, 5);
  }, [item?.minCount, item?.maxCount]);

  if (item?.countPerDates?.length === 0) {
    return (
      <CenterBox style={{ height: '250px' }}>
        <IconMessageBox
          size="150px"
          src="/images/empty/no-search-results.svg"
          message="No Data Found!"
        />
      </CenterBox>
    );
  }

  return (
    <Box pt={2}>
      <Box
        gap={1}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'space-between' }}
      ></Box>

      <Box mt={1.5} pb="20px" position="relative">
        {/* Draw Y axis */}
        {orderBy(yAxisItems, [], ['desc'])?.map((value) => (
          <Box key={value} display="flex" gap="5px" height="40px">
            <Typography
              component="div"
              variant="subtitle2"
              textAlign="right"
              width="30px"
              lineHeight="20px"
              fontSize="0.65rem"
              children={value}
            />
            {value === 0 && <Box width="100%" mt="10px" borderTop="1px dashed #DCE1EA" />}
          </Box>
        ))}
        {/* Draw X axis and Bars */}
        <Box
          display="flex"
          justifyContent="space-around"
          gap="5px"
          position="absolute"
          top={10}
          left={35}
          right={0}
          bottom={{ xs: '35px', md: '24px', xl: '16px' }}
          pb="10px"
        >
          {results.map(({ time, value }, index) => (
            <Box
              key={time}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              gap="5px"
            >
              <LightTooltip
                arrow
                followCursor
                placement="right"
                title={
                  <Box minWidth="150px" textAlign="left">
                    <Box display="grid" gap={0}>
                      <Typography
                        variant="body1"
                        lineHeight="12px"
                        fontSize="0.875rem"
                        pt={1}
                        fontWeight={500}
                      >
                        {item?.triggerCategoryName}
                      </Typography>
                      <Typography variant="caption" fontSize="0.68rem">
                        {formatDateWithWeek(time)}
                      </Typography>
                    </Box>

                    <Box pt={0.8}>
                      <Typography variant="caption" fontWeight={400}>
                        Number of Events
                      </Typography>
                      <Typography variant="body2" fontSize="0.75rem" fontWeight={500}>
                        {value || 0}
                      </Typography>
                    </Box>
                  </Box>
                }
              >
                <Box
                  //  bgcolor="#E4ECF4"
                  borderRadius="8px"
                  width="10px"
                  height="100%"
                  position="relative"
                >
                  {Boolean(value) && (
                    <Box
                      bgcolor="#2680EB"
                      borderRadius="8px"
                      position="absolute"
                      left={0}
                      right={0}
                      top={`${100 - normalizeTo100(value, 0, item?.maxCount)}%`}
                      bottom={0}
                    />
                  )}
                </Box>
              </LightTooltip>

              <Typography
                height={{ xs: '1px', md: '12px', xl: '20px' }}
                component="div"
                variant="subtitle2"
                textAlign="center"
                lineHeight={{ xs: '8px', md: '8px', xl: '18px' }}
                fontSize="0.65rem"
              >
                {index % 2 === 0 && formatDateForTriggerReport(time)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
