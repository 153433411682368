import { CHART_LIMIT_LEGEND, CHART_MTD_LEGEND } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Typography } from '@mui/material';

export function VideoMinutesChartLegends() {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignContent="center"
      gap={3}
      justifyContent="center"
      sx={{
        '& .MuiTypography-root': {
          color: '#0B2547',
          opacity: '0.68',
          fontSize: '0.75rem',
        },
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Box sx={{ height: '12px', width: '12px', backgroundColor: '#BFCBDC' }}></Box>
        <Typography>Daily</Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Box sx={{ height: '6px', width: '22px', backgroundColor: '#257FE9' }}></Box>
        <Typography>Month to Date</Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <BoxImage src={CHART_MTD_LEGEND} />
        <Typography>Estimated Month to Date</Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <BoxImage src={CHART_LIMIT_LEGEND} mt={'3px'} />
        <Typography>Limit</Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Box
          sx={{
            width: '16px',
            height: '14px',
            overflow: 'hidden',
            paddingTop: '-12px',
          }}
        >
          <Typography
            sx={{
              color: '#BFCBDC !important',
              fontWeight: 600,
              transform: 'rotate(45deg)',
              fontSize: '1.01rem !important',
            }}
          >
            ||||
          </Typography>
        </Box>
        <Typography>Estimated</Typography>
      </Box>
    </Box>
  );
}
