import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import {
  selectIsLoadingDescendants,
  selectSecretToken,
  selectSupportPath,
  selectTenantId,
  selectTenantTree,
} from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getMonth } from 'date-fns';
import { debounce, sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoMinutesFilterContext } from '../../../@context/VideoMinutesFilterContext';
import { findNestedTenantDataByIds, groupItemsByType } from '../../../@utils/overview';
import { CLIENT_WISE_MINUTE_PAYLOAD } from '../../../assets';
import { VideoMinutesTableHeader } from '../../VideoMinutesTableHeader';
import { TenantTreeItem } from '../TenantTreeItem';
import { store } from '@/store';

/**
 * @typedef {object} DescendantVideoMinutesItems
 * @property {Array<DescendantVideoMinutesItems>} [children]
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [type]
 * @property {string} [parentTenantId]
 */

/**
 * @typedef {object} VideoMinutesTenantTableProps
 * @property {() => any} [onSwitch]
 */

export function VideoMinutesTenantTable() {
  const supportPath = useSelector(selectSupportPath);
  const isLoadingDescendants = useSelector(selectIsLoadingDescendants);

  const { searchedEndpoint, searchedTenant, monthInt, year, client, setEndpoints, setTenants } =
    useContext(VideoMinutesFilterContext);

  const theme = useTheme();
  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const tenantTree = useSelector(selectTenantTree);

  const isShadow = useMemo(() => client === 'vision_team', [client]);
  const selectedMonth = useMemo(() => monthInt || getMonth(new Date()) + 1, [monthInt]);
  const selectedYear = useMemo(() => year || new Date().getFullYear(), [year]);
  const selectedClient = useMemo(
    () => CLIENT_WISE_MINUTE_PAYLOAD[client] || CLIENT_WISE_MINUTE_PAYLOAD['smarter_ai'],
    [client]
  );

  /** @type {StateVariable<array>} */
  const [itemList, setItemList] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [tenantsLoading, setTenantsLoading] = useState(true);
  /** @type {StateVariable<number>} */
  const [tenantExpandLoadingId, setTenantExpandLoadingId] = useState(null);
  /** @type {StateVariable<Array<TenantVideoMinuteUsages>>} */
  const [tenantsMinutes, setTenantsMinutes] = useState([]);
  const [selectedTree, setSelectedTree] = useState([]);

  /** @type {DescendantVideoMinutesItems[]} */
  const filteredTenantTree = useMemo(() => {
    const applyFilter = (tree, depth = 0) => {
      if (!tree?.length) return undefined;

      // Return all items if both searchedTenant and searchedEndpoint are empty
      if (!searchedTenant && !searchedEndpoint) {
        return tree; // Return the original tree without filtering
      }

      const filteredTree = [];
      for (const entity of tree) {
        if (!entity) continue;

        // Recursively apply filter to children
        const filteredChildren = applyFilter(entity.children, depth + 1);

        let matchesFilter = false;

        // Case 1: Only searchedTenant is available, search for TYPE 'TENANT'
        if (searchedTenant && !searchedEndpoint) {
          // Match tenant based on searchedTenant
          matchesFilter =
            entity.type?.includes('TENANT') &&
            entity.name?.toLowerCase()?.includes(searchedTenant.toLowerCase());

          // If it matches, include the tenant and all its children
          if (matchesFilter) {
            filteredTree.push({
              ...entity,
              children: entity.children || [], // Include all children
            });
            continue; // Skip to the next tenant since we already added it
          }
        }

        // Case 2: Only searchedEndpoint is available, search for TYPE 'ENDPOINT'
        else if (!searchedTenant && searchedEndpoint) {
          matchesFilter =
            entity.type === 'ENDPOINT' &&
            entity.name?.toLowerCase()?.includes(searchedEndpoint.toLowerCase());
        }

        // Case 3: Both searchedTenant and searchedEndpoint are available
        else if (searchedTenant && searchedEndpoint) {
          // Check if this entity matches searchedTenant
          const isTenantMatch =
            entity.type?.includes('TENANT') &&
            entity.name?.toLowerCase()?.includes(searchedTenant.toLowerCase());

          // If the tenant matches, include it and filter its children for ENDPOINT
          if (isTenantMatch) {
            const matchingChildren =
              entity.children?.filter(
                (child) =>
                  child.type === 'ENDPOINT' &&
                  child.name?.toLowerCase()?.includes(searchedEndpoint.toLowerCase())
              ) || [];

            // Add the matched tenant along with its matching children
            filteredTree.push({
              ...entity,
              children: matchingChildren.length > 0 ? matchingChildren : entity.children, // Include matching children or all if none match
            });
          }
        }

        // Add the tenant to the filtered tree if it matches the filter condition
        if (matchesFilter || filteredChildren?.length > 0) {
          filteredTree.push({
            ...entity,
            children: filteredChildren, // Always include the filtered children
          });
        }
      }

      // Sort the filtered tree by data type
      filteredTree.sort((a, b) => {
        const order = { TENANT: 1, GROUP: 2, ENDPOINT: 3 };
        return order[a.type] - order[b.type];
      });

      return filteredTree;
    };

    const filteredItems = applyFilter(itemList);
    return filteredItems;
  }, [itemList, searchedTenant, searchedEndpoint]);

  useEffect(() => {
    const data = groupItemsByType(itemList);
    if (data?.endpoint?.length) {
      setEndpoints(data?.endpoint);
    }
    if (data?.tenants?.length) {
      setTenants(data?.tenants);
    }
  }, [itemList, setEndpoints, setTenants]);

  // eslint-disable-next-line no-unused-vars
  const updateChildrenById = (items, targetId, updatedArray) => {
    return items.map((item) => {
      if (item.id === targetId) {
        return { ...item, children: updatedArray };
      } else if (item.children && item.children.length > 0) {
        item.children = updateChildrenById(item.children, targetId, updatedArray);
      }
      return item;
    });
  };

  const handleTenantChange = async (tree) => {
    const state = store.getState();
    setSelectedTree(tree);
    const selectedTenantId = tree?.at(tree?.length - 1);
    setTenantExpandLoadingId(selectedTenantId);
    const selectedTree = tree;

    const tenantTreePath = [...state?.auth.supportPath, ...selectedTree];
    const tenantData = findNestedTenantDataByIds(tenantTree, tenantTreePath);
    const subTenantData = async () => {
      const mappedData = tenantData?.descendantTenantList?.map((item) => {
        const subTenants = findNestedTenantDataByIds(tenantTree, [
          ...tenantTreePath,
          ...item?.tenantId,
        ]);

        return {
          name: item?.tenantName,
          id: item?.tenantId,
          type: subTenants?.descendantTenantList?.length > 0 ? 'TENANT_PARENT' : 'TENANT',
          parentTenantId: selectedTenantId,
        };
      });

      const sortedData = sortBy(mappedData, [(item) => item.name.toLowerCase()]);
      return sortedData;
    };

    const endpoints = await tenantEndpointData(selectedTenantId);
    const tenants = await subTenantData();
    // const data = [...tenants, ...endpoints];

    if (tenants?.length > 0) {
      const xData = [
        {
          name: tenantData?.tenantName,
          id: selectedTenantId,
          type: 'TENANT',
          children: endpoints,
        },
        ...tenants,
      ];
      setItemList((prev) => updateChildrenById(prev, selectedTenantId, xData));
    } else {
      setItemList((prev) => updateChildrenById(prev, selectedTenantId, endpoints));
    }
    setTenantExpandLoadingId(null);
  };

  /**
   * Fetch Tenant Data
   */
  const getParentTenantDetails = useCallback(async () => {
    try {
      const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result;
    } catch (ex) {
      return {};
    }
  }, [secretToken, tenantId]);

  const getParentTenantAndChildVideoMinutes = useCallback(
    async (tenantId) => {
      try {
        const request = api.ac.v5['video-minutes'].tenants.$tenantId(tenantId).$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: selectedYear,
            monthOfTheYear: selectedMonth + '',
            ...selectedClient,
          },
        });
        await request.process();
        setTenantsMinutes((prev) => [...prev, ...request.result]);
        return request.result;
      } catch (ex) {
        return [];
      }
    },
    [secretToken, selectedMonth, selectedClient, selectedYear]
  );

  const getTenantEndpointsVideoMinutes = useCallback(
    async (tenantId) => {
      try {
        const request = api.ac.v5['video-minutes'].tenants.$tenantId(tenantId).endpoints.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: selectedYear,
            monthOfTheYear: selectedMonth,
            ...selectedClient,
          },
        });
        await request.process();
        setTenantsMinutes((prev) => [...prev, ...request.result]);
        return request.result;
      } catch (ex) {
        return [];
      }
    },
    [secretToken, selectedMonth, selectedClient, selectedYear]
  );

  const tenantEndpointData = useCallback(
    async (selectedTenantId) => {
      try {
        const endpointList = await getTenantEndpointsVideoMinutes(selectedTenantId);
        const mappedEndpointsData = endpointList?.map((item) => ({
          name: item?.endpointName || `Smarter AI Dashcam`,
          id: item?.endpointId,
          parentTenantId: selectedTenantId,
          type: 'ENDPOINT',
        }));
        return sortBy(mappedEndpointsData, 'name');
      } catch (ex) {
        return [];
      }
    },
    [getTenantEndpointsVideoMinutes]
  );

  const fetchSubTenantList = useCallback(
    async (tenantId) => {
      //get the current tenant data
      const state = store.getState();
      const currentTenant = await getParentTenantDetails();
      const currentTenantEndpoints = await tenantEndpointData(tenantId);
      const tenantTreePath = [...state?.auth?.supportPath, tenantId];

      //get current tenant subtenants data
      const childTenants = findNestedTenantDataByIds(tenantTree, tenantTreePath);

      const mappedData = childTenants?.descendantTenantList?.map((item) => {
        const subTenants = findNestedTenantDataByIds(tenantTree, [
          ...tenantTreePath,
          item?.tenantId,
        ]);
        return {
          name: item?.tenantName,
          id: item?.tenantId,
          type: subTenants?.descendantTenantList?.length > 0 ? 'TENANT_PARENT' : 'TENANT',
          parentTenantId: currentTenant?.tenantId,
        };
      });
      const sortedData = sortBy(mappedData, [(item) => item.name.toLowerCase()]);

      //merge sub tenants and group data
      const mergedData = [...sortedData, ...currentTenantEndpoints];

      //creating the final array
      const tenantData =
        childTenants?.descendantTenantList?.length > 0
          ? [
              {
                name: currentTenant?.tenantName,
                id: currentTenant?.tenantId,
                type: 'TENANT_ROOT',
                children: [
                  {
                    name: currentTenant?.tenantName,
                    id: currentTenant?.tenantId,
                    type: 'TENANT_PARENT_X',
                    children: currentTenantEndpoints,
                  },
                  ...sortedData,
                ],
              },
            ]
          : [
              {
                name: currentTenant?.tenantName,
                id: currentTenant?.tenantId,
                type: 'TENANT_PARENT',
                children: mergedData,
              },
            ];
      setItemList(tenantData);
      setTenantsLoading(false);
    },
    [getParentTenantDetails, tenantEndpointData, tenantTree]
  );

  // Memoizing debounced functions to avoid re-creation on every render
  const debouncedFetchSubTenantList = useMemo(
    () =>
      debounce((tenantId) => {
        setItemList([]);
        fetchSubTenantList(tenantId);
      }, 1000),
    [fetchSubTenantList] // Dependency array for fetchSubTenantList
  );

  const debouncedGetParentTenantAndChildVideoMinutes = useMemo(
    () =>
      debounce((tenantId) => {
        setTenantsMinutes([]);
        getParentTenantAndChildVideoMinutes(tenantId);
      }, 1000),
    [getParentTenantAndChildVideoMinutes] // Dependency array for getParentTenantAndChildVideoMinutes
  );

  useEffect(() => {
    setTenantsLoading(true);
    const aborter = new AbortController();
    setItemList([]);
    setTenantsMinutes([]);
    if (tenantId) {
      debouncedFetchSubTenantList(tenantId);
      debouncedGetParentTenantAndChildVideoMinutes(tenantId);
    }

    return () => {
      aborter.abort();
      setItemList([]);
      setTenantsMinutes([]);
      debouncedFetchSubTenantList.cancel();
      debouncedGetParentTenantAndChildVideoMinutes.cancel();
    };
  }, [tenantId, debouncedFetchSubTenantList, debouncedGetParentTenantAndChildVideoMinutes]);

  useEffect(() => {
    setItemList([]);
    setTenantsMinutes([]);
    setSelectedTree([]);
  }, [selectedMonth, client, selectedYear, tenantId]);

  if (tenantsLoading || isLoadingDescendants) {
    return (
      <CenterBox sx={{ height: '80vh !important' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!filteredTenantTree?.length) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          gap={0}
          size="256px"
          message="No search results"
        />
      </CenterBox>
    );
  }

  return (
    <Box sx={{ maxHeight: '86vh', overflowY: 'auto', pb: xlAndUp ? 0 : 6 }}>
      <TableContainer component={Paper} sx={{ maxHeight: '78vh', pb: 2 }} elevation={0}>
        <Table stickyHeader sx={{ width: '100%' }} size="small">
          <VideoMinutesTableHeader isShadow={isShadow} showFirstLabel={false} />
          <TableBody>
            {filteredTenantTree?.map((entity) => (
              <TenantTreeItem
                key={entity.id}
                entity={entity}
                selected={supportPath || []}
                tenantsMinutes={tenantsMinutes}
                onSelect={handleTenantChange}
                tenantExpandLoadingId={tenantExpandLoadingId}
                isShadow={isShadow}
                selectedTree={selectedTree}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
