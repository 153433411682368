import { store } from '@/store';
import { isTenantLoggedIn, selectMainTokenExpiry, selectTenantId } from '@/store/auth';
import { refreshToken } from '@/store/auth/actions/refresh-token';
import { selectTabVisible } from '@/store/page-view';
import { buildAILabelMapping, fetchAILabels } from '@/utils/ai-labels';
import { buildTriggerMapping, fetchTriggerCategories } from '@/utils/triggers/categories';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const INTERVAL_TIME = 30 * 1000;

export function AuthListener() {
  const tenantId = useSelector(selectTenantId);
  const loggedIn = useSelector(isTenantLoggedIn);
  const tabVisible = useSelector(selectTabVisible);
  const accessTokenExpiry = useSelector(selectMainTokenExpiry);

  useEffect(() => {
    if (!loggedIn || !tabVisible) return;
    const dispatcher = () => {
      store.dispatch(refreshToken()).catch(console.error);
    };
    const timeout = accessTokenExpiry - Date.now() - INTERVAL_TIME;

    if (timeout <= INTERVAL_TIME) {
      dispatcher();
    } else {
      const tid = setTimeout(dispatcher, timeout);
      return () => clearTimeout(tid);
    }
  }, [loggedIn, tabVisible, accessTokenExpiry]);

  useEffect(() => {
    if (!tenantId || !loggedIn) return;
    const aborter = new AbortController();
    fetchAILabels(aborter.signal).then(buildAILabelMapping).catch(console.error);
    fetchTriggerCategories(aborter.signal).then(buildTriggerMapping).catch(console.error);
    return () => aborter.abort();
  }, [tenantId, loggedIn]);

  return null;
}
