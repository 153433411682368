import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { formatSecondsToMinutesAndSeconds } from '../../../@utils/date';
import { Box, TableCell, TableRow } from '@mui/material';
import { format12HourTime, licenseTimestampFormat } from '@/utils/datetime';
import { Fragment } from 'react';

export function EndpointTriggerTreeItem(props) {
  const { indent = 0, endpointTransaction, isShadow } = props;

  const groupedData = groupBy(
    endpointTransaction,
    (item) => `${format(item.createdAt, 'yyyyMMddHHmmss')}-${item.usagesType}`
  );

  const resultArray = [];
  const dates = [];

  // Process the data and calculate durations
  Object.values(groupedData).forEach((arr) => {
    let liveDuration = 0;
    let eventPlayback = 0;
    let eventUpload = 0;
    let recordingPlayback = 0;
    let recordingUpload = 0;
    let shadowUpload = 0;
    let shadowPlayback = 0;
    let createdAt = null;

    arr.forEach((item) => {
      createdAt = item.createdAt; // Update createdAt for each item processed
      if (item.usagesType.includes('LIVE')) {
        liveDuration += item.durationSeconds;
      } else if (item.usagesType.includes('EVENT_PLAYBACK')) {
        eventPlayback += item.durationSeconds;
      } else if (item.usagesType.includes('EVENT_UPLOAD')) {
        eventUpload += item.durationSeconds;
      } else if (item.usagesType.includes('RECORDING_PLAYBACK')) {
        recordingPlayback += item.durationSeconds;
      } else if (item.usagesType.includes('RECORDING_UPLOAD')) {
        recordingUpload += item.durationSeconds;
      } else if (item.usagesType.includes('SHADOW_EVENT_UPLOAD')) {
        shadowUpload += item.durationSeconds;
      } else if (item.usagesType.includes('SHADOW_EVENT_PLAYBACK')) {
        shadowPlayback += item.durationSeconds;
      }
    });

    const createdAtDate = new Date(createdAt);
    const formattedDate = format(createdAtDate, 'yyyyMMdd');
    const isContains = dates.some((date) => format(date, 'yyyyMMdd') === formattedDate);
    if (!isContains) {
      dates.push(createdAtDate);
    }

    // Create the result object for each loop iteration
    const formatDuration = (duration) =>
      duration ? formatSecondsToMinutesAndSeconds(duration) : null;

    const totalDataInSec = !isShadow
      ? liveDuration + eventPlayback + eventUpload + recordingPlayback + recordingUpload
      : shadowUpload + shadowPlayback;

    const resultObject = {
      live: formatDuration(liveDuration),
      eventPlayBack: formatDuration(eventPlayback),
      eventUpload: formatDuration(eventUpload),
      recordingPlayBack: formatDuration(recordingPlayback),
      recordingUpload: formatDuration(recordingUpload),
      shadowUpload: formatDuration(shadowUpload),
      shadowPlayback: formatDuration(shadowPlayback),
      total: formatSecondsToMinutesAndSeconds(totalDataInSec),
      date: isContains ? '' : createdAt,
      time: createdAt,
    };
    resultArray.push(resultObject);
  });

  return resultArray?.map((item, index) => (
    <Fragment key={index}>
      <TableRow
        sx={{
          '&:hover': {
            bgcolor: '#F6F9FE',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid #e0e0e0',
          },
          '& .borderOne': {
            borderLeft: '1px solid #e0e0e0',
            fontSize: '.90rem',
          },
          '& .borderTwo': {
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            fontSize: '.90rem',
          },
        }}
      >
        <TableCell
          align="left"
          sx={{
            pl: 11.5 + indent * 3.5,
            width: '40%',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box flex="1" fontSize=".90rem">
              {licenseTimestampFormat(item?.date)}
            </Box>
            <Box flex="1" fontSize=".90rem">
              {format12HourTime(item?.time)}
            </Box>
          </Box>
        </TableCell>

        {!isShadow ? (
          <>
            <TableCell align="right" className="borderOne">
              {item?.eventPlayBack}
            </TableCell>
            <TableCell align="right" className="borderTwo">
              {item?.eventUpload}
            </TableCell>
            <TableCell align="right" sx={{ fontSize: '.90rem' }}>
              {item?.live}
            </TableCell>
            <TableCell align="right" className="borderOne">
              {item?.recordingPlayBack}
            </TableCell>
            <TableCell align="right" className="borderTwo">
              {item?.recordingUpload}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="right" className="borderOne">
              {item?.shadowPlayback}
            </TableCell>
            <TableCell align="right" className="borderTwo">
              {item?.shadowUpload}
            </TableCell>
          </>
        )}

        <TableCell align="right" sx={{ width: '12%', fontSize: '.90rem' }}>
          {item?.total}
        </TableCell>
      </TableRow>
    </Fragment>
  ));
}
