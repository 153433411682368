import { store } from '@/store';
import { isTenantLoggedIn } from '@/store/auth';
import {
  PageView,
  selectFullWidthLayout,
  selectHideToolbar,
  selectSidebarMode,
  selectToolbarMenu,
} from '@/store/page-view';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { LogoImage } from '@/web/@components/LogoImage';
import { PageBreadcrumb } from '@/web/@components/PageBreadcrumb';
import { BreadcrumbContextProvider } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { MainLayoutAppBar } from '@/web/@layouts/MainLayout/MainLayoutAppBar';
import { SidebarDrawer } from '@/web/@layouts/MainLayout/SidebarDrawer';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH, TOOLBAR_HEIGHT } from './config';

export function MainLayout() {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  const tenantLoggedIn = useSelector(isTenantLoggedIn);
  const fullWidthLayout = useSelector(selectFullWidthLayout);
  const isSidebarCollapsed = useSelector(selectSidebarMode);
  const hideToolbar = useSelector(selectHideToolbar);
  const toolbarMenuOpen = useSelector(selectToolbarMenu);

  const [lastPath, setLastPath] = useState(null);
  const [onTop, setOnTop] = useState(toolbarMenuOpen);

  const drawerWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH),
    [isSidebarCollapsed]
  );

  useEffect(() => {
    if (lastPath === location.pathname) return;
    store.dispatch(PageView.setHideToolbar(false));
    store.dispatch(PageView.setFullWidthLayout(false));
    store.dispatch(PageView.setToolbarMenuOpen(false));
    setLastPath(location.pathname);
  }, [location.pathname, lastPath]);

  /** @type {import('react').PointerEventHandler<HTMLDivElement>} */
  const handlePointerMove = (e) => {
    if (toolbarMenuOpen) {
      setOnTop(true);
    } else if (e.pageY < 15) {
      setOnTop(true);
    } else if (e.pageY > TOOLBAR_HEIGHT) {
      setOnTop(false);
    }
  };

  if (!tenantLoggedIn) {
    return (
      <ErrorBoundary>
        <BreadcrumbContextProvider>
          <Box textAlign="center" p={2}>
            <LogoImage href="/" src="/images/layouts/logo.svg" />
            <Outlet />
          </Box>
        </BreadcrumbContextProvider>
      </ErrorBoundary>
    );
  }

  if (query.layout === 'empty') {
    return (
      <ErrorBoundary>
        <BreadcrumbContextProvider>
          <Outlet />
        </BreadcrumbContextProvider>
      </ErrorBoundary>
    );
  }

  return (
    <BreadcrumbContextProvider>
      <Box display="flex" height="100vh" justifyContent="center">
        <Box
          component="nav"
          sx={{
            width: { lg: drawerWidth },
            flexShrink: { lg: 0 },
            height: '100vh', // Ensure sidebar takes full height
          }}
        >
          <SidebarDrawer />
        </Box>
        <Box
          component="main"
          position="relative"
          overflow="hidden"
          height="100vh" // Ensures the main content area takes full viewport height
          width={{
            xs: '100%',
            lg: `calc(100% - ${drawerWidth}px)`,
          }}
          onPointerMove={handlePointerMove}
        >
          {!hideToolbar || !mdAndUp ? (
            <MainLayoutAppBar position="relative" />
          ) : (
            <MainLayoutAppBar
              position="absolute"
              sx={{
                transition: 'transform 0.1s ease-in',
                transform: onTop ? undefined : `translateY(-100%)`,
              }}
            />
          )}

          {!hideToolbar && (
            <Box sx={{ px: 2.5, pt: 2, pb: 0, display: { md: 'none' } }}>
              <PageBreadcrumb />
            </Box>
          )}

          <Box
            display="flex"
            mx="auto"
            height={hideToolbar ? '100%' : 'calc(100vh - 60px)'} // Deduct app bar height
            pb={5}
            overflow="auto" // Fix for scrolling issues
            justifyContent="center"
          >
            <ErrorBoundary key={location.pathname}>
              <Box
                width="100%"
                justifyContent="center"
                maxWidth={fullWidthLayout || query.fullWidth ? undefined : '1500px'}
              >
                <Outlet />
              </Box>
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
    </BreadcrumbContextProvider>
  );
}
