import api from '@/api';
import { CAMERA_DOWNLOAD_SD_ICON, PLAY_ICON_ENABLED } from '@/assets/constants/images';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { isDev } from '@/config';
import { store } from '@/store';
import {
  selectCurrentTenantName,
  selectSecretToken,
  selectTenantId,
  selectUserEmail,
} from '@/store/auth';
import { selectSessionId } from '@/store/hub-connection';
import { SmartCache } from '@/utils/caching/smart-cache';
import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { formatTimestamp } from '@/utils/datetime';
import { formatTriggerName, selectDisplaySourceId } from '@/utils/events';
import { downloadVideoFile } from '@/utils/file-utils';
import { roundToNearestSecond } from '@/utils/formatting';
import { fetchMyIP } from '@/utils/geoinfo';
import { toastInfo, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import { HubConnectionContext } from '@/web/@components/HubConnectionContext';
import { PageLink } from '@/web/@components/PageLink';
import { ScoreMiniWidget } from '@/web/safety/scoring/@components/ScoreMiniWidget';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { EventGridItemThumbnail } from './EventGridItemThumbnail';

/** @typedef {'OnCloud'|'OnSDCard'|'RecordingOff'|'RecordingErased'} MediaRecordingState */

const SD_TO_CLOUD_TIMEOUT = 30000;
const STATUS_UPDATE_INTERVAL = 1000;
const MAX_EVENT_DURATION = 60 * 60 * 1000;

/** @type {SmartCache<{endpointId: number, tenantId: string, triggerName: string}>} */
const eventCache = new SmartCache('event-player-info-cache', 365 * 24 * 3600 * 1000);
/** @type {SmartCache<number>} */
const sdToCloudCache = new SmartCache('sd-to-cloud-event-progress-cache', SD_TO_CLOUD_TIMEOUT);
/** @type {SmartCache<GetEventResponse>} */
const eventDetailsCache = new SmartCache('event.details.cache', 15 * 60 * 1000, {
  name: 'Dashcam Player v3',
});
/** @type {SmartCache<EventPreview>} */
const previewRecordingCache = new SmartCache('event.preview.recording.cache', 15 * 60 * 1000, {
  name: 'Dashcam Player v3',
});
/** @type {SmartCache<any>} */
const previewMediaCache = new SmartCache('event.preview.media.cache', 15 * 60 * 1000, {
  name: 'Dashcam Player v3',
});

/** @param {{item: EventV5ResponseModel | EventV2, disabled?: boolean, disabledPlay?: boolean} & import('@mui/system').BoxProps} props */
export function EventGridListItem(props) {
  const { item, disabled, disabledPlay = false, ...boxProps } = props;

  const navigate = useNavigate();
  const hub = useContext(HubConnectionContext);

  /** @type {StateVariable<number>} */
  const [stateUpdateKey, setStateUpdateKey] = useState(0);
  /** @type {StateVariable<MediaRecordingState>} */
  const [recordingState, setRecordingState] = useState();
  /** @type {StateVariable<EndpointDetailDto>} */
  const [camera, setCamera] = useState();
  /** @type {StateVariable<string>} */
  const [cameraState, setCameraState] = useState();
  // /** @type {StateVariable<number>} */
  // const [upcomingCount, setUpcomingCount] = useState(0);
  // /** @type {StateVariable<number>} */
  // const [receivedCount, setReceivedCount] = useState(0);
  /** @type {StateVariable<number>} */
  const [lastActivityAt, setLastActivityAt] = useState(0);
  /** @type {StateVariable<number>} */
  const [retrieveRequestAt, setRetrieveRequestAt] = useState(0);
  /** @type {StateVariable<string>} */
  const [currentRequestId, setCurrentRequestId] = useState();
  /** @type {StateVariable<boolean>} */
  const [busy, setBusy] = useState(false);
  /** @type {StateVariable<AbortController>} */
  const [processed, setProcessed] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [wasInParkingMode, setWasInParkingMode] = useState(false);
  const [actualWakeUpAt, setActualWakeUpAt] = useState(null);
  const [isReportSent, setIsReportSend] = useState(false);

  const cameraOnline = useMemo(() => Boolean(camera?.isOnline), [camera?.isOnline]);
  const cameraInParking = useMemo(() => isCameraInParkingMode(cameraState), [cameraState]);

  const displaySourceId = useMemo(
    () => selectDisplaySourceId(item?.triggerCategoryId, item?.triggerName),
    [item?.triggerCategoryId, item?.triggerName]
  );

  const isSmarterAiItem = useMemo(
    () => window.location.pathname?.split('/')?.at(1) === 'smarter-ai',
    []
  );

  const canPlay = useMemo(() => {
    return Boolean(
      item &&
        item.uploadCompleted &&
        item.recordingEndTimestamp &&
        item.recordingStartTimestamp &&
        (isUndefined(recordingState) ||
          recordingState === 'OnSDCard' ||
          recordingState === 'OnCloud') &&
        item.recordingEndTimestamp - item.recordingStartTimestamp > 100 &&
        item.recordingEndTimestamp - item.recordingStartTimestamp < MAX_EVENT_DURATION
    );
  }, [item, recordingState]);

  const { ref } = useInView({
    delay: 500,
    threshold: 0.1,
    initialInView: false,
    onChange: async (inView) => {
      if (!inView || !canPlay || recordingState !== 'OnCloud') return;
      if (processed) return processed.abort();

      const aborter = new AbortController();
      const signal = aborter.signal;
      setProcessed(aborter);

      try {
        if (!(await eventDetailsCache.getItem(item.id))) {
          setStateUpdateKey((v) => v + 1);
        }

        const state = store.getState();
        const secretToken = selectSecretToken(state);

        let result = await previewRecordingCache.getItem(item.id);
        if (result) return;

        // Update recording cache
        const request = api.ac.v5.events.$eventId(Number(item?.id)).preview.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        result = await request.process();
        if (!result) return;
        await previewRecordingCache.setItem(item.id, result);

        // Update media cache
        if (signal.aborted) return;
        try {
          const cacheKey = `${item?.deviceId}_${item?.id}_${item?.eventTimestamp}_${item?.eventEndTimestamp}_video`;
          if (!(await previewMediaCache.getItem(cacheKey))) {
            const url = await downloadVideoFile(result.videoPreviewUrl, signal);
            await previewMediaCache.setItem(cacheKey, url);
          }
        } catch (err) {
          console.error(err);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  // const hasProgress = useMemo(
  //   () => receivedCount < upcomingCount && upcomingCount,
  //   [receivedCount, upcomingCount]
  // );

  const sendWakeUpReport = useCallback(
    /** @param {string} [failReason] */
    (failReason) => {
      if (isReportSent || !retrieveRequestAt || !wasInParkingMode || !actualWakeUpAt) return;
      try {
        const state = store.getState();
        const tenantId = selectTenantId(state);
        const tenantName = selectCurrentTenantName(state);
        const sessionId = selectSessionId(state);
        const secretToken = selectSecretToken(state);
        const userEmail = selectUserEmail(state);

        api.ac.v5.endpoint.wakeup.statistics
          .$post({
            headers: {
              Authorization: secretToken,
            },
            data: [
              {
                duration: wasInParkingMode ? Date.now() - retrieveRequestAt : 0,
                status: !Boolean(failReason),
                failureReason: failReason,
                dashcamEndpointId: camera?.id,
                dashcamDeviceKey: camera?.deviceSerialNumber,
                reportingTime: Date.now(),
                requestTime: retrieveRequestAt,
                tenantId,
                tenantName,
                initiatorClientType: 'BROWSER',
                initiatorEndpointId: sessionId + '',
                userEmail,
                initiateWakeup: wasInParkingMode ? true : false,
              },
            ],
          })
          .process()
          .catch(console.error);
      } catch (error) {
      } finally {
        setWasInParkingMode(false);
        setIsReportSend(true);
      }
    },
    [
      retrieveRequestAt,
      camera?.id,
      camera?.deviceSerialNumber,
      wasInParkingMode,
      isReportSent,
      actualWakeUpAt,
    ]
  );

  useEffect(() => {
    if (!item) return;

    eventCache.setItem(item.id, {
      endpointId: item.endpointId,
      triggerName: item.triggerName,
      tenantId: selectTenantId(store.getState()),
    });

    try {
      const metadata = item?.eventMetadata ? JSON.parse(item?.eventMetadata) : null;
      setRecordingState(metadata?.mediaRecordingState);
    } catch {
      setStateUpdateKey((v) => v + 1);
    }

    sdToCloudCache.getItem(item.id).then((requestTime) => {
      if (!requestTime) return;
      setBusy(true);
      // setUpcomingCount(0);
      // setReceivedCount(0);
      setLastActivityAt(0);
      setCurrentRequestId(null);
      setStateUpdateKey((v) => v + 1);
      setRetrieveRequestAt(requestTime);
    });
  }, [item]);

  /***********************************************************
   *                                                          *
   *                    REPORT WAKE UP TIME                   *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    sendWakeUpReport();
  }, [sendWakeUpReport]);

  useEffect(() => {
    if (!hub) return;
    /** @param {{type: string, text: string}} message */
    const handler = (message) => {
      if (message?.type === MESSAGE_TYPE.PARKING_DEVICE_AWAKE) {
        setCameraState(message.text);
        if (message.text === 'NotInParkingMode') {
          setActualWakeUpAt(Date.now());
        }
      } else if (message?.type === MESSAGE_TYPE.RECORDING_UPCOMING) {
        const recordings = JSON.parse(message.text) || {};
        if (recordings.requestId !== currentRequestId) return;
        // setUpcomingCount((v) => v + (recordings.upcomingMediaList?.length ?? 0));
        setLastActivityAt(Date.now());
      } else if (message?.type === MESSAGE_TYPE.RECORDING_RESPONSE) {
        const response = JSON.parse(message.text) || {};
        if (response.requestId !== currentRequestId) return;
        // setReceivedCount((v) => v + 1);
        setLastActivityAt(Date.now());
      }
    };
    hub.on('newMessage', handler);
    return () => hub.off('newMessage', handler);
  }, [hub, currentRequestId]);

  const updateCamera = useCallback(
    /**
     * @param {any} id
     * @param {AbortSignal} [signal]
     * @returns {Promise<any>}
     */
    async (id, signal) => {
      const state = store.getState();
      const secretToken = selectSecretToken(state);
      // @ts-ignore
      const request = api.ac.v2['event-messaging'].events.$eventId(id).$get({
        signal,
        params: { secretToken },
      });
      try {
        const result = await request.process();
        const { event, endpoint } = result || {};
        eventDetailsCache.setItem(event?.id, result);
        const metadata = event?.eventMetadata ? JSON.parse(event?.eventMetadata) : null;
        setRecordingState(metadata?.mediaRecordingState);
        setCameraState(endpoint?.parkingModeStatus);
        setCamera(endpoint);
      } catch (err) {
        console.error(err);
      }
    },
    []
  );

  useEffect(() => {
    if (!item?.id || !stateUpdateKey) return;
    const aborter = new AbortController();
    updateCamera(item.id, aborter.signal);
    return () => aborter.abort();
  }, [stateUpdateKey, updateCamera, item?.id]);

  useEffect(() => {
    const prev = currentRequestId;
    return () => {
      if (!prev) return;
      const secretToken = selectSecretToken(store.getState());
      api.ac.v3.media.device.recordings.request
        .$requestId(prev)
        .$delete({ headers: { Authorization: `Bearer ${secretToken}` } })
        .process()
        .catch(console.error);
    };
  }, [currentRequestId]);

  useEffect(() => {
    if (!busy || !camera?.id || !item?.id || !retrieveRequestAt) return;

    // stop if state changed to cloud
    if (recordingState === 'OnCloud') {
      setCurrentRequestId(null);
      setBusy(false);
      return;
    }

    if (!cameraOnline) {
      toastWarning('Camera is Offline');
      setBusy(false);
      return;
    }

    // wake up or upload media
    async function process() {
      const state = store.getState();
      const sessionId = selectSessionId(state);
      const secretToken = selectSecretToken(state);
      if (cameraInParking) {
        // handle request timeout
        if (Date.now() - retrieveRequestAt > SD_TO_CLOUD_TIMEOUT) {
          toastWarning('Event upload timed out');
          setBusy(false);
          return;
        }

        setWasInParkingMode(true);

        // send wake signal
        await api.ac.v5.endpoint
          .$endpointId(camera.id)
          .awake.$post({
            headers: {
              Authorization: secretToken,
            },
            params: {
              senderId: sessionId,
              awakeUpTimeInSec: 600,
            },
          })
          .process()
          .catch((err) => {
            sendWakeUpReport('API_ERROR');
          });
      } else {
        // handle request timeout
        if (lastActivityAt) {
          if (Date.now() - lastActivityAt > SD_TO_CLOUD_TIMEOUT) {
            toastWarning('Event upload timed out');
            setCurrentRequestId(null);
            setBusy(false);
          }
          return;
        }

        // start upload
        setLastActivityAt(Date.now());
        const request = api.ac.v5.events.$eventId(Number(item.id)).recordings.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            senderEndpointId: sessionId,
          },
        });
        const result = await request.process();
        // setUpcomingCount(result.recordings.length);
        // setReceivedCount(result.recordings.length);
        setCurrentRequestId(result.requestId);
      }
    }
    process().catch(console.error);

    // check status after an interval
    const tid = setTimeout(() => {
      setStateUpdateKey((v) => v + 1);
      setRetrieveRequestAt((v) => v + 1);
    }, STATUS_UPDATE_INTERVAL);
    return () => clearTimeout(tid);
  }, [
    busy,
    item?.id,
    camera?.id,
    cameraOnline,
    cameraInParking,
    recordingState,
    retrieveRequestAt,
    lastActivityAt,
    sendWakeUpReport,
  ]);

  useEffect(() => {
    if (busy || !item || !retrieveRequestAt || !cameraOnline) return;

    const postReport = async () => {
      sdToCloudCache.removeItem(item.id);
      const state = store.getState();
      const tenantId = selectTenantId(state);
      const secretToken = selectSecretToken(state);
      const clientIPAddress = await fetchMyIP();
      const request = api.ac.v3.report['play-back-click-to-screen'].$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          tenantId,
          latency: Date.now() - retrieveRequestAt,
          chunkSize: roundToNearestSecond(
            item.recordingEndTimestamp - item.recordingStartTimestamp
          ),
          reportId: Date.now() + '',
          clientType: 'BROWSER',
          baseURL: window.location.hostname,
          endpointId: item.endpointId,
          eventId: '' + item?.id,
          playBackType: 'SD_TO_ON_CLOUD',
          timestamp: retrieveRequestAt,
          userId: state.auth.userId,
          userEmail: state.auth.email,
          clientIPAddress,
        },
      });
      request.process();
    };

    postReport();
  }, [busy, item, retrieveRequestAt, cameraOnline]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handlePlayback = (e) => {
    if (busy || !canPlay || disabled) return;
    if (recordingState === 'OnCloud' || isUndefined(recordingState)) {
      const url = disabledPlay
        ? ''
        : `${isSmarterAiItem ? '/smarter-ai' : ''}/events/play?id=${item.id}`;
      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank').focus();
      } else {
        navigate(url);
      }
    } else if (recordingState === 'OnSDCard') {
      setBusy(true);
      // setUpcomingCount(0);
      // setReceivedCount(0);
      setLastActivityAt(0);
      setCurrentRequestId(null);
      setStateUpdateKey((v) => v + 1);
      setRetrieveRequestAt(Date.now());
      sdToCloudCache.setItem(item.id, Date.now());
    }
  };

  const handlePointerEnter = () => {
    if (canPlay && !camera && !stateUpdateKey) {
      setStateUpdateKey((v) => v + 1);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const { origin, pathname } = window.location;
    const url = `${origin}${pathname}`;
    const customUrl = `${url}/play?id=${item?.id}`;
    // To copy on right-click, use a direct user-initiated click event
    if (navigator.clipboard && document.hasFocus()) {
      navigator.clipboard
        .writeText(customUrl)
        .then(() => {
          toastInfo('Event URL copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = customUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      toastInfo('Event URL copied to clipboard');
    }
  };

  const playButtonTitle = useMemo(() => {
    if (recordingState === 'OnCloud' || isUndefined(recordingState)) {
      return 'Cloud Recording';
    } else if (recordingState === 'RecordingOff') {
      return 'Camera Recording Disabled';
    } else if (recordingState === 'RecordingErased') {
      return 'Camera Recording Erased';
    } else if (recordingState === 'OnSDCard') {
      if (!busy) {
        return 'Camera Recording';
      } else if (!camera) {
        return 'Checking Camera Status';
      } else if (cameraInParking) {
        return 'Waking up the camera';
      } else if (cameraOnline) {
        return 'Downloading Recording';
      } else {
        return 'Camera is Offline';
      }
    }
    return null;
  }, [busy, camera, recordingState, cameraOnline, cameraInParking]);

  return (
    <Box
      ref={ref}
      {...boxProps}
      display="flex"
      flexDirection="column"
      onPointerEnter={handlePointerEnter}
    >
      <EventGridItemThumbnail
        item={item}
        camera={camera}
        disabled={!canPlay}
        source={displaySourceId}
        onClick={handlePlayback}
        disablePreview={recordingState !== 'OnCloud'}
        onContextMenu={handleContextMenu}
      />
      <Box
        className="event-grid-list-item-info"
        py="3px"
        pr={canPlay && isDev ? '74px' : '40px'}
        width="100%"
        position="relative"
      >
        {boxProps.children ?? (
          <>
            <EllipsisTextViewContainer>
              <PageLink
                className="marquee"
                to={`/cameras/${item.endpointId}`}
                fontSize="0.875rem"
                fontWeight={600}
                target={disabled ? '_blank' : undefined}
              >
                {formatCameraName(item.deviceLabel, item.deviceId)}
              </PageLink>
            </EllipsisTextViewContainer>
            <EllipsisTextViewContainer>
              <Typography
                fontSize="0.825rem"
                variant="subtitle2"
                fontWeight={400}
                lineHeight="20px"
                title={formatTriggerName(item.triggerName)}
              >
                {formatTriggerName(item.triggerName)}
              </Typography>
            </EllipsisTextViewContainer>
            <EllipsisTextViewContainer>
              <Typography
                fontSize="0.825rem"
                variant="subtitle2"
                fontWeight={400}
                lineHeight="20px"
              >
                {formatTimestamp(item.eventTimestamp)}
              </Typography>
            </EllipsisTextViewContainer>
          </>
        )}

        {isDev && !boxProps?.children && (
          <Box
            sx={{
              cursor: busy ? 'not-allowed' : 'pointer',
              position: 'absolute',
              top: '3px',
              right: canPlay && !disabledPlay ? '35px' : '3px',
              p: 0,
            }}
          >
            <Tooltip title={`Event Magnitude: ${item?.magnitude}`} arrow followCursor>
              <Box width="30px" height="28px" position="relative">
                <ScoreMiniWidget value={item?.magnitude} max={100} />
                <Typography
                  position="absolute"
                  left={5}
                  right={5}
                  top={1}
                  bottom={0}
                  textAlign={'center'}
                  borderRadius="50%"
                  lineHeight={item?.magnitude ? '28px' : '20px'}
                  fontWeight={600}
                  fontSize={!Number.isInteger(item?.magnitude) ? '0.70rem' : '.75rem'}
                >
                  {item?.magnitude > 0 ? Math.round(item?.magnitude) : '...'}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        )}

        {canPlay && !disabledPlay && (
          <Tooltip title={playButtonTitle} arrow followCursor>
            <IconButton
              className="event-grid-list-item-play-btn"
              onClick={handlePlayback}
              sx={{
                cursor: busy ? 'not-allowed' : 'pointer',
                position: 'absolute',
                top: '3px',
                right: '3px',
                p: 0,
                width: '28px',
                height: '28px',
              }}
            >
              {busy && (
                <CircularProgress
                  size={24}
                  // thickness={hasProgress ? 24 : 5}
                  // variant={hasProgress ? 'determinate' : 'indeterminate'}
                  // value={hasProgress ? (100 * receivedCount) / upcomingCount : undefined}
                  sx={{
                    color: '#fff',
                    opacity: 0.8,
                    position: 'absolute',
                    inset: 2,
                  }}
                />
              )}
              <BoxImage
                src={
                  !busy && recordingState === 'OnSDCard'
                    ? CAMERA_DOWNLOAD_SD_ICON
                    : PLAY_ICON_ENABLED
                }
                size="100%"
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
