import { useEffect, useMemo, useRef } from 'react';
import { VIDEO_MINUTES_CHART_HEIGHT, VIDEO_MINUTES_CHART_MARGIN_LEFT } from '../../assets';

export function VideoMinuteConsumptionLimit(props) {
  const { parentWidth, cumulativeMaxDuration, subscriptionData, subscriptionMaxData } = props;

  const canvasRef = useRef(null);

  const maxValue = useMemo(
    () => Math.max(cumulativeMaxDuration, subscriptionMaxData),
    [cumulativeMaxDuration, subscriptionMaxData]
  );

  const extraHeight = useMemo(() => {
    const adjustedHeight =
      maxValue === subscriptionMaxData
        ? VIDEO_MINUTES_CHART_HEIGHT
        : VIDEO_MINUTES_CHART_HEIGHT * Math.abs(subscriptionMaxData / cumulativeMaxDuration);

    const extraHeight = adjustedHeight - VIDEO_MINUTES_CHART_HEIGHT;
    return extraHeight > 200 ? 0 : extraHeight;
  }, [cumulativeMaxDuration, subscriptionMaxData, maxValue]);

  useEffect(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext('2d');

    if (!ctx) return;

    const dataLength = subscriptionData?.length;

    const height = canvas.height;
    const width = canvas.width;

    const calculatedHeight = VIDEO_MINUTES_CHART_HEIGHT + 5 + extraHeight;

    if (ctx && dataLength > 0 && parentWidth) {
      // Clear the canvas
      ctx.clearRect(0, 0, width, height);

      // Calculate the scaling factors
      const stepWidth = (width - VIDEO_MINUTES_CHART_MARGIN_LEFT) / (dataLength - 1);
      const scaleY =
        VIDEO_MINUTES_CHART_HEIGHT / Math.max(cumulativeMaxDuration, subscriptionMaxData);

      // Set the style for the dashed line
      ctx.strokeStyle = 'red';
      //  ctx.lineWidth = 2.2;
      // Dashed line pattern: 5px dash, 4px gap

      // Clear previous drawing
      ctx.clearRect(0, 0, width, height);

      let currentIndex = dataLength - 1; // Start from the last data point

      function drawLine() {
        ctx.clearRect(0, 0, width, height);

        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.setLineDash([4, 3]); // Dashed line pattern

        ctx.beginPath();
        for (let i = currentIndex; i >= 0; i--) {
          const x = VIDEO_MINUTES_CHART_MARGIN_LEFT + stepWidth * i;
          const y = calculatedHeight - subscriptionData[i] * scaleY;

          if (i === currentIndex) {
            ctx.moveTo(x, y); // Move to the first point
          } else {
            ctx.lineTo(x, y); // Draw a horizontal line to each next point
          }
        }

        ctx.stroke();

        // Draw the triangle arrow at the rightmost side of the line (pointing right)
        const arrowSize = 10; // Size of the arrowhead
        const arrowX = VIDEO_MINUTES_CHART_MARGIN_LEFT + stepWidth * (dataLength - 1.2); // Rightmost point X
        const arrowY = calculatedHeight - subscriptionData[dataLength - 1] * scaleY; // Rightmost point Y
        ctx.fillStyle = 'red'; // Arrow color
        ctx.beginPath();
        ctx.moveTo(arrowX, arrowY); // Tip of the arrow (right side)
        ctx.lineTo(arrowX + arrowSize, arrowY - arrowSize / 2); // Top point of the triangle
        ctx.lineTo(arrowX + arrowSize, arrowY + arrowSize / 2); // Bottom point of the triangle
        ctx.closePath();
        ctx.fill();
      }

      drawLine();
    }
  }, [parentWidth, cumulativeMaxDuration, subscriptionMaxData, subscriptionData, extraHeight]);

  if (!maxValue) return;

  return (
    <canvas
      ref={canvasRef}
      width={parentWidth}
      height={VIDEO_MINUTES_CHART_HEIGHT}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 100,
        marginTop: `${6}px`,
        //marginTop: `${8 + extraHeight}px`,
        pointerEvents: 'none',
      }}
    />
  );
}
