/**
 * @typedef {object} TenantVideoMinuteUsagesV2
 * @property {string} [tenantId]
 * @property {string} [tenantName]
 * @property {string} [endpointId]
 * @property {string} [endpointName]
 * @property {Array<VideoMinuteUsages>} [videoMinuteUsages]
 */

/**
 * @typedef {object} TenantTreeItemProps
 * @property {number} [indent]
 * @property {string} [filter]
 * @property {string[]} selected
 * @property {string[]} selectedTree
 * @property {string| number} tenantExpandLoadingId
 * @property {boolean} isShadow
 * @property {import("../VideoMinutuesTenantTable").DescendantVideoMinutesItems} entity
 * @property {(path: string[]) => any} [onSelect]
 * @property {Array<TenantVideoMinuteUsagesV2>} [tenantsMinutes ]
 */

import api from '@/api';
import { isLocalNetwork } from '@/config';
import { store } from '@/store';
import { selectSecretToken, selectTenantId, selectTenantTree } from '@/store/auth';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { Box, CircularProgress, TableCell, TableRow, Typography } from '@mui/material';
import { getMonth } from 'date-fns';
import { random } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoMinutesFilterContext } from '../../../@context/VideoMinutesFilterContext';
import { formatSecondsToMinutesAndSeconds } from '../../../@utils/date';
import {
  findDescendantTenantListByTenant,
  findEntityVideoDataByType,
  findNestedTenantDataByIds,
  sumParentAndChildMinutesDataByType,
  tenantsMinutesSumByType,
} from '../../../@utils/overview';
import { CLIENT_WISE_MINUTE_PAYLOAD, ENTITY_TYPE } from '../../../assets';
import { EndpointTreeItem } from '../EndpointTreeItem';

/** @param {TenantTreeItemProps} props */
export function TenantTreeItem(props) {
  const {
    indent = 0,
    entity,
    selected,
    tenantsMinutes,
    onSelect,
    tenantExpandLoadingId,
    isShadow,
    selectedTree,
  } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const tenantTree = useSelector(selectTenantTree);

  const { monthInt, year, client } = useContext(VideoMinutesFilterContext);

  const selectedMonth = useMemo(() => monthInt || getMonth(new Date()) + 1, [monthInt]);
  const selectedYear = useMemo(() => year || new Date().getFullYear(), [year]);
  const selectedClient = useMemo(
    () => CLIENT_WISE_MINUTE_PAYLOAD[client] || CLIENT_WISE_MINUTE_PAYLOAD['smarter_ai'],
    [client]
  );

  const [collapsed, setCollapsed] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [endpointTransaction, setEndpointTransaction] = useState([]);
  const [loading, setLoading] = useState(false);

  const active = useMemo(
    () =>
      entity.id?.toString() === tenantId?.toString() ||
      (selected?.length === 1 && selected?.at(0) === entity.id),
    [entity?.id, selected, tenantId]
  );

  const entityDurations = useMemo(() => {
    let summedData = null;
    const state = store.getState();
    if (['TENANT_ROOT']?.includes(entity?.type)) {
      const tenantOwnData = findEntityVideoDataByType(tenantsMinutes, entity?.id, 'TENANT');
      const tenantTreePath = [...state?.auth.supportPath, tenantId];
      const tenantData = findNestedTenantDataByIds(tenantTree, tenantTreePath);
      const tenantChildIds = findDescendantTenantListByTenant(tenantData, true);

      const subTenantMinutes = tenantsMinutes?.filter((item) =>
        tenantChildIds?.includes(item?.tenantId)
      );

      const parentMinutesData = tenantsMinutesSumByType([tenantOwnData]);
      const childMinutesData = tenantsMinutesSumByType(subTenantMinutes);
      summedData = sumParentAndChildMinutesDataByType(parentMinutesData, childMinutesData);
    } else if (['TENANT_PARENT']?.includes(entity?.type)) {
      const tenantOwnData = findEntityVideoDataByType(tenantsMinutes, entity?.id, 'TENANT');
      const tenantTreePath = [...state?.auth.supportPath, tenantId, entity?.id];
      const tenantData = findNestedTenantDataByIds(tenantTree, tenantTreePath);
      const tenantChildIds = findDescendantTenantListByTenant(tenantData, true);

      const subTenantMinutes = tenantsMinutes?.filter((item) =>
        tenantChildIds?.includes(item?.tenantId)
      );

      const parentMinutesData = tenantsMinutesSumByType([tenantOwnData]);
      const childMinutesData = tenantsMinutesSumByType(subTenantMinutes);
      summedData = sumParentAndChildMinutesDataByType(parentMinutesData, childMinutesData);
    } else {
      const entityData = findEntityVideoDataByType(
        tenantsMinutes,
        entity?.id,
        entity?.type === 'ENDPOINT' ? 'ENDPOINT' : 'TENANT'
      );

      summedData = tenantsMinutesSumByType([entityData]);
    }

    const eventPlayBack = summedData?.['EVENT_PLAYBACK'];
    const eventUpload = summedData?.['EVENT_UPLOAD'];
    const liveDuration = summedData?.['LIVE_STREAMING'];
    const recordingPlayBack = summedData?.['RECORDING_PLAYBACK'];
    const recordingUpload = summedData?.['RECORDING_UPLOAD'];
    const shadowPlayback = summedData?.['SHADOW_EVENT_PLAYBACK'];
    const shadowUpload = summedData?.['SHADOW_EVENT_UPLOAD'];
    const totalDuration = !isShadow
      ? (eventPlayBack || 0) +
        (eventUpload || 0) +
        (liveDuration || 0) +
        (recordingPlayBack || 0) +
        (recordingUpload || 0)
      : (shadowPlayback || 0) + (shadowUpload || 0);

    return {
      eventPlayBack: formatSecondsToMinutesAndSeconds(eventPlayBack),
      eventUpload: formatSecondsToMinutesAndSeconds(eventUpload),
      live: formatSecondsToMinutesAndSeconds(liveDuration),
      recordingPlayBack: formatSecondsToMinutesAndSeconds(recordingPlayBack),
      recordingUpload: formatSecondsToMinutesAndSeconds(recordingUpload),
      shadowPlayback: formatSecondsToMinutesAndSeconds(shadowPlayback),
      shadowUpload: formatSecondsToMinutesAndSeconds(shadowUpload),
      total: totalDuration ? formatSecondsToMinutesAndSeconds(totalDuration) : '',
    };
  }, [tenantsMinutes, entity?.id, entity?.type, isShadow, tenantTree, tenantId]);

  const getEndpointTransaction = (endpointId, nextPageToken = null) => {
    try {
      setLoading(true);

      const request = api.ac.v5['video-minutes'].tenants
        .$tenantId(tenantId)
        .endpoints.$endpointId(endpointId)
        .$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: selectedYear,
            monthOfTheYear: selectedMonth,
            ...selectedClient,
            nextPageToken: nextPageToken,
          },
        });

      request
        ?.process()
        ?.then((data) => {
          setEndpointTransaction((prevTransactions) => [
            ...prevTransactions,
            ...data?.transactions, // Append new transactions to the previous ones
          ]);

          // If there's a nextPageToken in the response, recursively fetch the next page
          if (data?.nextPageToken) {
            getEndpointTransaction(endpointId, data.nextPageToken);
          } else {
            setLoading(false); // Stop loading once all pages are fetched
          }
        })
        .catch((ex) => setLoading(false));
    } catch (ex) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isCollapsed = !selected?.includes(entity?.id);
    setCollapsed(isCollapsed);
  }, [entity.id, selected, tenantId]);

  if (!entityDurations && entity?.type === 'ENDPOINT') return null;

  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid #e0e0e0',
            fontSize: '.82rem',
            fontWeight: expandedId === entity?.id || selected?.includes(entity?.id) ? 500 : 400,
          },
          '& .cellOne': {
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
          },
          '& .cellTwo': {
            borderLeft: '1px solid #e0e0e0',
          },
        }}
      >
        <TableCell
          align="left"
          key={entity.id + random(100, 200)}
          sx={{
            pl: 4 + indent * 3.8,
            width: '40%',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            {(entityDurations ||
              ['TENANT_ROOT', 'TENANT_PARENT_X', 'TENANT_PARENT', 'TENANT']?.includes(
                entity?.type
              )) && (
              <Box
                width="24px"
                height="24px"
                ml="-25px !important"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandedId(collapsed ? entity?.id : null);

                  if (collapsed && entity?.type === 'ENDPOINT') {
                    setEndpointTransaction([]);
                    getEndpointTransaction(entity?.id);
                  }

                  if (
                    collapsed &&
                    ['TENANT_PARENT_X', 'TENANT_PARENT', 'TENANT']?.includes(entity?.type)
                  ) {
                    onSelect([entity?.id]);
                  }

                  setCollapsed((v) => !v);
                }}
              >
                <svg
                  width="12px"
                  height="12px"
                  viewBox="0 0 2 2"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transition: 'all 0.3s ease-in',
                    margin: collapsed ? '6px 10px' : '10px 6px',
                    transform: collapsed ? 'rotate(-90deg)' : undefined,
                  }}
                >
                  <path d="M1,1,2,0H0Z" fill="#5C6A80" />
                </svg>
              </Box>
            )}
            <Box
              flex="1"
              fontWeight={
                ['TENANT_ROOT', 'TENANT_PARENT_X', 'TENANT_PARENT', 'TENANT']?.includes(
                  entity?.type
                )
                  ? 450
                  : 400
              }
              flexDirection="row"
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              <IconLabelItem
                sx={{ alignItems: 'center', fontSize: '.85rem' }}
                title={
                  <LongTextShortener text={entity?.name || 'Smarter AI Dashcam'} length={45} />
                }
              />

              {((loading && expandedId === entity?.id) || tenantExpandLoadingId === entity?.id) && (
                <CircularProgress size={15} />
              )}

              {isLocalNetwork ? (
                <Typography color={'red'} fontSize={'8px'}>
                  {ENTITY_TYPE[entity?.type]}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </TableCell>

        {!isShadow ? (
          <>
            <TableCell align="right" className="cellOne">
              {entityDurations?.eventPlayBack}
            </TableCell>

            <TableCell align="right">{entityDurations?.eventUpload}</TableCell>

            <TableCell align="right" className="cellTwo">
              {entityDurations?.live}
            </TableCell>

            <TableCell align="right" className="cellTwo">
              {entityDurations?.recordingPlayBack}
            </TableCell>

            <TableCell align="right" className="cellOne">
              {entityDurations?.recordingUpload}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="right" className="cellTwo">
              {entityDurations?.shadowPlayback}
            </TableCell>

            <TableCell align="right" className="cellOne">
              {entityDurations?.shadowUpload}
            </TableCell>
          </>
        )}

        <TableCell align="right" sx={{ fontSize: '.90rem' }}>
          {entityDurations?.total || ''}
        </TableCell>
      </TableRow>

      {collapsed || !entity?.children?.length ? null : (
        <>
          {entity?.children.map((child, index) => (
            <TenantTreeItem
              key={child.id + index}
              indent={indent + 1}
              entity={child}
              selected={selected}
              tenantsMinutes={tenantsMinutes}
              onSelect={(path) => onSelect([entity.id, ...path])}
              tenantExpandLoadingId={tenantExpandLoadingId}
              isShadow={isShadow}
              selectedTree={selectedTree}
            />
          ))}
        </>
      )}

      {endpointTransaction && !collapsed && (
        <EndpointTreeItem endpointTransaction={endpointTransaction} isShadow={isShadow} />
      )}
    </>
  );
}
