import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { useContext } from 'react';
import {
  SetVideoMinutesFilterContext,
  VideoMinutesFilterContext,
} from '../../@context/VideoMinutesFilterContext';

export function VideoMinutesFilteringArea({ textView }) {
  const setVideoMinutesFilter = useContext(SetVideoMinutesFilterContext);

  const { searchedEndpoint, searchedTenant, tenants, endpoints } =
    useContext(VideoMinutesFilterContext);

  return (
    <PageFilteringArea
      value={{ searchedEndpoint, searchedTenant }}
      textView={textView}
      onChange={(data) => {
        setVideoMinutesFilter({
          searchedEndpoint: data.searchedEndpoint,
          searchedTenant: data.searchedTenant,
        });
      }}
      filters={[
        {
          type: 'CHOICE',
          key: 'searchedTenant',
          title: 'Tenant',
          submenu: tenants,
          hidden: tenants?.length <= 1,
        },
        {
          type: 'CHOICE',
          key: 'searchedEndpoint',
          title: 'Camera',
          submenu: endpoints,
          hidden: !endpoints?.length,
        },
      ]}
    />
  );
}
