import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken, selectSupportPath } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { getMonth } from 'date-fns';
import { debounce, sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoMinutesFilterContext } from '../../../@context/VideoMinutesFilterContext';
import { transformEndpointUsagesData } from '../../../@utils';
import { CLIENT_WISE_MINUTE_PAYLOAD } from '../../../assets';
import { VideoMinutesTableHeader } from '../../VideoMinutesTableHeader';
import { TenantEndpointsTreeItem } from '../TenantEndpointsTreeItem';

/**
 * @typedef {object} DescendantVideoMinutesItems
 * @property {Array<DescendantVideoMinutesItems>} [children]
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [type]
 * @property {string} [parentEntityId]
 */

/**
 * @typedef {object} VideoMinutesEndpointTriggerTableProps
 * @property {string} [selectedTenant]
 */

/** @param {VideoMinutesEndpointTriggerTableProps} props */
export function VideoMinutesEndpointTriggerTable(props) {
  const { selectedTenant } = props;

  const { client, year, monthInt, setMinutesEndpoints, searchedCameras } =
    useContext(VideoMinutesFilterContext);

  const supportPath = useSelector(selectSupportPath);
  const secretToken = useSelector(selectSecretToken);

  const selectedClient = useMemo(() => {
    const firstPath = window.location.pathname.split('/')[1];
    return (
      CLIENT_WISE_MINUTE_PAYLOAD[client] ||
      (firstPath === 'smarter-ai'
        ? CLIENT_WISE_MINUTE_PAYLOAD['smarter_ai']
        : CLIENT_WISE_MINUTE_PAYLOAD['customer'])
    );
  }, [client]);

  const selectedMonth = useMemo(() => monthInt || getMonth(new Date()) + 1, [monthInt]);
  const selectedYear = useMemo(() => year || new Date().getFullYear(), [year]);
  const isShadow = useMemo(() => client === 'vision_team', [client]);

  const treeKey = useMemo(
    () => `${client}-${selectedMonth}-${selectedYear}-${isShadow}`,
    [client, selectedMonth, selectedYear, isShadow]
  );

  /** @type {StateVariable<array>} */
  const [itemList, setItemList] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [tenantsLoading, setTenantsLoading] = useState(true);
  /** @type {StateVariable<number>} */
  const [endpointExpandLoadingId, setEndpointExpandLoadingId] = useState(null);
  /** @type {StateVariable<Array<TenantVideoMinuteUsages>>} */
  const [entityMinutes, setEntityMinutes] = useState([]);

  /** @type {DescendantVideoMinutesItems[]} */
  const filteredEntityTree = useMemo(() => {
    const applyFilter = (tree) => {
      if (!tree?.length) return undefined;

      const filteredTree = [];
      for (const tenant of tree) {
        if (!tenant) continue;

        // Check if the current tenant matches any of the searched cameras
        const matchesFilter = !searchedCameras?.length || searchedCameras?.includes(tenant.name);

        // Add the tenant to the filtered tree if it matches the filter condition
        if (matchesFilter) {
          filteredTree.push({ ...tenant });
        }
      }

      // Sort the filtered tree by data type and then by name
      filteredTree.sort((a, b) => {
        const order = { TENANT: 1, GROUP: 2, ENDPOINT: 3 };
        return order[a.type] - order[b.type];
      });

      return sortBy(filteredTree, 'name'); // Sort alphabetically by name after sorting by type
    };

    return applyFilter(itemList);
  }, [itemList, searchedCameras]);

  const updateChildrenById = (items, targetId, updatedArray) => {
    return items.map((item) => {
      if (item.id === targetId) {
        return { ...item, children: updatedArray };
      } else if (item.children && item.children.length > 0) {
        item.children = updateChildrenById(item.children, targetId, updatedArray);
      }
      return item;
    });
  };

  const getEndpointTriggersVideoMinutes = useCallback(
    async (endpointId) => {
      try {
        const request = api.ac.v5['video-minutes'].tenants
          .$tenantId(selectedTenant)
          .endpoints.$endpointId(endpointId)
          ['usages-by-usages-name'].$get({
            headers: {
              Authorization: secretToken,
            },
            params: {
              year: selectedYear,
              monthOfTheYear: selectedMonth + '',
              ...selectedClient,
            },
          });
        await request.process();
        const transformedData = transformEndpointUsagesData(request.result);
        return transformedData;
      } catch (ex) {
        return [];
      }
    },
    [secretToken, selectedMonth, selectedClient, selectedYear, selectedTenant]
  );

  const handleEndpointChange = async (tree) => {
    const selectedEndpointId = tree?.at(tree?.length - 1);
    setEndpointExpandLoadingId(selectedEndpointId);
    const endpointTriggers = await getEndpointTriggersVideoMinutes(selectedEndpointId);
    setEntityMinutes((prev) => [...prev, ...endpointTriggers?.triggerVideoMinutes]);
    const sortedData = sortBy(endpointTriggers?.triggersArray, [(item) => item.name.toLowerCase()]);
    if (sortedData?.length > 0) {
      setItemList((prev) => updateChildrenById(prev, selectedEndpointId, sortedData));
    }
    setEndpointExpandLoadingId(null);
  };

  const getTenantEndpointsVideoMinutes = useCallback(async () => {
    try {
      if (!selectedTenant) return [];
      const request = api.ac.v5['video-minutes'].tenants.$tenantId(selectedTenant).endpoints.$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          year: selectedYear,
          monthOfTheYear: selectedMonth + '',
          ...selectedClient,
        },
      });
      await request.process();
      setEntityMinutes((prev) => [...prev, ...request.result]);
      setMinutesEndpoints(request.result);
      return request.result;
    } catch (ex) {
      setMinutesEndpoints([]);
      return [];
    }
  }, [
    secretToken,
    selectedMonth,
    selectedClient,
    selectedTenant,
    selectedYear,
    setMinutesEndpoints,
  ]);

  const tenantEndpointData = useCallback(async () => {
    try {
      const endpointList = await getTenantEndpointsVideoMinutes();
      return (
        endpointList?.map((item) => ({
          name: item?.endpointName || 'Smarter AI Dashcam',
          id: item?.endpointId,
          parentEntityId: selectedTenant,
          type: 'ENDPOINT',
        })) || []
      );
    } catch (error) {
      console.error('Failed to fetch tenant endpoints:', error);
      return [];
    }
  }, [selectedTenant, getTenantEndpointsVideoMinutes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSubTenantList = useCallback(
    debounce(async (signal) => {
      setTenantsLoading(true);
      setItemList([]);
      setEntityMinutes([]);
      const currentTenantEndpoints = await tenantEndpointData();
      setItemList(currentTenantEndpoints);
      setTenantsLoading(false);
    }, 1000),
    [tenantEndpointData]
  );

  useEffect(() => {
    setTenantsLoading(true);
    const aborter = new AbortController();
    debouncedFetchSubTenantList(aborter?.signal);
    return () => {
      debouncedFetchSubTenantList?.cancel();
      aborter.abort();
    };
  }, [debouncedFetchSubTenantList]);

  useEffect(() => {
    setItemList([]);
    setEntityMinutes([]);
  }, [monthInt, selectedTenant, year]);

  if (tenantsLoading) {
    return (
      <CenterBox style={{ height: '250px', border: '1px solid #dfe4ec' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!filteredEntityTree?.length) {
    return (
      <CenterBox style={{ height: '350px', border: '1px solid #dfe4ec' }}>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          gap={0}
          size="140px"
          message="No Camera data found"
        />
      </CenterBox>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: '52vh',
        pb: '15px',
      }}
      elevation={0}
    >
      <Table stickyHeader sx={{ width: '100%' }} size="small">
        <VideoMinutesTableHeader isShadow={isShadow} />
        <TableBody>
          {filteredEntityTree?.map((entity) => (
            <TenantEndpointsTreeItem
              key={entity.id + treeKey}
              tree={entity}
              selected={supportPath || []}
              entityMinutes={entityMinutes}
              onSelect={handleEndpointChange}
              endpointExpandLoadingId={endpointExpandLoadingId}
              isShadow={isShadow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
