/**
 * @typedef {object} TenantVideoMinuteUsagesV2
 * @property {string} [tenantId]
 * @property {string} [tenantName]
 * @property {string} [endpointId]
 * @property {string} [endpointName]
 * @property {Array<VideoMinuteUsages>} [videoMinuteUsages]
 */

/**
 * @typedef {object} TenantEndpointsTreeItemProps
 * @property {number} [indent]
 * @property {string} [filter]
 * @property {boolean} [isShadow]
 * @property {string[]} selected
 * @property {string| number} endpointExpandLoadingId
 * @property {import("../VideoMinutesEndpointTriggerTable").DescendantVideoMinutesItems} tree
 * @property {(path: string[]) => any} [onSelect]
 * @property {Array<TenantVideoMinuteUsagesV2>} [entityMinutes ]
 */

import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { TableCell, TableRow } from '@mui/material';
import { getMonth } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoMinutesFilterContext } from '../../../@context/VideoMinutesFilterContext';
import { getTotalVideoMinuteUsagesByType } from '../../../@utils';
import { formatSecondsToMinutesAndSeconds } from '../../../@utils/date';
import { findEntityVideoDataByType, sumDurationSeconds } from '../../../@utils/overview';
import { CLIENT_WISE_MINUTE_PAYLOAD } from '../../../assets';
import { VideoMinutesEntityName } from '../../VideoMinutesEntityName';
import { EndpointTriggerTreeItem } from '../EndpointTriggerTreeItem';

/** @param {TenantEndpointsTreeItemProps} props */
export function TenantEndpointsTreeItem(props) {
  const {
    indent = 0,
    tree,
    selected,
    entityMinutes,
    onSelect,
    endpointExpandLoadingId,
    isShadow,
  } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { monthInt, year, client } = useContext(VideoMinutesFilterContext);

  const [collapsed, setCollapsed] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [endpointTransaction, setEndpointTransaction] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedMonth = useMemo(() => monthInt || getMonth(new Date()) + 1, [monthInt]);
  const selectedYear = useMemo(() => year || new Date().getFullYear(), [year]);
  const selectedClient = useMemo(
    () => CLIENT_WISE_MINUTE_PAYLOAD[client] || CLIENT_WISE_MINUTE_PAYLOAD['smarter_ai'],
    [client]
  );

  const active = useMemo(
    () =>
      tree.id?.toString() === tenantId?.toString() ||
      (selected?.length === 1 && selected?.at(0) === tree.id),
    [tree?.id, selected, tenantId]
  );

  const nameParts = useMemo(() => tree.name, [tree.name]);

  const entityDurations = useMemo(() => {
    const data = findEntityVideoDataByType(entityMinutes, tree?.id, tree?.type);
    if (data) {
      const eventPlayBack = sumDurationSeconds(data, 'EVENT_PLAYBACK');
      const eventUpload = sumDurationSeconds(data, 'EVENT_UPLOAD');
      const liveDuration = sumDurationSeconds(data, 'LIVE');
      const recordingPlayBack = sumDurationSeconds(data, 'RECORDING_PLAYBACK');
      const recordingUpload = sumDurationSeconds(data, 'RECORDING_UPLOAD');
      const shadowPlayback = sumDurationSeconds(data, 'SHADOW_EVENT_PLAYBACK');
      const shadowUpload = sumDurationSeconds(data, 'SHADOW_EVENT_UPLOAD');
      const totalDuration = getTotalVideoMinuteUsagesByType(data?.videoMinuteUsages, isShadow);

      return {
        eventPlayBack,
        eventUpload,
        live: liveDuration,
        recordingPlayBack,
        recordingUpload,
        shadowPlayback,
        shadowUpload,
        total: totalDuration ? formatSecondsToMinutesAndSeconds(totalDuration) : '',
      };
    } else {
      return null;
    }
  }, [entityMinutes, tree?.id, tree?.type, isShadow]);

  // eslint-disable-next-line no-unused-vars
  const getTriggerTransaction = (triggerId) => {
    try {
      setLoading(true);
      const request = api.ac.v5['video-minutes'].tenants
        .$tenantId(tenantId)
        .endpoints.$endpointId(triggerId)
        .$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            year: selectedYear,
            monthOfTheYear: selectedMonth,
            ...selectedClient,
          },
        });

      request
        ?.process()
        ?.then((data) => {
          setEndpointTransaction(data?.transactions);
        })
        .then((d) => setLoading(false));
    } catch (ex) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCollapsed(!selected?.includes(tree?.id));
  }, [tree.id, selected, tenantId]);

  if (!entityDurations && tree?.type === 'ENDPOINT') return null;

  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid #e0e0e0',
            fontSize: '0.82rem !important',
            fontWeight: expandedId === tree?.id ? 500 : 400,
          },
          '& .cellOne': {
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
          },
          '& .cellTwo': {
            borderLeft: '1px solid #e0e0e0',
          },
        }}
      >
        <TableCell
          align="left"
          key={tree.id}
          sx={{
            pl: 4 + indent * 3.8,
            width: '40%',
          }}
        >
          <VideoMinutesEntityName
            entityDurations={entityDurations}
            tree={tree}
            collapsed={collapsed}
            setExpandedId={setExpandedId}
            onSelect={onSelect}
            setCollapsed={setCollapsed}
            loading={loading}
            nameParts={nameParts}
            expandedId={expandedId}
            endpointExpandLoadingId={endpointExpandLoadingId}
          />
        </TableCell>

        {!isShadow ? (
          <>
            <TableCell align="right" className="cellOne" sx={{}}>
              {entityDurations?.eventPlayBack}
            </TableCell>

            <TableCell align="right" sx={{ fontSize: '.90rem' }}>
              {entityDurations?.eventUpload}
            </TableCell>

            <TableCell align="right" className="cellTwo">
              {entityDurations?.live}
            </TableCell>

            <TableCell align="right" className="cellTwo">
              {entityDurations?.recordingPlayBack}
            </TableCell>

            <TableCell align="right" className="cellOne">
              {entityDurations?.recordingUpload}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="right" className="cellTwo">
              {entityDurations?.shadowPlayback}
            </TableCell>

            <TableCell align="right" className="cellOne">
              {entityDurations?.shadowUpload}
            </TableCell>
          </>
        )}

        <TableCell align="right" sx={{ width: '12%', fontSize: '.90rem' }}>
          {entityDurations?.total}
        </TableCell>
      </TableRow>

      {collapsed || !tree?.children?.length ? null : (
        <>
          {tree?.children.map((child, index) => (
            <TenantEndpointsTreeItem
              key={child.id + index}
              indent={indent + 1}
              tree={child}
              selected={selected}
              entityMinutes={entityMinutes}
              onSelect={(path) => onSelect([tree.id, ...path])}
              endpointExpandLoadingId={endpointExpandLoadingId}
              isShadow={isShadow}
            />
          ))}
        </>
      )}

      {endpointTransaction && !collapsed && (
        <EndpointTriggerTreeItem endpointTransaction={endpointTransaction} isShadow={isShadow} />
      )}
    </>
  );
}
