import { T } from '@/assets/locales';
import { KnownUserError, readErrorMessage } from '@/utils/errors';
import {
  BarChart,
  CheckCircleTwoTone,
  ErrorTwoTone,
  InfoOutlined,
  WarningTwoTone,
} from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoxImage } from '../BoxImage';

/**
 * @typedef {object} IconMessageBoxPropsBase
 * @property {'primary'|'secondary'} [text]
 * @property {string} [src]
 * @property {import('@mui/system').ResponsiveStyleValue<any>} [size]
 * @property {any} [message]
 * @property {any} [error]
 * @property {any} [retryText]
 * @property {'success'|'error'|'info'|'warning'| 'chart'} [icon]
 * @property {boolean} [centered] To position this to center of the screen
 * @property {import('react').MouseEventHandler<HTMLButtonElement>} [onRetry]
 */
//

/**
 * @typedef {IconMessageBoxPropsBase & import('@mui/material').BoxProps} IconMessageBoxProps
 */

/**
 * @param {IconMessageBoxProps} props
 */
export function IconMessageBox(props) {
  const {
    text = 'secondary',
    centered,
    src,
    size,
    message,
    error,
    onRetry,
    retryText,
    icon,
    ...extra
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  if (!message && !error && !(src || icon)) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="5px"
      {...extra}
      {...(centered
        ? {
            width: '100%',
            height: '100%',
          }
        : {})}
    >
      {src ? (
        <BoxImage draggable={false} size={size} src={src} fallback={null} objectPosition="bottom" />
      ) : typeof icon === 'string' ? (
        <Box
          draggable={false}
          textAlign="center"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: size,
              color: theme.palette[icon]?.main,
            },
          }}
        >
          {icon === 'error' ? (
            <ErrorTwoTone />
          ) : icon === 'warning' ? (
            <WarningTwoTone />
          ) : icon === 'success' ? (
            <CheckCircleTwoTone />
          ) : icon === 'info' ? (
            <InfoOutlined />
          ) : icon === 'chart' ? (
            <BarChart htmlColor="#dfe4ec" sx={{ mb: '-25px' }} />
          ) : null}
        </Box>
      ) : null}

      <Typography
        component="div"
        variant="subtitle2"
        fontWeight="normal"
        textAlign="center"
        color={theme.palette.text[text]}
        mt={1.5}
      >
        {message ?? (error instanceof KnownUserError ? readErrorMessage(error) : null)}
      </Typography>

      {onRetry && (
        <Button
          size="small"
          type="button"
          variant="outlined"
          style={{
            width: '100px',
            marginTop: '10px',
          }}
          onClick={onRetry}
        >
          {retryText ?? t(T['button.retry'])}
        </Button>
      )}
    </Box>
  );
}
