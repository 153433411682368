import { formatTriggerName } from '@/utils/events';
import { getTriggerImageByName } from '@/utils/triggers';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { Box, CircularProgress, Typography } from '@mui/material';
import { truncate } from 'lodash';
import { useMemo } from 'react';

export function VideoMinutesEntityName(props) {
  const {
    entityDurations,
    tree,
    collapsed,
    setExpandedId,
    onSelect,
    setCollapsed,
    loading,
    nameParts,
    expandedId,
    endpointExpandLoadingId,
  } = props;

  const entityName = useMemo(() => nameParts || 'Smarter AI Dashcam', [nameParts]);

  const triggerImage = useMemo(() => getTriggerImageByName(entityName), [entityName]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      {(entityDurations || tree?.type === 'TENANT') && tree?.type !== 'TRIGGER' ? (
        <Box
          width="24px"
          height="24px"
          ml="-15px !important"
          onClick={(e) => {
            e.stopPropagation();
            if (collapsed) {
              setExpandedId(tree?.id);
            } else {
              setExpandedId(null);
            }

            if (collapsed && tree?.type === 'ENDPOINT') {
              onSelect([tree?.id]);
            }

            if (collapsed && tree?.type === 'TRIGGER') {
              //getTriggerTransaction(tree?.id);
            }
            setCollapsed((v) => !v);
          }}
        >
          <svg
            width="12px"
            height="12px"
            viewBox="0 0 2 2"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transition: 'all 0.3s ease-in',
              margin: collapsed ? '6px 10px' : '10px 6px',
              transform: collapsed ? 'rotate(-90deg)' : undefined,
            }}
          >
            <path d="M1,1,2,0H0Z" fill="#5C6A80" />
          </svg>
        </Box>
      ) : null}

      <Box
        flex="1"
        fontWeight={tree?.type === 'ENDPOINT' ? 450 : 400}
        flexDirection="row"
        display={'flex'}
        alignItems={'center'}
        gap={1}
      >
        {tree?.type === 'TRIGGER' ? (
          <IconLabelItem
            src={triggerImage}
            iconSize={{
              xs: '15px',
              md: '20px',
            }}
            fontSize="0.875rem"
            pl={'4px'}
            sx={{
              alignItems: 'center',
              gap: '10px',
              alignContent: 'left',
            }}
            title={
              <Typography
                fontSize={'0.85rem !important'}
                title={entityName}
                sx={{ textAlign: 'left' }}
              >
                {truncate(formatTriggerName(entityName), { length: 50 })}
              </Typography>
            }
          />
        ) : (
          <Typography alignItems="center" fontSize=".85rem">
            {entityName}
          </Typography>
        )}

        {((loading && expandedId === tree?.id) || endpointExpandLoadingId === tree?.id) && (
          <CircularProgress size={15} />
        )}
      </Box>
    </Box>
  );
}
