import api from '@/api';
import { parseJwtToken } from '@/utils/crypto';
import { toastWarning } from '@/utils/toaster';
import { Auth, selectGeoTabTenant, selectTenant } from '..';

export const mappedTenantData = (tenantAccessList) => {
  const tenantDescendantAccess = (tenantAccessList || [])?.reduce((acc, tenant) => {
    acc[tenant.tenantId] = tenant.accessChildTenants || false;
    return acc;
  }, {});

  return tenantDescendantAccess;
};

/**
 * Login to a new session
 * @param {AccountLoginRequest} data
 * @returns {StoreAction<Promise>}
 */
export const doLogin = (data) => async (dispatch) => {
  const request = api.ac.v5.auth.login.$post({ data });
  await request.process();
  const result = request.result;
  const tenantList = result.tenantAccessList || [];
  dispatch(Auth.setRequest(data));
  if (result.tenantAccessList.length === 1) {
    dispatch(Auth.setUserAccessibleFeatures(tenantList[0]?.enabledFeatures));
    const action = selectTenant({
      tenantId: tenantList[0].tenantId,
      token: result.refreshToken,
      email: result.email,
    });
    await dispatch(action);
  }
  dispatch(Auth.setAccount(result));
  const tenantDescendantAccess = mappedTenantData(result?.tenantAccessList);
  dispatch(getAllDescendants(tenantDescendantAccess)).catch(console.error);
};

/**
 * Refresh all descendants of the current logged in user
 * @param {object} tenantDescendantAccess
 * @returns {StoreAction<Promise>}
 */
export const getAllDescendants = (tenantDescendantAccess) => async (dispatch, getState) => {
  dispatch(Auth.setLoadingDescendants(true));
  const state = getState();
  const request = api.ac.v5.auth['tenant-access'].batch.$post({
    data: state.auth.tenantList.map((x) => ({
      tenantId: x.tenantId,
      email: state.auth.email,
      token: state.auth.accessToken || state.auth.accountToken,
    })),
  });
  const result = await request.process();
  if (result.errors?.length) {
    console.error('Failed to acquire some access tokens', result.errors);
  }
  for (const token of result.accessTokenResponses || []) {
    if (!tenantDescendantAccess[token?.tenantId]) continue;
    await new Promise((resolve) => setTimeout(resolve, 100));
    const request = api.ac.v5.tenant.descendants.$get({
      headers: {
        Authorization: token.accessToken,
      },
    });
    const result = await request.process();
    result.tenantId = token.tenantId;
    result.tenantName = state.auth.tenantIdToName[token.tenantId];
    dispatch(Auth.setTenantTree(result));
  }
  dispatch(Auth.setLoadingDescendants(false));
};

/**
 * @typedef {object} LoginWithGeoTabData
 * @property {string} [database]
 * @property {string} [username]
 * @property {string} [password]
 * @property {string} [geotabUrl]
 * @property {string} [authenticationType]
 */

/**
 * Get GeoTab session Data
 * @param {LoginWithGeoTabData} sessionData
 */
const getGeoTabSession = async (sessionData) => {
  try {
    const { database, password, username, geotabUrl } = sessionData;

    const formData = {
      username: username,
      password,
      database,
      myGUrl: geotabUrl,
    };

    const request = api.ac.v5.auth['login-myg'].$post({ data: formData });
    const result = await request?.process();
    return result;
  } catch (err) {
    console.log('data', err);
    console.error('GeoTab Session API failed');
    if (err.response.data.code === 401) {
      toastWarning('Error', 'Login failed');
      return;
    }
    toastWarning('Error', err?.message);
  }
};

/**
 * Login by GeoTab
 * @param {LoginWithGeoTabData} data
 */
export const doLoginGeotab = (data) => async (dispatch) => {
  try {
    dispatch(Auth.setRequest(data));
    const result = await getGeoTabSession(data);
    if (!result) return;
    const accessTokenResult = result;
    const action = selectGeoTabTenant({ tokenData: accessTokenResult });
    await dispatch(action);

    const parsedData = parseJwtToken(accessTokenResult?.accessToken);
    const res = {
      accountId: parsedData?.account_id,
      email: data?.username,
      refreshToken: accessTokenResult?.accessToken,
      teamList: [],
      isGeoTabLogin: true,
      tenantAccessList: [
        {
          tenantId: accessTokenResult?.tenantId,
          tenantName: 'Geotab',
          userRole: parsedData?.role,
        },
      ],
    };
    dispatch(Auth.setAccount(res));
  } catch (error) {
    console.error(error);
  }
};
