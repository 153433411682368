import api from '@/api';
import {
  EVENT_CLUSTER_ICON,
  MAP_DRIVER_EVENT_ALERT_ICON,
  MAP_EVENT_ALERT_ICON,
  MAP_RUNNING_ICON,
  MAP_START_ICON,
  MAP_STOP_ICON,
  NO_SEARCH_RESULT_IMG,
} from '@/assets/constants/images';
import { IMPERIAL_COUNTRIES } from '@/assets/constants/unit-system';
import { GOOGLE_MAP_ID, isDev } from '@/config';
import { useGoogleMap } from '@/hooks/useGoogleMap';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import { PageView, selectSimplifyCurve } from '@/store/page-view';
import { makeAvatarImage, rotateImage } from '@/utils/canvas';
import { TimestampStore } from '@/utils/collections/timestamp-store';
import { downloadImageFile } from '@/utils/file-utils';
import { calculateSpeed, filterBySpeed } from '@/utils/geo';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { CustomLogger } from '@/utils/logger';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MapTypeSwitchButton } from '@/web/@components/MapTypeSwitchButton';
import { DefaultRenderer, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Gesture as GestureIcon, Polyline as PolylineIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Hidden, Popper } from '@mui/material';
import { orderBy, sortedIndexBy, sortedUniqBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TripDriverScoreInfo } from '../TripDriverScoreInfo';
import { CameraDowntime } from './CameraDowntime';
import { DriverPopperContent } from './DriverPopperContent';
import { TripEndPopperContent } from './EndPopperContent';
import { TripEventPopperContent } from './EventPopperContent';
import { TripStartPopperContent } from './StartPopperContent';
import { ThumbnailPopperContent } from './ThumbnailPopperContent';
import { DouglasPeucker } from './douglas-peucker';
import { createVirtualElement, findGeoPoints, interpolatePoint } from './utils';

const logger = new CustomLogger('GoogleMap');

const BLUE_LINE_WIDTH = 6;
const BLUE_LINE_COLOR = '#2680EB';
const TIME_TOLERANCE_MS = 5000;
const SPEED_TOLERANCE_MS = 200;

/**
 * @typedef {object} ThumbnailItem
 * @property {number} ts
 * @property {{[key: string]: VideoThumbnailData}} value
 */

/**
 * @typedef {object} PopperData
 * @property {'start'|'end'|'event'|'driver'|'thumbnail'} type
 * @property {import('@popperjs/core').VirtualElement} target
 * @property {string} [sensorUrl]
 * @property {TriggeredEvent} [event]
 * @property {ThumbnailItem['value']} [thumbnails]
 * @property {number} [speed]
 * @property {Driver} [driver]
 */

/**
 * @typedef {object} TripDetailsViewPops
 * @property {TripDetailsResponse} details
 * @property {EndpointDetailDto} camera
 * @property {Array<ThumbnailItem>} [thumbnails]
 */

/** @param {TripDetailsViewPops} props */
export function TripDetailsView(props) {
  const { details: item, camera, thumbnails } = props;

  const location = useLocation();
  const simplifyCurve = useSelector(selectSimplifyCurve);

  /** @type {import('react').Ref<HTMLDivElement>} */
  const parent = useRef();
  /** @type {import('react').Ref<HTMLDivElement>} */
  const container = useRef();

  const isInTripPage = useMemo(() => location?.pathname?.includes('/trips'), [location?.pathname]);

  const [imperial, setImperial] = useState(false);
  /** @type {StateVariable<PopperData>} */
  const [popper, setPopper] = useState(null);
  /** @type {StateVariable<google.maps.Map>} */
  const [map, setMap] = useState(null);
  /** @type {StateVariable<'satellite'|'roadmap'>} */
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  /** @type {StateVariable<number>} */
  const [zoom, setZoom] = useState(null);
  /** @type {StateVariable<Array<google.maps.Polyline>>} */
  const [blueLines, setBlueLines] = useState([]);
  /** @type {StateVariable<google.maps.Marker>} */
  const [startMarker, setStartMarker] = useState(null);
  /** @type {StateVariable<google.maps.Marker>} */
  const [stopMarker, setStopMarker] = useState(null);
  /** @type {StateVariable<Array<google.maps.Marker>>} */
  const [eventMarkers, setEventMarkers] = useState(null);

  /***********************************************************
   *                        Map Data                         *
   ***********************************************************/
  const edges = useMemo(() => {
    return (item?.geoPoints || [])
      .map((path, i) => ({
        connected: i % 2 === 0,
        path: filterBySpeed([...path].reverse(), SPEED_TOLERANCE_MS),
      }))
      .reverse();
  }, [item]);

  const geoPoints = useMemo(() => {
    if (!edges?.length) return [];
    const points = edges.map((x) => x.path).flatMap((x) => x);
    return sortedUniqBy(points, 'timestamp');
  }, [edges]);

  useEffect(() => {
    if (!geoPoints.length) return;
    const first = geoPoints[0].geo;
    fetchGeoInfo(first.lat, first.lon).then((info) => {
      const country = info.country.toLowerCase();
      const exists = IMPERIAL_COUNTRIES.find(
        (x) => country.includes(x.toLowerCase()) || x.toLowerCase().includes(country)
      );
      setImperial(Boolean(exists));
    });
  }, [geoPoints]);

  /***********************************************************
   *                    Point Reduction                      *
   ***********************************************************/

  const curveTolerance = useMemo(() => {
    if (!simplifyCurve) return 0;
    if (!zoom) return 0.15;
    return 1.5 * Math.round(19 - zoom) + 0.1;
  }, [zoom, simplifyCurve]);

  const reducedEdges = useMemo(() => {
    if (!curveTolerance) return edges;
    let before = 0;
    let after = 0;
    const reduced = edges.map(({ path, connected }) => {
      before += path.length;
      path = DouglasPeucker(path, curveTolerance);
      after += path.length;
      return { path, connected };
    });
    logger.debug(`Point reduction: ${before} -> ${after} @ tolerance: ${curveTolerance}`);
    return reduced;
  }, [edges, curveTolerance]);

  /***********************************************************
   *               The Google Map Initialization             *
   ***********************************************************/
  const [loading, error] = useGoogleMap();

  const [centerLat, centerLng] = useMemo(() => {
    const isValidGeo = (geo) => geo?.lat && geo?.lon;
    if (geoPoints?.length) {
      const { geo } = geoPoints[Math.floor(geoPoints.length / 2)];
      return [geo.lat, geo.lon];
    } else if (
      item?.startGeo &&
      isValidGeo(item?.startGeo) &&
      item?.endGeo &&
      isValidGeo(item.endGeo)
    ) {
      const midLat = (item.startGeo.lat + item.endGeo.lat) / 2;
      const midLon = (item.startGeo.lon + item.endGeo.lon) / 2;
      return [midLat, midLon];
    } else if (item?.startGeo && isValidGeo(item.startGeo)) {
      return [item.startGeo.lat, item.startGeo.lon];
    } else if (item?.endGeo && isValidGeo(item.endGeo)) {
      return [item.endGeo.lat, item.endGeo.lon];
    } else if (camera) {
      return [camera.latitude, camera.longitude];
    }
    return [];
  }, [geoPoints, camera, item]);

  // Create instance
  useEffect(() => {
    if (loading || typeof centerLat !== 'number' || typeof centerLng !== 'number') return;
    const map = new window.google.maps.Map(container.current, {
      mapId: GOOGLE_MAP_ID,
      center: new window.google.maps.LatLng(centerLat, centerLng),
      isFractionalZoomEnabled: true,
      maxZoom: 19,
      zoom: 11,
      restriction: {
        latLngBounds: {
          east: 180,
          north: 85,
          south: -85,
          west: -180,
        },
        strictBounds: true,
      },
      noClear: false,
      controlSize: 24,
      mapTypeControl: false,
      mapTypeId: mapTypeId || window.google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      backgroundColor: '#fff',
      clickableIcons: false,
      scaleControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      },
    });
    map.addListener('zoom_changed', () => {
      try {
        setZoom(map.getZoom());
      } catch (err) {
        logger.warn('Failed to read map zoom level', err);
      }
    });
    setMap(map);
    return () => {
      map.unbindAll();
    };
  }, [loading, mapTypeId, centerLat, centerLng]);

  /***********************************************************
   *                       Fit Bound                         *
   ***********************************************************/

  useEffect(() => {
    if (!map || !geoPoints?.length || geoPoints.length < 2) return;
    const bounds = new window.google.maps.LatLngBounds();
    geoPoints.forEach((x) => bounds.extend(new window.google.maps.LatLng(x.geo.lat, x.geo.lon)));
    map.fitBounds(bounds, 30);
  }, [map, geoPoints]);

  /***********************************************************
   *                       The Blue Line                     *
   ***********************************************************/

  /** @type {Array<{path: Array<google.maps.LatLng>, connected: boolean}>} */
  const mapEdges = useMemo(() => {
    if (loading) return [];
    return reducedEdges.map(({ path, connected }) => ({
      connected,
      path: path.map(({ geo }) => new window.google.maps.LatLng(geo.lat, geo.lon)),
    }));
  }, [loading, reducedEdges]);

  useEffect(() => {
    const calculate = () => {
      const blueLines = [];
      for (const edge of mapEdges) {
        const polyline = new window.google.maps.Polyline({
          zIndex: 1,
          path: edge.path,
          strokeColor: BLUE_LINE_COLOR,
          strokeWeight: BLUE_LINE_WIDTH,
          strokeOpacity: edge.connected ? 1 : 0,
          icons: [
            edge.connected
              ? {
                  repeat: '80px',
                  icon: {
                    anchor: new window.google.maps.Point(1, 1),
                    path: 'M1,1,2,0H0Z',
                    fillColor: '#fff',
                    fillOpacity: 1,
                    strokeOpacity: 0,
                    scale: 4,
                    rotation: 180,
                  },
                }
              : {
                  repeat: '20px',
                  icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    scale: 4,
                  },
                },
          ],
        });
        blueLines.push(polyline);
      }
      setBlueLines(blueLines);
    };
    const tid = setTimeout(calculate, 100);
    return () => clearTimeout(tid);
  }, [mapEdges]);

  useEffect(() => {
    if (!map) return;
    blueLines.forEach((line) => line.setMap(map));
    return () => blueLines.forEach((line) => line.setMap(null));
  }, [map, blueLines]);

  /***********************************************************
   *                     Start Marker                        *
   ***********************************************************/

  const startPosition = useMemo(() => {
    if (loading || !geoPoints.length) return;
    const point = geoPoints[0];
    return new window.google.maps.LatLng(point.geo.lat, point.geo.lon);
  }, [geoPoints, loading]);

  useEffect(() => {
    if (loading) return;
    const marker = new window.google.maps.Marker({
      optimized: false,
      zIndex: 50,
      icon: {
        url: MAP_START_ICON,
        scaledSize: new window.google.maps.Size(36, 36),
        anchor: new window.google.maps.Point(18, 18),
      },
    });
    /** @param {{domEvent: MouseEvent}} e */
    const showPopper = (e) => {
      setPopper({
        type: 'start',
        target: createVirtualElement(e.domEvent),
      });
    };
    marker.addListener('mouseup', showPopper);
    marker.addListener('mouseover', showPopper);
    setStartMarker(marker);
  }, [loading]);

  useEffect(() => {
    if (!startMarker || !startPosition) return;
    startMarker.setPosition(startPosition);
    return () => startMarker.setPosition(null);
  }, [startMarker, startPosition]);

  useEffect(() => {
    if (!map || !startMarker || !startMarker.getPosition()) return;
    startMarker.setMap(map);
    return () => startMarker.setMap(null);
  }, [map, startMarker]);

  /***********************************************************
   *                       Stop Marker                       *
   ***********************************************************/

  useEffect(() => {
    if (loading) return;
    const icon =
      item?.tripStatus === 'ENDED'
        ? {
            url: MAP_STOP_ICON,
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: new window.google.maps.Point(20, 20),
          }
        : {
            url: MAP_RUNNING_ICON,
            scaledSize: new window.google.maps.Size(80, 80),
            anchor: new window.google.maps.Point(40, 40),
          };
    const marker = new window.google.maps.Marker({
      icon,
      zIndex: 100,
      optimized: false,
    });

    if (item?.tripStatus === 'ENDED') {
      /** @param {{domEvent: MouseEvent}} e */
      const showPopper = (e) => {
        setPopper({
          type: 'end',
          target: createVirtualElement(e.domEvent),
        });
      };
      marker.addListener('mouseup', showPopper);
      marker.addListener('mouseover', showPopper);
    }

    setStopMarker(marker);
  }, [loading, item?.tripStatus]);

  const [stopPosition, finalHeading] = useMemo(() => {
    if (loading || geoPoints.length < 2) return [];
    const point1 = geoPoints[geoPoints.length - 2];
    const point2 = geoPoints[geoPoints.length - 1];
    const last1 = new window.google.maps.LatLng(point1.geo.lat, point1.geo.lon);
    const last2 = new window.google.maps.LatLng(point2.geo.lat, point2.geo.lon);
    const heading = window.google.maps.geometry.spherical.computeHeading(last1, last2);
    return [last2, heading];
  }, [geoPoints, loading]);

  useEffect(() => {
    if (!stopMarker || !stopPosition) return;
    stopMarker.setPosition(stopPosition);
    return () => stopMarker.setPosition(null);
  }, [stopMarker, stopPosition]);

  useEffect(() => {
    if (!stopMarker || !finalHeading) return;
    /** @type {google.maps.Icon} */ // @ts-ignore
    const icon = stopMarker.getIcon();
    const { width, height } = icon.scaledSize;
    rotateImage(icon.url, finalHeading)
      .then((url) =>
        stopMarker.setIcon({
          url,
          scaledSize: new window.google.maps.Size(width * 2, height * 2),
          anchor: new window.google.maps.Point(width, height),
        })
      )
      .catch(logger.debug);
  }, [finalHeading, stopMarker]);

  useEffect(() => {
    if (!map || !stopMarker || !stopMarker.getPosition()) return;
    stopMarker.setMap(map);
    return () => stopMarker.setMap(null);
  }, [map, stopMarker]);

  /***********************************************************
   *                      Event Markers                      *
   ***********************************************************/
  const disableClustering = useMemo(() => zoom === 19, [zoom]);

  const eventPoints = useMemo(() => {
    if (loading || geoPoints.length < 2 || !item?.triggeredEvents?.length) {
      return [];
    }
    const markers = [];
    for (const event of item.triggeredEvents || []) {
      try {
        const ts = event.eventTimestamp;
        if (!ts) continue;
        const [left, right] = findGeoPoints(geoPoints, ts);
        const geo = interpolatePoint(ts, left, right);
        if (!geo) continue;
        const position = new window.google.maps.LatLng(geo.lat, geo.lon);
        markers.push({ ts, event, position });
      } catch (err) {
        console.warn('Failed to process event', err);
      }
    }
    return orderBy(markers, 'ts');
  }, [loading, item?.triggeredEvents, geoPoints]);

  useEffect(() => {
    if (loading || !eventPoints?.length) return;
    const calculate = async () => {
      const markers = [];
      for (const { position, event } of eventPoints) {
        try {
          const marker = new window.google.maps.Marker({
            position,
            optimized: true,
            zIndex: 200,
            icon: {
              url:
                event.triggerName === 'EMERGENCY_RECORD'
                  ? MAP_DRIVER_EVENT_ALERT_ICON
                  : MAP_EVENT_ALERT_ICON,
              scaledSize: new window.google.maps.Size(28, 28),
              anchor: new window.google.maps.Point(14, 14),
            },
          });
          const sensor = event.snapshots.find((x) => x.source === 'sensor');
          /** @param {google.maps.MapMouseEvent} e */
          const showPopper = async (e) => {
            setPopper({
              type: 'event',
              target: createVirtualElement(e.domEvent),
              event,
              sensorUrl: sensor?.downloadUrl,
            });
          };
          marker.addListener('mouseup', showPopper);
          marker.addListener('mouseover', showPopper);
          markers.push(marker);
        } catch (err) {
          console.warn(err);
        }
      }
      setEventMarkers(markers);
    };
    const tid = setTimeout(calculate, 200);
    return () => clearTimeout(tid);
  }, [eventPoints, loading]);

  useEffect(() => {
    if (!map || !eventMarkers?.length) return;
    if (disableClustering) {
      eventMarkers.forEach((marker) => marker.setMap(map));
      return () => eventMarkers.forEach((marker) => marker.setMap(null));
    } else {
      class CustomRenderer extends DefaultRenderer {
        render({ count, position }) {
          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: EVENT_CLUSTER_ICON,
              scaledSize: new window.google.maps.Size(32, 32),
              anchor: new window.google.maps.Point(16, 16),
            },
            label: {
              text: `${count}`,
              color: 'rgba(255,255,255,0.9)',
              fontSize: '11px',
            },
            zIndex: 1000 + count,
            title: `${count} events`,
          });
        }
      }
      const cluster = new MarkerClusterer({
        map,
        markers: eventMarkers,
        renderer: new CustomRenderer(),
      });
      return () => cluster.setMap(null);
    }
  }, [eventMarkers, disableClustering, map]);

  /***********************************************************
   *                      Show thumbnails                    *
   ***********************************************************/
  /** @type {StateVariable<Array<google.maps.Polyline>>} */
  const [thumbnailLines, setThumbnailLines] = useState(null);

  useEffect(() => {
    if (!map || !thumbnails?.length || geoPoints.length < 2) {
      return;
    }
    const { computeDistanceBetween } = window.google.maps.geometry.spherical;
    const calculate = () => {
      /** @type {TimestampStore<ThumbnailItem>} */
      const store = new TimestampStore();
      store.$keys = thumbnails.map((x) => x.ts);
      store.$values = thumbnails;

      const markers = [];
      for (let i = geoPoints.length - 1; i > 0; --i) {
        try {
          const right = geoPoints[i];
          let left = geoPoints[i - 1];
          if (right.timestamp - left.timestamp > TIME_TOLERANCE_MS) continue;

          const x = sortedIndexBy(thumbnails, { ts: right.timestamp, value: null }, 'ts');
          const thumbnail = thumbnails[x];
          if (!thumbnail || thumbnail.ts <= right.timestamp) continue;

          const { value: thumbs, ts } = thumbnail;
          if (ts < left.timestamp) {
            const geo = interpolatePoint(ts, left, right);
            if (!geo) continue;
            left = { timestamp: ts, geo };
          }

          const points = [left, right];
          const { averageSpeed: speed } = calculateSpeed(points);

          const marker = new window.google.maps.Polyline({
            path: points.map(({ geo }) => new window.google.maps.LatLng(geo.lat, geo.lon)),
            strokeColor: ['#F6803B', '#66D05B'][i & 1],
            strokeWeight: BLUE_LINE_WIDTH * 2,
            strokeOpacity: 0, // isDev ? 1 : 0,
            zIndex: 10,
          });

          const showPopper = (/** @type {google.maps.MapMouseEvent} */ e) => {
            const bounds = map.getBounds();
            if (!bounds || !container.current) return;
            const mapDistance = computeDistanceBetween(
              bounds.getSouthWest(),
              bounds.getNorthEast()
            );
            const mapPixels = Math.hypot(
              container.current.clientWidth,
              container.current.clientHeight
            );
            for (const { position } of eventPoints || []) {
              const distance = computeDistanceBetween(position, e.latLng);
              if (distance * mapPixels < 25 * mapDistance) return;
            }
            setPopper({
              type: 'thumbnail',
              target: createVirtualElement(e.domEvent),
              thumbnails: thumbs,
              speed,
            });
          };
          marker.addListener('mouseup', showPopper);
          marker.addListener('mousemove', showPopper);
          markers.push(marker);
        } catch (err) {
          logger.debug('Failed to process thumbnail', err);
        }
      }
      setThumbnailLines(markers);
    };
    const tid = setTimeout(calculate, 1000);
    return () => clearTimeout(tid);
  }, [thumbnails, eventPoints, geoPoints, map]);

  useEffect(() => {
    if (!map || !thumbnailLines?.length) return;
    thumbnailLines.forEach((line) => line.setMap(map));
    return () => thumbnailLines.forEach((line) => line.setMap(null));
  }, [thumbnailLines, map]);

  /***********************************************************
   *                      Driver Icon                        *
   ***********************************************************/
  /** @type {StateVariable<Driver>} */
  const [driver, setDriver] = useState(null);
  /** @type {StateVariable<string>} */
  const [driverAvatar, setDriverAvatar] = useState(null);
  /** @type {StateVariable<google.maps.Marker>} */
  const [driverMarker, setDriverMarker] = useState(null);

  const lastTripEvent = useMemo(() => {
    if (!item?.tripEvents?.length) return null;
    const validTypes = new Set(['DRIVER_LOGOUT', 'DRIVER_LOGIN']);
    for (let i = item.tripEvents.length - 1; i >= 0; i--) {
      const event = item.tripEvents[i];
      if (event?.driverId && validTypes.has(event.tripEventType)) {
        return event;
      }
    }
    return null;
  }, [item?.tripEvents]);

  const driverPosition = useMemo(() => {
    if (loading || !geoPoints?.length || !lastTripEvent) {
      return null;
    }
    let geo = lastTripEvent.geo;
    if (lastTripEvent.tripEventType !== 'DRIVER_LOGOUT') {
      geo = geoPoints[geoPoints.length - 1].geo;
    }
    return new window.google.maps.LatLng(geo.lat, geo.lon);
  }, [loading, lastTripEvent, geoPoints]);

  useEffect(() => {
    if (loading || !lastTripEvent?.driverId) return;
    const aborter = new AbortController();
    const process = async () => {
      try {
        const request = api.ac.v5.driver.$driverId(lastTripEvent.driverId).$get({
          signal: aborter.signal,
          headers: {
            Authorization: selectSecretToken(store.getState()),
          },
        });
        const resp = await request.process();
        const driver = resp.result;
        setDriver(driver);
        let url = await downloadImageFile(driver.pictureUrl);
        url = await makeAvatarImage(url);
        setDriverAvatar(url);
      } catch (err) {
        logger.debug('Failed to fetch driver', err);
      }
    };
    const tid = setTimeout(process, 800);
    return () => {
      aborter.abort();
      clearTimeout(tid);
    };
  }, [loading, lastTripEvent?.driverId]);

  useEffect(() => {
    if (!driver || !driverPosition || !driverAvatar) return;
    const marker = new window.google.maps.Marker({
      optimized: false,
      position: driverPosition,
      zIndex: 150,
      icon: {
        url: driverAvatar,
        scaledSize: new window.google.maps.Size(42, 42),
      },
    });
    /** @param {{domEvent: MouseEvent}} e */
    const showPopper = async (e) => {
      setPopper({
        type: 'driver',
        target: createVirtualElement(e.domEvent),
        driver,
      });
    };
    marker.addListener('mouseup', showPopper);
    marker.addListener('mouseover', showPopper);
    setDriverMarker(marker);
  }, [driver, driverPosition, driverAvatar]);

  useEffect(() => {
    if (!map || !driverMarker) return;
    driverMarker.setMap(map);
    return () => driverMarker.setMap(null);
  }, [map, driverMarker]);

  /***********************************************************
   *                        Popper                           *
   ***********************************************************/
  useEffect(() => {
    if (!popper) return;
    const aborter = new AbortController();
    document
      ?.querySelector('.infinite-scroll-view')
      ?.parentElement?.addEventListener('scroll', () => setPopper(null), {
        signal: aborter.signal,
      });
    return () => aborter.abort();
  }, [popper]);

  return (
    <Box
      ref={parent}
      height="100%"
      width="100%"
      position="relative"
      overflow="hidden"
      onPointerUp={() => setPopper(null)}
      onPointerLeave={() => setPopper(null)}
    >
      <Box ref={container} width="100%" height="100%" minWidth="200px" minHeight="220px">
        <CenterBox bgcolor="#fff">
          {error ? (
            <IconMessageBox
              size={'96px'}
              src={NO_SEARCH_RESULT_IMG}
              message="Failed to load Google Maps"
            />
          ) : (
            <CircularProgress />
          )}
        </CenterBox>
      </Box>

      {blueLines && !loading && (
        <>
          <MapTypeSwitchButton value={mapTypeId} onUpdate={setMapTypeId} />

          {isDev && (
            <Button
              onClick={() => store.dispatch(PageView.toggleSimplifyCurve())}
              style={{
                position: 'absolute',
                top: '130px',
                right: '6px',
                minWidth: 0,
                width: '24px',
                height: '24px',
                borderRadius: '3px',
                background: '#fff',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
              }}
              sx={{
                '&:hover': {
                  bgcolor: '#fff',
                },
              }}
            >
              {simplifyCurve ? <GestureIcon /> : <PolylineIcon />}
            </Button>
          )}

          {isDev && item && location.pathname.includes('trips') ? (
            <CameraDowntime trip={item} />
          ) : null}

          <Hidden mdDown>
            <Box
              style={{
                position: 'absolute',
                bottom: '18px',
                right: '6px',
                gap: '6px',
              }}
            >
              {item?.driverTripWithScore && (
                <TripDriverScoreInfo driverTripWithScore={item.driverTripWithScore} />
              )}
            </Box>
          </Hidden>
        </>
      )}

      <Popper
        open={Boolean(popper?.target)}
        anchorEl={popper?.target}
        container={parent?.current}
        popperOptions={{ strategy: 'absolute' }}
      >
        <Box
          zIndex={1000}
          position="relative"
          bgcolor="#fff"
          borderRadius="3px"
          boxShadow="0 2px 7px 1px rgb(0 0 0 / 30%)"
          onPointerUp={(e) => e.stopPropagation()}
        >
          {popper?.type === 'event' ? (
            <TripEventPopperContent
              event={popper.event}
              imperial={imperial}
              sensorUrl={popper.sensorUrl}
              isInTripPage={isInTripPage}
              onClose={() => setPopper(null)}
            />
          ) : popper?.type === 'start' ? (
            <TripStartPopperContent item={item} onClose={() => setPopper(null)} />
          ) : popper?.type === 'end' ? (
            <TripEndPopperContent item={item} onClose={() => setPopper(null)} />
          ) : popper?.type === 'driver' ? (
            <DriverPopperContent driver={popper.driver} onClose={() => setPopper(null)} />
          ) : popper?.type === 'thumbnail' ? (
            <ThumbnailPopperContent
              camera={camera}
              thumbnails={popper.thumbnails}
              imperial={imperial}
              speed={popper.speed}
              isInTripPage={isInTripPage}
              onClose={() => setPopper(null)}
            />
          ) : null}
        </Box>
      </Popper>
    </Box>
  );
}
