import api from '@/api';
import { T } from '@/assets/locales';
import { store } from '@/store';
import { selectIsTokenRefreshing, selectSecretToken, selectTenantId } from '@/store/auth';
import { PageView } from '@/store/page-view';
import { SmartCache } from '@/utils/caching/smart-cache';
import { customSortDevices } from '@/utils/cameras';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CameraFilteringArea } from '../CameraFilteringArea';
import { CameraGridListPage } from '../CameraGridList';

/** @type {SmartCache<Array<EndpointInfoAggregated>>} */
const cameraList = new SmartCache('v5.endpoint.list');

/** @param {{ onCountUpdate?: StateAction<number>, fullHeight?: boolean}} props */
export function CameraListPage(props) {
  const { fullHeight, onCountUpdate } = props;

  const { t } = useTranslation();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const tokenRefreshing = useSelector(selectIsTokenRefreshing);

  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [cameras, setCameras] = useState(null);
  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [filteredCameras, setFilteredCameras] = useState([]);

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  useEffect(() => {
    if (!onCountUpdate) return;
    onCountUpdate(filteredCameras?.length);
  }, [onCountUpdate, filteredCameras?.length]);

  useEffect(() => {
    async function process() {
      setLoading(true);
      const time = Date.now();
      try {
        let offset = 0;
        const limit = 500;
        /** @type {Array<EndpointInfoAggregated>} */
        const results = [];
        while (true) {
          const request = api.ac.v5.endpoint.list.$get({
            headers: {
              Authorization: secretToken,
            },
            params: {
              type: 'DEVICE',
              status: 'ACTIVE',
              limit,
              offset,
              tenantId,
            },
          });
          await request.process();
          if (!request.result) break;
          const result = request.result.endpointInfoList;
          results.push(...result);
          if (result.length < limit) break;
          offset += limit;
        }
        const sortedCameras = customSortDevices(results);
        await cameraList.setItem(tenantId, sortedCameras);
        setCameras(sortedCameras || []);
        setFilteredCameras(sortedCameras || []);
      } catch (err) {
        cameraList
          .getItem(tenantId)
          .then(setCameras)
          .catch(() => setCameras([]));
        console.error('Failed to load camera', err);
        reportEvent(SentryEvents.CAMERA_LIST_LOAD_FAILED, '', {
          err,
          tenantId,
          tags: { tenantId },
        });
      } finally {
        setLoading(false);
        const duration = Date.now() - time;
        if (duration > 10000) {
          reportEvent(SentryEvents.SLOW_CAMERA_LIST_LOAD, '', {
            tags: { tenantId, duration },
          });
        }
      }
    }
    process().catch(console.error);
  }, [tenantId, secretToken]);

  if (loading || (tokenRefreshing && !cameras)) {
    return (
      <CenterBox fullView={!fullHeight}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <>
      <Box px={2.5} mt={1}>
        <CameraFilteringArea cameras={cameras} onUpdate={setFilteredCameras} />
      </Box>
      {filteredCameras?.length ? (
        <CameraGridListPage cameras={filteredCameras} fullHeight={fullHeight} />
      ) : (
        <CenterBox fullView>
          <IconMessageBox
            size="256px"
            src="/images/empty/no-vehicles.svg"
            message={t(T['cameras.no.data.message'])}
            retryText={'Back'}
            onRetry={null}
          />
        </CenterBox>
      )}
    </>
  );
}
