import { useRouteQuery } from '@/hooks/useRouteQuery';
import { BoxImage } from '@/web/@components/BoxImage';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { VIDEO_MINUTES_CLIENT_ITEMS } from '../../assets';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 6,
    'marginTop': theme.spacing(1),
    'minWidth': 180,
    'color': 'rgb(55, 65, 81)',
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export function CustomerSelectionMenu() {
  const { query, updateQuery } = useRouteQuery();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    handleClose();
    updateQuery({ client: item?.key });
  };

  useEffect(() => {
    const item = VIDEO_MINUTES_CLIENT_ITEMS?.find((i) => i?.key === query?.client);
    setSelectedItem(item || VIDEO_MINUTES_CLIENT_ITEMS[1]);
  }, [query?.client]);

  return (
    <div>
      <Button
        size="small"
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          open ? (
            <ArrowDropUp sx={{ fontSize: '25px !important' }} />
          ) : (
            <ArrowDropDown sx={{ fontSize: '25px !important' }} />
          )
        }
      >
        {selectedItem?.iconType === 'IMAGE' ? (
          <IconLabelItem
            src={selectedItem?.activeImage}
            title={selectedItem?.label}
            iconSize={selectedItem?.imageSize ?? '20px'}
          />
        ) : (
          <Box display="inline-flex" alignItems="center" gap={1}>
            {selectedItem?.activeIcon}
            <Typography fontSize={'.90rem'}>{selectedItem?.label}</Typography>
          </Box>
        )}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {VIDEO_MINUTES_CLIENT_ITEMS?.map((item, index) => (
          <MenuItem onClick={() => handleSelect(item)} disableRipple key={item?.key}>
            {item?.iconType === 'IMAGE' ? (
              <BoxImage src={item?.img} size={item?.imageSize ?? '20px'} />
            ) : (
              item?.icon
            )}
            <Typography fontSize={'.90rem'} paddingLeft={item?.iconType === 'IMAGE' ? '8px' : 0}>
              {item?.label}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
