import {
  CalendarMonthOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { subMonths } from 'date-fns';
import { useState } from 'react';

const MONTHS_NAMES = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

const VIEW_YEARS = 'YEARS';
const VIEW_MONTHS = 'MONTHS';

const YearMonthPicker = ({
  onChange,
  currentYearMonth = true,
  defaultYear = new Date().getFullYear(),
  defaultMonth = new Date().getMonth() + 1,
  minYear = 2000,
  maxYear = new Date().getFullYear(),
  closeOnSelect = true,
}) => {
  const currentTime = new Date();
  const startYear = currentYearMonth ? currentTime.getFullYear() : defaultYear;
  const startMonth = currentYearMonth
    ? Object.keys(MONTHS_NAMES)[currentTime.getMonth()]
    : Object.keys(MONTHS_NAMES)[defaultMonth - 1];

  const [years, setYears] = useState(Array.from({ length: 12 }, (_v, k) => k + startYear));
  const [selectedYear, setSelectedYear] = useState(startYear);
  const [selectedMonth, setSelectedMonth] = useState(startMonth);
  const [currentView, setCurrentView] = useState(startYear ? VIEW_MONTHS : VIEW_YEARS);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (selectedMonth, selectedYear) => {
    let monthIndex = Object.keys(MONTHS_NAMES).findIndex((m) => m === selectedMonth);
    onChange({ month: selectedMonth, year: selectedYear, monthInt: monthIndex + 1 });
  };

  const selectMonthYear = (month, year, shouldClose = true) => {
    setSelectedMonth(month);
    setSelectedYear(year);
    handleChange(month, year);
    if (closeOnSelect && shouldClose) {
      handleClose();
    }
  };

  const updateYears = (startYear) => {
    const newYears = Array.from({ length: 12 }, (_v, k) => k + startYear);
    setYears(newYears);
  };

  const previous = () => {
    let month = selectedMonth;
    let year = selectedYear;
    if (currentView === VIEW_YEARS) {
      if (selectedYear > minYear) {
        if (selectedYear === years[0]) {
          updateYears(years[0] - 12);
        }
        year = selectedYear - 1;
      }
    } else if (currentView === VIEW_MONTHS) {
      let monthIndex = Object.keys(MONTHS_NAMES).findIndex((m) => m === selectedMonth);
      if (monthIndex === 0) {
        monthIndex = 11;
        if (selectedYear > minYear) {
          if (selectedYear === years[0]) {
            updateYears(years[0] - 12);
          }
          year = selectedYear - 1;
        }
      } else {
        monthIndex = monthIndex - 1;
      }
      month = Object.keys(MONTHS_NAMES)[monthIndex];
    }

    setSelectedMonth(month);
    setSelectedYear(year);
    handleChange(month, year);
  };

  const next = () => {
    let month = selectedMonth;
    let year = selectedYear;
    if (currentView === VIEW_YEARS) {
      if (selectedYear < maxYear) {
        if (selectedYear === years[11]) {
          updateYears(years[11] + 1);
        }
        year = selectedYear + 1;
      }
    } else if (currentView === VIEW_MONTHS) {
      let monthIndex = Object.keys(MONTHS_NAMES).findIndex((m) => m === selectedMonth);
      if (monthIndex === 11) {
        monthIndex = 0;
        if (selectedYear < maxYear) {
          if (selectedYear === years[11]) {
            updateYears(years[11] + 1);
          }
          year = selectedYear + 1;
        }
      } else {
        monthIndex = monthIndex + 1;
      }
      month = Object.keys(MONTHS_NAMES)[monthIndex];
    }
    // Update state with new values
    setSelectedMonth(month);
    setSelectedYear(year);

    // Notify changes
    handleChange(month, year);
  };

  const handleThisMonth = () => {
    const now = new Date();
    const month = Object.keys(MONTHS_NAMES)[now.getMonth()];
    const year = now.getFullYear();
    selectMonthYear(month, year);
  };

  const handleLastMonth = () => {
    const lastMonthDate = subMonths(new Date(), 1);
    const month = Object.keys(MONTHS_NAMES)[lastMonthDate.getMonth()];
    const year = lastMonthDate.getFullYear();
    selectMonthYear(month, year);
  };

  const renderMonths = () => {
    const now = new Date();
    const currentMonthIndex = now.getMonth();
    const currentYear = now.getFullYear();

    return Object.keys(MONTHS_NAMES).map((month, i) => {
      const isDisabled =
        selectedYear > currentYear || (selectedYear === currentYear && i > currentMonthIndex);
      return (
        <Grid item xs={4} key={i}>
          <Button
            variant={selectedMonth === month ? 'contained' : 'text'}
            size="small"
            onClick={() => selectMonthYear(month, selectedYear)}
            fullWidth
            disabled={isDisabled}
          >
            <Typography fontSize="12px"> {month}</Typography>
          </Button>
        </Grid>
      );
    });
  };

  const renderYears = () => {
    return years.map((year, i) => (
      <Grid item xs={4} key={i}>
        <Button
          variant={selectedYear === year ? 'contained' : 'outlined'}
          onClick={() => {
            if (year >= minYear && year <= maxYear) {
              selectMonthYear(selectedMonth, year, false);
              setCurrentView(VIEW_MONTHS);
            }
          }}
          fullWidth
          disabled={year < minYear || year > maxYear}
        >
          <Typography fontSize="12px"> {year}</Typography>
        </Button>
      </Grid>
    ));
  };

  const renderHead = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const isFutureMonth =
      selectedYear > currentYear ||
      (selectedYear === currentYear &&
        Object.keys(MONTHS_NAMES).indexOf(selectedMonth) > currentMonthIndex);

    const isCurrentMonth =
      selectedYear === currentYear &&
      Object.keys(MONTHS_NAMES).indexOf(selectedMonth) === currentMonthIndex;

    return (
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button
          onClick={() => previous()}
          disabled={currentView === VIEW_YEARS && selectedYear <= minYear}
          aria-label="Previous"
          sx={{ minWidth: '10px' }}
        >
          <KeyboardArrowLeftOutlined />
        </Button>
        <Button onClick={() => setCurrentView(VIEW_YEARS)}>
          <Typography fontSize={'13px'}> {`${selectedMonth} ${selectedYear}`}</Typography>
        </Button>
        <Button
          onClick={() => next()}
          disabled={
            isFutureMonth ||
            isCurrentMonth ||
            (currentView === VIEW_YEARS && selectedYear >= maxYear)
          }
          aria-label="Next"
          sx={{ minWidth: '10px' }}
        >
          <KeyboardArrowRightOutlined />
        </Button>
      </Box>
    );
  };

  return (
    <Box
      width={{
        xs: '100%',
        md: '165px',
      }}
    >
      <TextField
        size="small"
        sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '13px' } }}
        label=""
        value={`${MONTHS_NAMES[selectedMonth]}, ${selectedYear}`}
        onClick={handleOpen}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarMonthOutlined sx={{ fontSize: '18px' }} />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ '& .MuiPaper-root': { width: { xs: '100%', md: '250px' } } }}
      >
        <Box p={2}>
          {renderHead()}
          <Grid container spacing={2.5}>
            {currentView === VIEW_YEARS ? renderYears() : renderMonths()}
          </Grid>
        </Box>

        <Divider />
        <Grid container spacing={2} p={1}>
          <Grid item xs={6}>
            <Button variant="text" fullWidth onClick={handleLastMonth} size="small">
              <Typography fontSize={'13px'}> Last Month</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="text" fullWidth onClick={handleThisMonth} size="small">
              <Typography fontSize={'13px'}> This Month</Typography>
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
};

export default YearMonthPicker;
