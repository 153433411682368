import api from '@/api';
import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import {
  findTenant,
  selectMainAccessToken,
  selectMainRefreshToken,
  selectMainTenantId,
  selectRefreshToken,
  selectSecretToken,
  selectSupportAccessToken,
  selectSupportRefreshToken,
  selectSupportTenantId,
  selectTenant,
  selectTenantId,
  selectUserEmail,
} from '@/store/auth';
import { PageView } from '@/store/page-view';
import { SmartCache } from '@/utils/caching/smart-cache';
import { formatTriggerName } from '@/utils/events';
import { CustomLogger } from '@/utils/logger';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { PlayerEventList } from '@/web/@components/PlayerEventList';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const logger = new CustomLogger('EventsPage');

/** @type {SmartCache<{endpointId: number, tenantId: string, triggerName: string}>} */
const eventCache = new SmartCache('event-player-info-cache', 365 * 24 * 3600 * 1000);

export function EventPlayPage() {
  const { query } = useRouteQuery();

  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const isSmarterAiItem = useMemo(
    () => window.location.pathname?.split('/')?.at(1) === 'smarter-ai',
    []
  );

  const navigate = useNavigate();
  const player = useDashcamPlayer('dashcam-player', isSmarterAiItem);
  const currentTenantId = useSelector(selectTenantId);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<number>} */
  const [endpointId, setEndpointId] = useState(null);
  /** @type {StateVariable<string>} */
  const [tenantId, setTenantId] = useState(null);
  /** @type {StateVariable<string>} */
  const [triggerName, setTriggerName] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [support, setSupport] = useState(false);
  /** @type {StateVariable<string>} */
  const [accessToken, setAccessToken] = useState(null);
  /** @type {StateVariable<string>} */
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    if (!query.id) {
      navigate(`/cameras`);
      return;
    }
    (async () => {
      try {
        const eventId = query.id + '';
        let cache = await eventCache.getItem(eventId);
        if (!cache?.endpointId) {
          const state = store.getState();
          // @ts-ignore TODO: fix query parameter type
          const request = api.ac.v2['event-messaging'].events.$eventId(eventId).$get({
            params: {
              secretToken: selectMainAccessToken(state),
            },
          });
          const result = await request.process();
          const tenantId = result.event.tenantId;
          const endpointId = result.event.endpointId;
          const triggerName = result.event.triggerName;
          cache = { tenantId, endpointId, triggerName };
          eventCache.setItem(eventId, cache);
        }
        setTenantId(cache.tenantId);
        setEndpointId(cache.endpointId);
        setTriggerName(cache.triggerName);
      } catch (err) {
        logger.error('Failed to get event details', err);
        navigate(`/cameras`);
      }
    })();
  }, [navigate, query.id]);

  // automatically switch tenant
  useEffect(() => {
    if (!tenantId) return;
    (async function () {
      try {
        const tenant = findTenant(tenantId);
        if (tenant?.tenantId !== tenantId) {
          logger.error('Tenant check failed with id', tenantId);
          navigate(`/cameras`);
          return;
        }

        setSupport(tenant.path?.length > 1);
        console.info(
          'Event belongs to %c%s%c',
          'font-weight:bold',
          tenant.tenantName,
          'font-weight:normal',
          tenant
        );

        const state = store.getState();
        if (selectTenantId(state) === tenantId) {
          setAccessToken(selectSecretToken(state));
          setRefreshToken(selectRefreshToken(state));
          return;
        }

        store
          .dispatch(selectTenant({ tenantId: tenant.tenantId, support: tenant.path }))
          .catch(console.error);

        if (selectMainTenantId(state) === tenant.tenantId) {
          setAccessToken(selectMainAccessToken(state));
          setRefreshToken(selectMainRefreshToken(state));
          return;
        }
        if (selectSupportTenantId(state) === tenant.tenantId) {
          setAccessToken(selectSupportAccessToken(state));
          setRefreshToken(selectSupportRefreshToken(state));
          return;
        }

        let token = selectMainAccessToken(state);
        if (tenant.path?.length > 1 && tenant.path[0] !== selectMainTenantId(state)) {
          const request = api.ac.v5.auth['tenant-access'].$post({
            data: {
              tenantId: tenant.path[0],
              email: selectUserEmail(state),
              token: selectMainAccessToken(state),
            },
          });
          const result = await request.process();
          token = result.accessToken;
        }
        const request = api.ac.v5.auth['tenant-access'].$post({
          data: {
            token,
            tenantId: tenant.tenantId,
            email: selectUserEmail(state),
            virtualLogin: tenant.path?.length > 1,
          },
        });
        const result = await request.process();
        setAccessToken(result.accessToken);
        setRefreshToken(result.refreshToken);
      } catch (err) {
        logger.error('Tenant check failed for the event', err);
        navigate(`/cameras`);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigate, tenantId]);

  useEffect(() => {
    if (!triggerName) return;
    setBreadcrumbTitle(formatTriggerName(triggerName));
  }, [triggerName, setBreadcrumbTitle]);

  useEffect(() => {
    if (!player || loading) return;
    if (support) {
      player.setAttribute('support', 'on');
    } else {
      player.removeAttribute('support');
    }
  }, [player, loading, support]);

  useEffect(() => {
    if (!player || loading) return;
    player.setAttribute('tenant-id', tenantId);
  }, [player, loading, tenantId]);

  useEffect(() => {
    if (!player || loading) return;
    player.setAttribute('refresh-token', refreshToken);
  }, [player, loading, refreshToken]);

  useEffect(() => {
    if (!player || loading) return;
    player.setAttribute('access-token', accessToken);
  }, [player, loading, accessToken]);

  useEffect(() => {
    if (!player || loading) return;
    player.setAttribute('event-id', query.id + '');
  }, [player, loading, query.id]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('show-coachable-action', 'on');
    isSmarterAiItem && player.setAttribute('show-trigger-report', 'on');
    !isSmarterAiItem && player.setAttribute('show-feedback-action', 'on');
  }, [player, isSmarterAiItem]);

  useEffect(() => {
    if (!player) return;
    const time = Math.floor(Number(query.time));
    if (time) {
      player.setAttribute('time', time + '');
    } else {
      player.setAttribute('time', null);
    }
  }, [player, query.time]);

  // display on view
  useEffect(() => {
    if (!player || loading || !refreshToken || !accessToken) return;
    parentRef?.current?.replaceChildren(player);
    store.dispatch(PageView.setHideToolbar(true));
    store.dispatch(PageView.setFullWidthLayout(true));
  }, [loading, refreshToken, accessToken, player]);

  useEffect(() => {
    if (loading || !tenantId) return;
    const tid = setTimeout(() => {
      if (currentTenantId !== tenantId) {
        navigate(`/cameras`);
      }
    }, 1000);
    return () => clearTimeout(tid);
  }, [navigate, loading, tenantId, currentTenantId]);

  return (
    <>
      {!loading && player && accessToken && (
        <HubConnectionProvider>
          <PlayerEventList
            player={player}
            cameraId={endpointId}
            tenantId={tenantId}
            secretToken={accessToken}
          />
        </HubConnectionProvider>
      )}
      <Box ref={parentRef} width="100%" height="100%" p={1}>
        <CenterBox>
          <CircularProgress />
          <Typography variant="subtitle2" fontSize="12px" mt={1}>
            Verifying access
          </Typography>
        </CenterBox>
      </Box>
    </>
  );
}
