import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isAfter,
  isSameDay,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { isEqual, orderBy, sortBy } from 'lodash';
import { formatSecondsToMinutes } from '@/web/smarter-ai/video-minutes/@utils/date';

export const calculateDailySubscriptionData = (data) => {
  const { initialSubscription, finalSubscription, activations, expirations } = data;
  const result = [];
  const startDate = startOfMonth(new Date());
  const endDate = endOfMonth(new Date());

  // Helper function to format date to day of the month
  const formatDay = (date) => format(date, 'd');

  // Create an array of all days in the current month with initial subscription values
  const days = eachDayOfInterval({ start: startDate, end: endDate });
  days.forEach((day) => {
    const dayKey = parseInt(formatDay(day), 10);
    result.push({ date: dayKey, value: initialSubscription });
  });

  // Function to update the result array based on activation or expiration dates
  const updateValues = (date, quantity, isActivation) => {
    const day = parseISO(date);
    result.forEach((entry) => {
      if (entry.date >= formatDay(day)) {
        entry.value += isActivation ? quantity : -quantity;
      }
    });
  };

  // Process activations
  activations.forEach((activation) => {
    updateValues(activation.activatedAt, activation.quantity, true);
  });

  // Process expirations
  expirations.forEach((expiration) => {
    updateValues(expiration.activatedAt, expiration.quantity, false);
  });

  // Ensure the final subscription value for each day
  result.forEach((entry) => {
    if (entry.value > finalSubscription) {
      entry.value = finalSubscription;
    }
  });

  // Sort results by date
  result.sort((a, b) => a.date - b.date);
  return result;
};

const convertDurationToMinutes = (data) => {
  const mappedData = data.map((item) => ({
    ...item,
    durationMinutes: item.durationSeconds / 60,
  }));
  return sortBy(mappedData, 'durationMinutes')?.reverse();
};

export function processVideoMinutesChartData(
  data,
  estimateData,
  selectedYear,
  selectedMonth,
  client
) {
  const monthIndex = parseInt(selectedMonth, 10) - 1;
  const start = startOfMonth(new Date(selectedYear, monthIndex));
  const end = endOfMonth(new Date(selectedYear, monthIndex));
  const allDays = eachDayOfInterval({ start, end });

  const isVisionTeam = client === 'vision_team';

  const minutes = allDays.map((day) => {
    const dayTimestamp = day.getTime();
    const dayName = format(day, 'EEEE');
    const dayData = data.find((item) => isSameDay(startOfDay(item?.timestamp), day));

    const videoMinutesUsagesFilteredData = !isVisionTeam
      ? dayData?.videoMinuteUsages
      : dayData?.videoMinuteUsages?.filter((item) => item?.type?.includes('SHADOW'));

    const videoMinuteUsages = dayData
      ? convertDurationToMinutes(videoMinutesUsagesFilteredData)
      : [];

    const totalDurationSeconds = dayData
      ? videoMinutesUsagesFilteredData.reduce((sum, usage) => sum + (usage.durationSeconds || 0), 0)
      : 0;

    const isFutureDate = day > new Date();
    const predictedDuration = isFutureDate
      ? estimateData?.[dayName?.toUpperCase()] || 0
      : totalDurationSeconds;

    return {
      timestamp: dayTimestamp,
      totalDurationMinutes: formatSecondsToMinutes(predictedDuration) || 0,
      videoMinuteUsages: videoMinuteUsages || [],
      predicted: isFutureDate,
    };
  });

  const maxDuration = minutes.reduce(
    (max, item) => (item.totalDurationMinutes > max ? item.totalDurationMinutes : max),
    0
  );

  return {
    minutes,
    maxDuration,
  };
}

export function formatKeyValueEstimateData(data, selectedClient) {
  const result = data.reduce((acc, item) => {
    acc[item.dayOfTheWeek] = selectedClient?.includeShadowUse
      ? item?.estimatedShadowUse
      : item.estimatedUse;
    return acc;
  }, {});
  return result;
}

export function checkIsCurrentOrFuture(year, month) {
  const selectedDate = startOfMonth(parse(`${year}-${month}`, 'yyyy-M', new Date()));
  const currentDate = new Date();
  const startOfCurrentMonth = startOfMonth(currentDate);
  return isAfter(selectedDate, currentDate) || isEqual(selectedDate, startOfCurrentMonth);
}

export const getTotalVideoMinuteUsagesByType = (videoMinuteUsages, isShadow) => {
  const totalDuration = videoMinuteUsages?.reduce((total, item) => {
    if (isShadow) {
      return item.type.includes('SHADOW') ? total + item.durationSeconds : total;
    }
    return total + item.durationSeconds;
  }, 0);
  return totalDuration;
};

/**
 * @typedef {object} Trigger
 * @property {string} name - The name of the trigger.
 * @property {string} id - The ID of the trigger (same as the name).
 * @property {number} parentEndpointId - The ID of the parent endpoint.
 * @property {string} type - The type of the object, always set to 'TRIGGER'.
 */

/**
 * @typedef {object} TriggerVideoMinute
 * @property {string} triggerId - Trigger ID.
 * @property {string} triggerName - Trigger name.
 * @property {Array<{type: string, durationSeconds: number}>} videoMinuteUsages
 */

/**
 * Transforms endpoint usages data into two arrays: one for triggers and another for video minute usages.
 * @param {EndpointVideoMinuteUsagesByUsagesName} [data]
 * @returns {object}
 */
export const transformEndpointUsagesData = (data) => {
  const allowedTypes = [
    'LIVE_STREAMING',
    'EVENT_PLAYBACK',
    'EVENT_UPLOAD',
    'SHADOW_EVENT_PLAYBACK',
    'SHADOW_EVENT_UPLOAD',
    'RECORDING_PLAYBACK',
    'RECORDING_UPLOAD',
  ];

  const triggersArray = Object.keys(data.usagesByName)
    .filter((key) => !allowedTypes.includes(key))
    .map((key) => ({
      name: key,
      id: key,
      parentEndpointId: data.endpointId,
      type: 'TRIGGER',
    }));

  const triggerVideoMinutes = Object.entries(data.usagesByName).map(([triggerName, usages]) => {
    return {
      triggerId: triggerName,
      triggerName: triggerName,
      videoMinuteUsages: usages,
    };
  });

  return {
    triggersArray: orderBy(triggersArray, ['name'], ['asc']),
    triggerVideoMinutes: triggerVideoMinutes,
  };
};
