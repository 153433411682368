import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import {
  SetVideoMinutesFilterContext,
  VideoMinutesFilterContext,
} from '../../@context/VideoMinutesFilterContext';

export function VideoMinutesBreakDownFilteringArea({ textView }) {
  const setVideoMinutesFilter = useContext(SetVideoMinutesFilterContext);
  const { searchedCameras, minutesEndpoints } = useContext(VideoMinutesFilterContext);

  const cameraNames = useMemo(
    () =>
      sortedUniq(
        (minutesEndpoints || [])
          .map((x) => x.endpointName || 'Smarter AI Dashcam')
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [minutesEndpoints]
  );

  if (!cameraNames.length) return null;

  return (
    <PageFilteringArea
      value={{ searchedCameras }}
      textView={textView}
      onChange={(data) => {
        setVideoMinutesFilter({
          searchedCameras: data.searchedCameras,
        });
      }}
      filters={[
        {
          type: 'SELECT',
          key: 'searchedCameras',
          title: 'Camera',
          submenu: cameraNames,
          hidden: !cameraNames.length,
        },
      ]}
    />
  );
}
