export function findTenantById(data, id) {
  for (const item of data) {
    // Check if the current item's tenantId matches the provided id
    if (item.tenantId === id) {
      return item; // Return the item if a match is found
    }

    // If there are descendant tenants, search in the descendantTenantList
    if (item.descendantTenantList && item.descendantTenantList.length > 0) {
      const foundInChildren = findTenantById(item.descendantTenantList, id);
      if (foundInChildren) {
        return foundInChildren; // Return if found in children
      }
    }
  }
  return null; // Return null if not found
}
