import api from '@/api';
import { AI_MODEL_ICON_ON } from '@/assets/constants/images';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken, selectTeamList, selectUserId } from '@/store/auth';
import { sendMessageToDevice } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  ArrowDropDown,
  MoreVert,
  PowerSettingsNew,
  RestartAlt,
  SdCard,
  Settings,
  Terminal,
  UploadFile,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONTROL_ACTION_TYPES } from '../../@assets/controls';
import { CameraConfigurationForm } from '../../@components/CameraConfigurationForm';
import { CameraEnvironmentModal } from '../../@components/CameraEnvironmentModal';
import { CameraTerminalModal } from '../../@components/CameraTerminalModal';
import { CameraUploadDbcModal } from '../../@components/CameraUploadDbcModal';
import { GroupDeployedModels } from '../../@components/GroupDeployedModels';

export function CameraServerConfigurationPage() {
  const { cameraId, camera, capabilities } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const loggedUserId = useSelector(selectUserId);
  const teamList = useSelector(selectTeamList);

  const [anchorEl, setAnchorEl] = useState(null);

  const [configurations, setConfigurations] = useState(null);
  const [showTerminal, setShowTerminal] = useState(false);
  const [showAiModels, setShowAiModels] = useState(false);
  const [showEnvModal, setShowEnvModal] = useState(false);
  const [showDbcModal, setShowDbcModal] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRebootDevice = async () => {
    try {
      if (!camera?.deviceSerialNumber) return;
      const request = api.ac.v5.device
        .$deviceSerialNumber(camera?.deviceSerialNumber)
        .reboot.$post({
          headers: {
            Authorization: secretToken,
          },
        });

      request
        ?.process()
        ?.then(() => {
          toastSuccess(`The Reboot signal has been sent to ${camera.label}`);
        })
        .catch((exp) => {
          toastWarning('Could not send reboot signal');
        });
      handleMenuClose();
    } catch (err) {
      toastWarning('Could not send reboot signal');
    }
  };

  const handleFormatSDCard = () => {
    try {
      //sendEventsToDevices([cameraId], MESSAGE_TYPE.FORMAT_SD_CARD);
      const request = sendMessageToDevice(cameraId, MESSAGE_TYPE.FORMAT_SD_CARD);
      request
        ?.then(() => {
          toastSuccess(`The SD card format signal has been sent to ${camera.label}`);
        })
        .catch((exp) => {
          toastWarning('Could not send SD card format signal');
        });

      handleMenuClose();
    } catch (err) {
      toastWarning('Could not send SD card format signal');
    }
  };

  const handleResetCamera = async () => {
    try {
      const request = api.ac.v1.endpoint.reset.$delete({
        params: {
          secretToken,
          endpointId: cameraId,
        },
      });
      request
        .process()
        .then((data) => {
          toastSuccess(`${camera.label} Reset Successfully`);
        })
        .catch((err) => {
          toastWarning(`Failed to reset ${camera.label}`);
        });
    } catch (error) {
      toastWarning(`Failed to reset ${camera.label}`);
    }
  };

  const handleSetEnvironment = async (url) => {
    try {
      const request = sendMessageToDevice(cameraId, MESSAGE_TYPE.SET_ENVIRONMENT, url);
      // const request = sendEventsToDevices([cameraId], MESSAGE_TYPE.SET_ENVIRONMENT, url);
      request
        ?.then(() => {
          toastSuccess(`The set environment signal has been sent to ${camera.label}`);
        })
        .catch((exp) => {
          toastWarning('Failed to send set environment signal');
        });
      handleMenuClose();
    } catch (err) {
      toastWarning('Failed to send set environment signal');
    } finally {
      setShowEnvModal(false);
    }
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setDialogType(null);
  };

  const handleControlExecution = async () => {
    if (dialogType === CONTROL_ACTION_TYPES.REBOOT) {
      await handleRebootDevice();
    } else if (dialogType === CONTROL_ACTION_TYPES.SD_CARD_FORMAT) {
      await handleFormatSDCard();
    } else if (dialogType === CONTROL_ACTION_TYPES.RESET) {
      await handleResetCamera();
    }

    setDialogTitle('');
    setDialogMessage('');
    setDialogType(null);
    setShowDialog(false);
  };

  const handleModelReCalibrate = (model) => {
    const eventText = `modelId=${model.modelId}`;
    //sendEventsToDevices([cameraId], MESSAGE_TYPE.DEVICE_CALIBRATE, eventText);
    sendMessageToDevice(cameraId, MESSAGE_TYPE.DEVICE_CALIBRATE, eventText);
    setShowAiModels(false);
  };

  useEffect(() => {
    if (!cameraId) return;
    const request = api.ac.endpoint.serverconfig.$get({
      params: {
        endpointID: cameraId,
        secretToken,
      },
    });
    request.process().then((result) => {
      setConfigurations(result);
    });
    return () => request.abort();
  }, [secretToken, cameraId]);

  const hasResetActionAccess = useMemo(() => {
    return (teamList || [])?.includes('HIGHER_MANAGEMENT') || camera.userId === loggedUserId;
  }, [teamList, camera, loggedUserId]);

  return (
    <>
      {showTerminal && (
        <CameraTerminalModal
          open={true}
          camera={camera}
          cameraId={cameraId}
          onCancel={() => setShowTerminal(false)}
        />
      )}

      {showAiModels && (
        <GroupDeployedModels
          groupId={camera?.groupId}
          productId={camera?.productId}
          onCancel={() => setShowAiModels(false)}
          onSubmit={(model) => handleModelReCalibrate(model)}
        />
      )}

      {showEnvModal && (
        <CameraEnvironmentModal
          endpointName={camera?.label}
          baseUrl={capabilities?.baseURL}
          onCancel={() => setShowEnvModal(false)}
          onSubmit={(url) => handleSetEnvironment(url)}
        />
      )}

      {showDbcModal && (
        <CameraUploadDbcModal cameraId={cameraId} onCancel={() => setShowDbcModal(false)} />
      )}

      <ConfirmationDialog
        open={showDialog}
        title={dialogTitle}
        message={dialogMessage}
        onCancel={handleDialogClose}
        onApply={handleControlExecution}
      />

      <Box
        p={2.5}
        px={3}
        mt={2}
        sx={{
          '& .MuiPaper-elevation': {
            border: '1px solid #c5d9f0',
            borderRadius: '5px',
          },
          'overflowY': 'auto',
        }}
      >
        <Box display="flex" justifyContent="space-between" mb="20px" alignItems="center">
          <Typography variant="body1">Controls</Typography>
          <Box>
            <Button
              size="small"
              variant="outlined"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<MoreVert />}
            >
              Signals
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setShowTerminal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <Terminal fontSize="small" htmlColor="#608ac3" />
                </ListItemIcon>
                <Typography variant="subtitle1">Terminal</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowEnvModal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" htmlColor="#608ac3" />
                </ListItemIcon>
                <Typography variant="subtitle1">Set Environment</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowAiModels(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <BoxImage src={AI_MODEL_ICON_ON} size={18} sx={{ ml: '1px' }} />
                </ListItemIcon>
                <Typography variant="subtitle1">Recalibrate AI Model</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowDbcModal(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <UploadFile fontSize="small" htmlColor="#608ac3" />
                </ListItemIcon>
                <Typography variant="subtitle1">Update DBC File</Typography>
              </MenuItem>

              <Divider sx={{ p: 0 }} />

              {hasResetActionAccess && (
                <MenuItem
                  onClick={() => {
                    setDialogTitle('Reset Camera');
                    setDialogMessage(`Are you sure you want to reset ${camera.label}?`);
                    setDialogType(CONTROL_ACTION_TYPES.RESET);
                    setShowDialog(true);
                  }}
                >
                  <ListItemIcon>
                    <RestartAlt fontSize="medium" htmlColor="#e32828" />
                  </ListItemIcon>
                  <Typography variant="subtitle1">Reset</Typography>
                </MenuItem>
              )}

              <MenuItem
                onClick={async () => {
                  setDialogTitle('Reboot');
                  setDialogMessage(`Are you sure you want to reboot ${camera.label}?`);
                  setDialogType(CONTROL_ACTION_TYPES.REBOOT);
                  setShowDialog(true);
                }}
              >
                <ListItemIcon>
                  <PowerSettingsNew htmlColor="#e32828" sx={{ fontSize: '21px' }} />
                </ListItemIcon>
                <Typography variant="subtitle1">Reboot</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setDialogTitle('Format SD Card');
                  setDialogMessage(`Are you sure you want to format SD card for ${camera.label}?`);
                  setDialogType(CONTROL_ACTION_TYPES.SD_CARD_FORMAT);
                  setShowDialog(true);
                }}
              >
                <ListItemIcon>
                  <SdCard fontSize="small" htmlColor="#e32828" />
                </ListItemIcon>
                <Typography variant="subtitle1">Format SD card</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Accordion
          key={'controls'}
          disableGutters
          elevation={0}
          square
          TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDown fontSize="large" />}
            aria-controls="controls-content"
            id="controls-header"
          >
            <Typography>Media Server Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" alignItems="center" columnGap="5px" pt={1}>
              <Typography variant="subtitle2">Configuration Type:</Typography>
              <Typography variant="subtitle1">
                {configurations?.isStatic ? 'Static' : 'Dynamic'}
              </Typography>
            </Box>
            <CameraConfigurationForm configurations={configurations} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
