import { T } from '@/assets/locales';
import { store } from '@/store';
import { doLogout, selectTenant, selectTenantList } from '@/store/auth';
import { reportEvent, SentryEvents } from '@/utils/sentry';
import { toastWarning } from '@/utils/toaster';
import { CheckCircle, Circle } from '@mui/icons-material';
import { Box, Button, Divider, Grid } from '@mui/material';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SearchField } from '../../@components/SearchField';
import { BackButtonContainer } from '../@components/BackButtonContainer';

export function TenantsPage() {
  const { t } = useTranslation();
  const tenantList = useSelector(selectTenantList);

  const [filter, setFilter] = useState(null);
  const [selectedTenantId, setSelectedTenantId] = useState(null);

  useEffect(() => {
    if (tenantList.length === 1) {
      store.dispatch(selectTenant({ tenantId: tenantList[0].tenantId }));
    }
  }, [tenantList]);

  /** @param {string} searchText */
  const filteredTenantList = useMemo(() => {
    let list = tenantList;
    if (filter) {
      list = tenantList.filter((tenant) => tenant.tenantName?.toLowerCase().includes(filter));
    }
    return sortBy(list, 'tenantName');
  }, [tenantList, filter]);

  const handleTenantSelection = (tenant) => {
    setSelectedTenantId(tenant.tenantId);
  };

  const handleBackButton = () => {
    store.dispatch(doLogout());
  };

  const handleTenantLogin = async () => {
    if (!selectedTenantId) return;
    try {
      await store.dispatch(selectTenant({ tenantId: selectedTenantId }));
    } catch (err) {
      console.error('Tenant login failed', err);
      toastWarning('Failed to login with this tenant');
      reportEvent(SentryEvents.TENANT_LOGIN_FAILED, '', {
        err,
        tenantId: selectedTenantId,
        tags: { tenantId: selectedTenantId },
      });
    }
  };

  return (
    <BackButtonContainer onClick={handleBackButton}>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={12} md={6}>
          <Box my={1} fontWeight={500} textAlign={{ xs: 'center', md: 'left' }}>
            {t(T['tenants.label.select'])}
          </Box>
        </Grid>

        {tenantList.length > 8 && (
          <Grid item xs={12} md={6}>
            <SearchField
              clearable
              ignoreCase
              onSearch={setFilter}
              placeholder={t(T['tenants.placeholder.search'])}
              sx={{
                borderColor: 'transparent',
                borderRadius: 10,
                background: '#f7f7f7',
              }}
            />
          </Grid>
        )}
      </Grid>

      {/* <Divider /> */}
      <Box minHeight="200px" height="calc(100vh - 425px)" overflow="hidden auto">
        {(filteredTenantList || []).map((tenant, index) => (
          <Box key={index}>
            {index > 0 && <Divider />}
            <Button
              onClick={() => handleTenantSelection(tenant)}
              sx={{
                'width': '100%',
                'display': 'flex',
                'fontWeight': '500',
                'color': '#011e42',
                'justifyContent': 'space-between',
                '& .checkbox': {
                  padding: '1px',
                  fontSize: '1.375rem',
                  borderRadius: '50%',
                  background: '#ffffff',
                  border: '1px solid #cfd8e5',
                },
              }}
            >
              {tenant.tenantName}
              {selectedTenantId === tenant.tenantId ? (
                <CheckCircle htmlColor="#596A81" className="checkbox" />
              ) : (
                <Circle htmlColor="#CFD8E5" className="checkbox" />
              )}
            </Button>
          </Box>
        ))}
      </Box>
      {/* <Divider /> */}

      <Box mt={1} mb="35px">
        <Button
          disableElevation
          disabled={!selectedTenantId}
          variant="contained"
          onClick={handleTenantLogin}
          fullWidth
          sx={{
            background: '#596A81',
            textTransform: 'capitalize',
            fontSize: '1rem',
          }}
        >
          {t(T['login.button'])}
        </Button>
      </Box>
    </BackButtonContainer>
  );
}
