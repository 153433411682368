import storage from 'redux-persist/lib/storage';

/** @type {Array<keyof import('./slice').InitialState>} */
const whitelist = [
  // add state items you want to persist in local storage
  'sidebarCollapsed',
  'simplifyCurve',
];

/** @type {import('redux-persist').PersistConfig<PageViewState>} */
export const pageViewPersistConfig = {
  key: 'page-view',
  version: 3,
  storage,
  whitelist,
  migrate: async (state, version) => {
    if (state?._persist?.version !== version) return null;
    return state;
  },
};
