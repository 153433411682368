import { isDev, isProduction } from '@/config';
import { selectUserRole } from '@/store/auth';
import { PageLink } from '@/web/@components/PageLink';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { SERVER_ENVIRONMENTS } from '@/web/administration/device-groups/_id/cameras/manage-cameras/@assets/environments';
import { Box, Grid, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EditGroupModal } from '../EditGroupModal';
import { EditLicensePlate } from '../EditLicensePlate';
import { EditVinModalButton } from '../EditVinModal';
import { CameraDetailsTitle } from './CameraDetailsTitle';

export function CameraDetailsArea() {
  const { camera, capabilities } = useContext(CameraDetailsContext);

  const userRole = useSelector(selectUserRole);

  const cameraEnvironment = useMemo(() => {
    if (!capabilities?.baseURL) return 'Production';
    return SERVER_ENVIRONMENTS?.find((item) => item.value === capabilities?.baseURL)?.label;
  }, [capabilities?.baseURL]);

  const isAppDevice = useMemo(() => camera?.type === 'APP', [camera?.type]);

  const isAdminAndUp = useMemo(
    () => (isDev ? true : userRole?.includes('ADMIN') || userRole?.includes('SUPPORT')),
    [userRole]
  );

  return (
    <Box borderRadius="20px" position="relative">
      <CameraDetailsTitle />

      <Grid container spacing={2.5} textAlign="left">
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="subtitle2" lineHeight="18px">
            Serial No.
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {camera.deviceSerialNumber || 'Not Available'}
          </Typography>
        </Grid>

        {!isAppDevice && (
          <>
            <Grid item xs={12} md={4} lg={3}>
              <Typography variant="subtitle2" lineHeight="18px">
                Product
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {camera?.productName || 'Not Available'}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              position="relative"
              sx={{ '&:hover .edit-group-button': { opacity: 1 } }}
            >
              <Typography variant="subtitle2" lineHeight="18px">
                Group
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {camera.groupId ? (
                  !isAdminAndUp ? (
                    camera.groupName || 'Not Available'
                  ) : (
                    <PageLink to={`/administration/device-groups/${camera.groupId}`}>
                      {camera.groupName || 'Not Available'}
                    </PageLink>
                  )
                ) : (
                  'Default-Group'
                )}
              </Typography>
              {isAdminAndUp && (
                <Box position="absolute" top="20px" right="10px">
                  <EditGroupModal
                    className="edit-group-button"
                    sx={{ opacity: 0, transition: 'opacity 0.3s ease-out' }}
                  />
                </Box>
              )}
            </Grid>

            {camera.vin && (
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                position="relative"
                sx={{ '&:hover .edit-vin-button': { opacity: 1 } }}
              >
                <Typography variant="subtitle2" lineHeight="18px">
                  VIN
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {camera.vin || 'Not Available'}
                </Typography>
                {isAdminAndUp && (
                  <Box position="absolute" top="20px" right="10px">
                    <EditVinModalButton
                      className="edit-vin-button"
                      sx={{ opacity: 0, transition: 'opacity 0.3s ease-out' }}
                    />
                  </Box>
                )}
              </Grid>
            )}

            {camera.licensePlate && (
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                position="relative"
                sx={{ '&:hover .edit-license-button': { opacity: 1 } }}
              >
                <Typography variant="subtitle2" lineHeight="18px">
                  License Plate
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {camera.licensePlate || 'Not Available'}
                </Typography>
                <Box position="absolute" top="20px" right="10px">
                  <EditLicensePlate
                    className="edit-license-button"
                    sx={{ opacity: 0, transition: 'opacity 0.3s ease-out' }}
                  />
                </Box>
              </Grid>
            )}

            {/* <Grid item xs={12} md={4} lg={3}>
              <Typography variant="subtitle2" lineHeight="18px">
                Driver
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {'Not Available'}
              </Typography>
            </Grid> */}

            {!isProduction && (
              <Grid item xs={12} md={4} lg={3}>
                <Typography variant="subtitle2" lineHeight="18px">
                  Environment
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                  {cameraEnvironment || 'Unknown'}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}
