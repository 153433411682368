import api from '@/api';
import {
  LIVE_TRACKING_CLUSTER_ICON,
  LIVE_TRACKING_OFFLINE_ICON,
  LIVE_TRACKING_ONLINE_ICON,
  LIVE_TRACKING_PARKED_ICON,
  LIVE_TRACKING_SINGLE_CLUSTER_ICON,
  NO_SEARCH_RESULT_IMG,
} from '@/assets/constants/images';
import { GOOGLE_MAP_ID } from '@/config';
import { useGoogleMap } from '@/hooks/useGoogleMap';
import { selectSecretToken } from '@/store/auth';
import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { rotateImage } from '@/utils/canvas';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { CustomLogger } from '@/utils/logger';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { LiveIcon } from '@/web/@components/LiveIcon';
import { MapTypeSwitchButton } from '@/web/@components/MapTypeSwitchButton';
import { DefaultRenderer, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const logger = new CustomLogger('LiveTracking');

const UPDATE_DELAY = 300;
const UPDATE_INTERVAL = 30 * 1000;

/** @param {{filters?: import('./FilteringArea').FilterValue}} props */
export function LiveTrackingMap(props) {
  const { filters } = props;

  const navigate = useNavigate();
  const secretToken = useSelector(selectSecretToken);

  /** @type {import('react').Ref<HTMLDivElement>} */
  const parent = useRef();
  /** @type {import('react').Ref<HTMLDivElement>} */
  const container = useRef();

  /** @type {StateVariable<google.maps.Map>} */
  const [map, setMap] = useState(null);
  /** @type {StateVariable<'satellite'|'roadmap'>} */
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  /** @type {StateVariable<number>} */
  const [zoom, setZoom] = useState(null);
  /** @type {StateVariable<number>} */
  const [sessionId, setSessionId] = useState(null);
  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [endpoints, setEndpoints] = useState(null);
  /** @type {StateVariable<{[key: string]: EndpointInfoAggregated}>} */
  const [previous, setPrevious] = useState({});
  /** @type {StateVariable<google.maps.InfoWindow>} */
  const [popup, setPopup] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [doFitBound, setDoFitBound] = useState(true);

  /***********************************************************
   *                     Data Collection                     *
   ***********************************************************/

  useEffect(() => {
    const request = api.ac.v5.fleet['start-tracking'].$post({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((r) => r.result)
      .then(setSessionId)
      .catch(logger.error);
  }, [secretToken]);

  useEffect(() => {
    if (!map || sessionId === null) return;
    async function update() {
      const bounds = map.getBounds();
      if (!bounds) return;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const request = api.ac.v5.fleet.tracking.$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          sessionID: sessionId,
          northeastlat: ne.lat(),
          northeastlon: ne.lng(),
          southwestlat: sw.lat(),
          southwestlon: sw.lng(),
        },
      });
      let endpoints = [];
      try {
        const result = await request.process();
        endpoints = result?.list || [];
      } catch (err) {
        logger.error(err);
      } finally {
        setEndpoints((last) => {
          if (last?.length) {
            setPrevious((prev) => {
              /** @type {{[key: string]: EndpointInfoAggregated}} */
              const next = { ...prev };
              for (const item of last) {
                next[item.endpointId] = item;
              }
              return next;
            });
          }
          return endpoints;
        });
      }
    }
    const tid = setTimeout(update, UPDATE_DELAY);
    const iid = setInterval(update, UPDATE_INTERVAL);
    return () => {
      clearTimeout(tid);
      clearInterval(iid);
    };
  }, [sessionId, secretToken, map, zoom]);

  /** @type {Array<EndpointInfoAggregated>} */
  const vehicles = useMemo(() => {
    if (!endpoints) return [];
    if (!filters?.status?.length) {
      return endpoints;
    }
    return endpoints.filter((item) =>
      item.deviceOnlineStatus
        ? isCameraInParkingMode(item.parkingStatus)
          ? filters.status.includes('Parked')
          : filters.status.includes('Online')
        : filters.status.includes('Offline')
    );
  }, [endpoints, filters]);

  /***********************************************************
   *               The Google Map Initialization             *
   ***********************************************************/
  const [loading, error] = useGoogleMap();

  // Create instance
  useEffect(() => {
    if (loading) return;
    const { Map, LatLng, MapTypeId, ControlPosition } = window.google.maps;
    const map = new Map(container.current, {
      mapId: GOOGLE_MAP_ID,
      center: new LatLng(0, 0),
      isFractionalZoomEnabled: false,
      minZoom: 2,
      maxZoom: 19,
      zoom: 3,
      restriction: {
        latLngBounds: {
          east: 180,
          north: 85,
          south: -85,
          west: -180,
        },
        strictBounds: true,
      },
      noClear: false,
      controlSize: 24,
      mapTypeControl: false,
      mapTypeId: mapTypeId || MapTypeId.ROADMAP,
      streetViewControl: false,
      backgroundColor: '#fff',
      clickableIcons: false,
      scaleControl: true,
      zoomControlOptions: {
        position: ControlPosition.RIGHT_TOP,
      },
    });
    map.addListener('zoom_changed', () => {
      try {
        setZoom(map.getZoom());
      } catch (err) {
        logger.warn('Failed to read map zoom level', err);
      }
    });
    setMap(map);
    return () => {
      setPopup(null);
      map.unbindAll();
    };
  }, [loading, mapTypeId]);

  /***********************************************************
   *                  Fit Bound & Recenter                   *
   ***********************************************************/

  useEffect(() => {
    const fid = requestAnimationFrame(() => {
      try {
        map?.setZoom(2);
        setPrevious({});
        setEndpoints(null);
        setDoFitBound(true);
      } catch (err) {
        console.error(err);
      }
    });
    return () => cancelAnimationFrame(fid);
  }, [map, secretToken, sessionId]);

  const mapDistance = useMemo(() => {
    if (!map || !zoom) return 0;
    const bounds = map.getBounds();
    if (!bounds) return 0;
    const { computeDistanceBetween } = window.google.maps.geometry.spherical;
    return computeDistanceBetween(bounds.getSouthWest(), bounds.getNorthEast());
  }, [map, zoom]);

  useEffect(() => {
    if (!map || !vehicles?.length || !doFitBound) return;
    const { LatLng, LatLngBounds } = window.google.maps;
    setDoFitBound(false);
    const bounds = new LatLngBounds();
    vehicles.forEach((item) => {
      bounds.extend(new LatLng(item.latitude, item.longitude));
    });
    map.fitBounds(bounds, 30);
  }, [map, doFitBound, vehicles]);

  /***********************************************************
   *                      Event Markers                      *
   ***********************************************************/
  /** @type {StateVariable<Array<google.maps.Marker>>} */
  const [markers, setMarkers] = useState(null);
  const [disableClustering, setDisableClustering] = useState(true);

  useEffect(() => {
    const tid = setTimeout(() => {
      setDisableClustering(mapDistance && mapDistance < 50 * 1000);
    }, 100);
    return () => clearTimeout(tid);
  }, [mapDistance]);

  useEffect(() => {
    if (loading || !vehicles?.length) {
      setMarkers([]);
      return;
    }

    const { computeHeading } = window.google.maps.geometry.spherical;
    const { LatLng, Marker, Point, Size } = window.google.maps;

    const calculate = async () => {
      const markers = [];
      for (const item of vehicles) {
        const last = previous[item.endpointId];
        const position = new LatLng(item.latitude, item.longitude);
        const lastPosition = last ? new LatLng(last.latitude, last.longitude) : position;
        const heading = computeHeading(lastPosition, position);
        try {
          const img = document.createElement('img');
          img.src = item.deviceOnlineStatus
            ? isCameraInParkingMode(item.parkingStatus)
              ? LIVE_TRACKING_PARKED_ICON
              : LIVE_TRACKING_ONLINE_ICON
            : LIVE_TRACKING_OFFLINE_ICON;
          const url = await rotateImage(img, heading);
          const w = 3.5 * img.naturalWidth;
          const h = 3.5 * img.naturalHeight;

          const marker = new Marker({
            position,
            zIndex: 10,
            optimized: true,
            icon: {
              url,
              scaledSize: new Size(w, h),
              anchor: new Point(w / 2, h / 2),
            },
          });
          marker['vehicle'] = item;
          markers.push(marker);
        } catch (err) {
          console.debug(err);
        }
      }
      setMarkers(markers);
    };

    const tid = setTimeout(calculate, 100);
    return () => clearTimeout(tid);
  }, [loading, vehicles, previous]);

  /***********************************************************
   *                 Event Marker Listeners                  *
   ***********************************************************/
  useEffect(() => {
    if (!map || !markers?.length) return;

    /** @type {google.maps.MapsEventListener[]} */
    const listeners = [];
    const { InfoWindow } = window.google.maps;

    for (const marker of markers) {
      /** @type {EndpointInfoAggregated} */
      const item = marker['vehicle'];
      const position = marker.getPosition();

      /** @param {google.maps.MapMouseEvent} e */
      const showPopup = async (e) => {
        e.stop();
        const address = await fetchGeoInfo(position.lat(), position.lng());
        const container = document.createElement('div');
        const root = createRoot(container);
        root.render(
          <Box fontWeight={400} fontSize={'1rem'} p="5px">
            <Box fontWeight={600}>{formatCameraName(item.deviceLabel, item.deviceSerialNo)}</Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box fontSize={'0.925em'}>
                {isCameraInParkingMode(item.parkingStatus) ? 'Parked, ' : ''}
                {item?.deviceOnlineStatus ? 'Online' : 'Offline'}
              </Box>
              {item?.deviceOnlineStatus && !isCameraInParkingMode(item.parkingStatus) && (
                <LiveIcon onClick={() => navigate(`/cameras/live?id=${item.endpointId}`)} />
              )}
            </Box>
            {address?.fullAddress && (
              <>
                <Box height="10px" />
                <Box fontSize={'0.85em'} maxWidth="250px">
                  {address.fullAddress}
                </Box>
              </>
            )}
          </Box>
        );
        const popup = new InfoWindow({
          position: position,
          content: container,
        });
        popup.addListener('closeClick', () => {
          root.unmount();
        });
        setPopup(popup);
      };

      const handleClick = () => {
        navigate(`/cameras/${item.endpointId}/trips`);
      };

      if (item.deviceOnlineStatus) {
        listeners.push(marker.addListener('click', handleClick));
      }
      listeners.push(marker.addListener('mouseover', showPopup));
    }
    return () => {
      setPopup(null);
      listeners.forEach((item) => item.remove());
    };
  }, [markers, map, navigate]);

  useEffect(() => {
    if (!map || !popup) return;
    popup.open({ map });
    return () => popup.close();
  }, [map, popup]);

  /***********************************************************
   *                 Event Marker Attachment                 *
   ***********************************************************/
  useEffect(() => {
    if (!map || !markers?.length) return;
    if (disableClustering) {
      markers.forEach((marker) => {
        marker.setMap(map);
      });
      return () =>
        markers.forEach((marker) => {
          marker.setMap(null);
        });
    } else {
      const { Marker, Size, Point } = window.google.maps;
      class CustomRenderer extends DefaultRenderer {
        render({ count, position }) {
          // create marker using svg icon
          return new Marker({
            position,
            zIndex: 10 + count,
            title: `${count} vehicles`,
            icon: {
              url: LIVE_TRACKING_CLUSTER_ICON,
              scaledSize: new Size(48, 48),
              origin: new Point(2, 0),
            },
            label: {
              text: `${count}`,
              color: 'rgba(255,255,255,0.9)',
              fontSize: '14px',
              fontWeight: '600',
            },
          });
        }
      }

      const renderer = new CustomRenderer();
      const customMarkers = markers.map((marker) => {
        marker = renderer.render({
          count: 1,
          position: marker.getPosition(),
        });
        marker.setIcon({
          url: LIVE_TRACKING_SINGLE_CLUSTER_ICON,
          scaledSize: new Size(48, 48),
        });
        marker.addListener('click', (/** @type {google.maps.MapMouseEvent} */ e) => {
          map.setZoom(18);
          map.setCenter(e.latLng);
        });
        return marker;
      });

      const cluster = new MarkerClusterer({
        map,
        renderer,
        markers: customMarkers,
        algorithmOptions: { maxZoom: 19 },
      });
      return () => {
        cluster.setMap(null);
        customMarkers.map((x) => x.unbindAll());
      };
    }
  }, [markers, disableClustering, map]);

  /***********************************************************
   *                         View                            *
   ***********************************************************/

  return (
    <Box
      ref={parent}
      width="100%"
      overflow="hidden"
      position="relative"
      height="calc(100% - 70px)"
      onClick={() => setPopup(null)}
    >
      <Box ref={container} width="100%" height="100%" minWidth="200px" minHeight="200px">
        <CenterBox bgcolor="#fff">
          {error ? (
            <IconMessageBox
              size={'96px'}
              src={NO_SEARCH_RESULT_IMG}
              message="Failed to load Google Maps"
            />
          ) : (
            <CircularProgress />
          )}
        </CenterBox>
      </Box>
      <MapTypeSwitchButton value={mapTypeId} onUpdate={setMapTypeId} />
      {endpoints === null && (
        <CenterBox
          style={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
          }}
        >
          <CircularProgress />
        </CenterBox>
      )}
    </Box>
  );
}
