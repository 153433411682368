import { uniq } from 'lodash';

const { formatSecondsToMinutesAndSeconds } = require('./date');

export const findEntityVideoDataByType = (tenantsMinutes, treeId, treeType) => {
  return tenantsMinutes?.find((t) =>
    treeType === 'TENANT'
      ? t.tenantId === treeId
      : treeType === 'TRIGGER'
        ? t.triggerId === treeId
        : t.endpointId === treeId
  );
};

export const sumDurationSeconds = (data, type) => {
  const filteredData = data?.videoMinuteUsages?.filter((item) => item.type.includes(type));
  const sum = filteredData.reduce((total, item) => total + item.durationSeconds, 0);
  return sum ? formatSecondsToMinutesAndSeconds(sum) : '';
};

export const tenantsMinutesSumByType = (data) => {
  // Create an object to accumulate the total duration for each type
  const videoUsageTotals = {};

  // Iterate over each tenant's data
  data?.forEach((tenant) => {
    tenant?.videoMinuteUsages?.forEach((usage) => {
      // If the type exists, add to the total; otherwise, initialize it
      if (videoUsageTotals[usage.type]) {
        videoUsageTotals[usage.type] += usage.durationSeconds;
      } else {
        videoUsageTotals[usage.type] = usage.durationSeconds;
      }
    });
  });

  return videoUsageTotals;
};

export const sumParentAndChildMinutesDataByType = (data1, data2) => {
  // Create a new object to store the summed values
  const mergedData = {};

  // Merge the data by summing up the values for each type
  [...Object.keys(data1), ...Object.keys(data2)].forEach((type) => {
    mergedData[type] = (data1[type] || 0) + (data2[type] || 0);
  });

  return mergedData;
};

const removeConsecutiveDuplicates = (array) =>
  array.filter((value, index, arr) => index === 0 || value !== arr[index - 1]);

export const findNestedTenantDataByIds = (obj, ids) => {
  if (!Array.isArray(ids) || ids.length < 1) return [];

  const inputIds = removeConsecutiveDuplicates(ids);
  const mainKey = inputIds?.at(0);
  const tenantIds = inputIds.slice(1);
  let results = [];

  if (tenantIds.length === 0) {
    return obj[mainKey] || [];
  }

  function recursiveSearch(tenants, currentIndex) {
    for (const tenant of tenants || []) {
      if (tenant?.tenantId === tenantIds?.at(currentIndex)) {
        // If we reached the last ID in inputIds, add to results
        if (currentIndex === tenantIds.length - 1) {
          results.push(tenant);
        }
        // Recursively search through the descendant tenant list if not at the last ID
        if (currentIndex < tenantIds.length - 1) {
          recursiveSearch(tenant?.descendantTenantList || [], currentIndex + 1);
        }
      }
    }
  }

  recursiveSearch(obj[mainKey]?.descendantTenantList, 0);
  return results?.at(0) || {};
};

function getAllTenantIds(tenantList, skipFirst) {
  let ids = [];
  tenantList?.forEach((tenant, index) => {
    if (!skipFirst || index > 0) {
      ids.push(tenant?.tenantId);
    }
    if (tenant?.descendantTenantList && tenant.descendantTenantList.length > 0) {
      ids = ids.concat(getAllTenantIds(tenant.descendantTenantList, false));
    }
  });
  return ids;
}

export const findDescendantTenantListByTenant = (obj, skipFirst = false) => {
  const parenIds = getAllTenantIds([obj], skipFirst);
  return parenIds || [];
};

export const groupItemsByType = (items) => {
  const endpointArray = [];
  const tenantArray = [];

  function groupByType(items) {
    items?.forEach((item) => {
      if (item.type === 'ENDPOINT') {
        endpointArray.push(item?.name);
      } else if (
        item.type === 'TENANT_PARENT' ||
        item.type === 'TENANT' ||
        item?.type === 'TENANT_PARENT_X'
      ) {
        tenantArray.push(item?.name || 'Smarter AI Dashcam');
      }

      // Recursively check for children
      if (item.children && item.children.length > 0) {
        groupByType(item.children);
      }
    });
  }

  groupByType(items);

  return {
    endpoint: uniq(endpointArray),
    tenants: uniq(tenantArray),
  };
};
