import { GOOGLE_CLIENT_ID } from '@/config';
import { CustomLogger } from '@/utils/logger';
import { useCallback, useEffect, useState } from 'react';

/**
 * @typedef {object} LoginResponse
 * @property {string} email
 * @property {string} idToken
 * @property {string} picture
 */

const logger = new CustomLogger('GoogleAuth');

/**
 * A hook to provide login and logout with Google OAuth Client.
 * @returns {{ loading: boolean, signIn: () => Promise<LoginResponse> }}
 */
export function useGoogleAuth() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/platform.js';
      script.async = true;
      script.defer = true;

      script.onload = () => {
        logger.debug('GAPI script loaded', window.gapi);
        window.gapi.load('auth2', () => {
          logger.debug('GAPI auth2 loaded');
          setLoading(false);
        });
      };

      script.onerror = (err) => {
        logger.error('Failed to load GAPI script', err);
        setLoading(false);
      };

      document.body.appendChild(script);

      return () => {
        script.remove(); // Cleanup the script element
      };
    };

    if (!window.gapi) {
      setLoading(true);
      loadGapiScript();
    } else {
      setLoading(false); // GAPI is already loaded
    }
  }, []);

  const signIn = useCallback(async () => {
    if (!window.gapi || !window.gapi.auth2) {
      throw new Error('Google Auth library not loaded');
    }

    try {
      setLoading(true);

      const auth2 = await window.gapi.auth2.init({
        client_id: GOOGLE_CLIENT_ID,
        plugin_name: 'chat',
      });

      logger.debug('Initialized GoogleAuth instance', auth2);

      logger.info('Signing in');
      const user = await auth2.signIn({
        ux_mode: 'popup',
        scope: 'email profile',
        prompt: 'select_account',
      });

      logger.log('Basic profile retrieved', user);
      const profile = user.getBasicProfile();
      const email = profile.getEmail();
      const picture = profile.getImageUrl();
      const idToken = user.getAuthResponse(true).id_token;

      if (!email || !idToken) {
        throw new Error('Failed to read basic profile');
      }

      return { email, idToken, picture };
    } catch (err) {
      logger.error('Sign-in failed', err);
      throw err; // Re-throw the error to handle in the component if needed
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, signIn };
}
