import {
  CAMERA_N_OFF_ICON,
  LIVE_STREAMING_ICON,
  RECALL_UPLOAD_ICON,
} from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { PlayArrow, PlayCircle, Upload } from '@mui/icons-material';
import { Box, TableCell, TableHead, TableRow } from '@mui/material';
import { VIDEO_MINUTES_TYPE_MAPPING } from '../../assets';

export function VideoMinutesTableHeader({ isShadow, showFirstLabel = true }) {
  return (
    <TableHead>
      <TableRow
        sx={{
          '& .MuiTableCell-head': {
            borderBottom: '1px solid #e0e0e0',
            borderRight: 'none',
            fontSize: '0.78rem',
            color: '#5d6f85',
            wordBreak: 'keep-all',
            padding: 0,
            px: '2px',
            height: '40px',
          },
        }}
      >
        <TableCell align="center">
          {showFirstLabel ? (
            <Box
              display="flex"
              flexDirection="row"
              gap="5px"
              alignItems="center"
              justifyContent="center"
            >
              <BoxImage src={CAMERA_N_OFF_ICON} size={'20px'}></BoxImage>
              Cameras
            </Box>
          ) : null}
        </TableCell>

        {isShadow ? (
          <>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <PlayArrow htmlColor="#c4d0e0" fontSize="small" />
                {VIDEO_MINUTES_TYPE_MAPPING?.SHADOW_EVENT_PLAYBACK}
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <Upload htmlColor="#c4d0e0" fontSize="small" />
                {VIDEO_MINUTES_TYPE_MAPPING?.SHADOW_EVENT_UPLOAD}
              </Box>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="2px"
                alignItems="center"
                justifyContent="center"
              >
                <PlayArrow htmlColor="#c4d0e0" fontSize="small" />
                {VIDEO_MINUTES_TYPE_MAPPING?.EVENT_PLAYBACK}
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="2px"
                alignItems="center"
                justifyContent="center"
              >
                <Upload htmlColor="#c4d0e0" fontSize="small" />
                {VIDEO_MINUTES_TYPE_MAPPING?.EVENT_UPLOAD}
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="5px"
                alignItems="center"
                justifyContent="center"
              >
                <BoxImage src={LIVE_STREAMING_ICON} size={'16px'}></BoxImage>
                {VIDEO_MINUTES_TYPE_MAPPING?.LIVE_STREAMING}
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <PlayCircle htmlColor="#c4d0e0" fontSize="small" sx={{ fontSize: '19px' }} />
                {VIDEO_MINUTES_TYPE_MAPPING?.RECORDING_PLAYBACK}
              </Box>
            </TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                flexDirection="row"
                gap="4px"
                alignItems="center"
                justifyContent="center"
              >
                <BoxImage src={RECALL_UPLOAD_ICON} height={'15px'} width={'15px'}></BoxImage>
                {VIDEO_MINUTES_TYPE_MAPPING?.RECORDING_UPLOAD}
              </Box>
            </TableCell>
          </>
        )}

        <TableCell align="center" sx={{ borderRight: '1px solid #e0e0e0' }}>
          Total
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
