import { Box } from '@mui/material';
import YearMonthPicker from '../@components/MonthYearPicker';
import { VideoMinutesTenantTable } from '../@components/overview/VideoMinutuesTenantTable';
import { VideoMinutesFilteringArea } from '../@components/VideoMinutesFilteringArea';
import { VideoMinutesFilterContextProvider } from '../@context/VideoMinutesFilterContext';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectTenantId } from '@/store/auth';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export function VideoMinutesOverview() {
  const { query, updateQuery } = useRouteQuery();

  const tenantId = useSelector(selectTenantId);

  const handleDateChange = (data) => {
    updateQuery({ monthInt: data?.monthInt, year: data?.year });
  };

  useEffect(() => {
    if (tenantId) {
      updateQuery({ searchedEndpoint: null, searchedTenant: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent={'flex-end'}>
        <YearMonthPicker
          {...(query?.year && { defaultYear: Number(query.year) })}
          {...(query?.monthInt && { defaultMonth: Number(query.monthInt) })}
          currentYearMonth={false}
          onChange={(data) => {
            handleDateChange(data);
          }}
          minYear={2022}
        />
      </Box>
      <VideoMinutesFilterContextProvider>
        <VideoMinutesFilteringArea textView={true} />
        <VideoMinutesTenantTable />
      </VideoMinutesFilterContextProvider>
    </Box>
  );
}
